import { Grid, MenuItem, TextField } from "@mui/material";
import { useContext } from "react";
// import { SearchParamsContext } from "./search/CreateChart";
import { useTranslation } from 'react-i18next';
import { SearchParamsContext } from "./CreateChart";
const DateTimeCombo = () => {
  const d=new Date();
  // const [day, setDay] = useState(d.getDate());
  // const [month, setMonth] = useState(d.getMonth());
  // const [year, setYear] = useState(d.getFullYear());
  // const [hour, setHour] = useState(d.getHours()%12);
  // const [minute, setMinute] = useState(d.getMinutes());
  // const [ampm, setAmpm] = useState(d.getHours()%12==0?'AM':'PM');
  // alert(d.getMinutes())
  // Arrays for options
  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const { day, setDay,month, setMonth,year, setYear,hour, setHour,minute, setMinute,ampm, setAmpm } = useContext(SearchParamsContext)
  const { t } = useTranslation();
  const months = [
    { value: 0, label: 'Jan' },
    { value: 1, label: 'Feb' },
    { value: 2, label: 'Mar' },
    { value: 3, label: 'Apr' },
    { value: 4, label: 'May' },
    { value: 5, label: 'Jun' },
    { value: 6, label: 'Jul' },
    { value: 7, label: 'Aug' },
    { value: 8, label: 'Sep' },
    { value: 9, label: 'Oct' },
    { value: 10, label: 'Nov' },
    { value: 11, label: 'Dec' },
  ];
  
  const years = Array.from({ length: 110 }, (_, i) => new Date().getFullYear()+10 - i);
  const hours = Array.from({ length: 12 }, (_, i) => (i));
  const minutes = Array.from({ length: 60 }, (_, i) => (i));
// alert(hours)
  return (
    <Grid container spacing={2} sx={{width:320}}>
      <Grid item  >

        
        <TextField sx={{width:70,}}
          select variant="outlined"
          selec defaultValue={1}
          label={t("Days")}
          value={day} 
          onChange={(e) => setDay(e.target.value)}
        >
          {days.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      {/* </Grid> */}
      {/* <Grid item xs={4}> */}
        <TextField sx={{width:130,paddingLeft:0.5}}
          select
          label={t("Months")}
          value={month}
          onChange={(e) => setMonth(e.target.value)}
        >
          {months.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      {/* </Grid>
      <Grid item xs={1}> */}
      
        <TextField sx={{width:90,paddingLeft:0.5}}
          select
          
          label={t("Birth Year")}
          value={year}
          onChange={(e) => setYear(e.target.value)}
        >
          {years.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
       </Grid>
      <Grid item > 
        <TextField sx={{width:100}}
          select
          
          label={t("Hour")}
          value={hour}
          onChange={(e) => setHour(e.target.value)}
        >
          {hours.map((option) => (
            <MenuItem key={option} value={option}>
              {option===0?12:option}
            </MenuItem>
          ))}
        </TextField>
      {/* </Grid>
      <Grid item xs={4}> */}
        <TextField sx={{width:100,paddingLeft:0.5}}
          select
          
          label={t("Min")}
          value={minute}
          onChange={(e) => setMinute(e.target.value)}
        >
          {minutes.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <TextField sx={{width:100,paddingLeft:0.5}}
          select
          
          label={t("AM/PM")}
          value={ampm}
          onChange={(e) => {
            setAmpm(e.target.value);
            // if(e.target.value=='PM')
            // setHour(hour+12)
          }
          }
        >
          {/* {minutes.map((option) => ( */}
            <MenuItem key='AM' value='AM'>AM</MenuItem>
            <MenuItem key='PM' value='PM'>PM</MenuItem>
          {/* ))} */}
        </TextField>
      </Grid>
    </Grid>
  );
};

export default DateTimeCombo;
