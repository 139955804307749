import React, { useContext, useEffect, useState } from 'react';
import { Grid, IconButton, useMediaQuery } from '@mui/material';
import { ResponseContext } from '../../../App';
import { useTranslation } from 'react-i18next';
import TodayPlanetsDialog from '../../search/TodayPlanetsDialog';
import { useTheme } from '@emotion/react';
import { getPlanet } from '../../common/planetUtils';
import EditNoteIcon from '@mui/icons-material/EditNote';
import ChartNotesDialog from './ChartNotesDialog';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
// Inline style definitions
const styles = {
    container: {
        width: '100%',
        margin: '0 auto',
        padding: '15px',
        backgroundColor: 'white',
        borderRadius: '10px',
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
        fontFamily: 'Roboto, sans-serif',marginBottom:10,
    },
    description: {
        textAlign: 'left',
        marginBottom: '20px',
    },
    title: {
        fontSize: '1.4em',
        color: '#002D72',
        fontWeight: 'bold'
    },
    titleSmall: {
        fontSize: '1.4em',
        color: '#002D72',
        fontWeight: 'bold',
        marginBottom:-15,
        marginTop: -10,
    },
    text: {
        fontSize: '0.9em',maxWidth:1150,
        color: '#555'
    },
    divider: {
        height: '2.5px',
        width: '100%',
        background: 'linear-gradient(to right, #00A6FF, #00C9A7)',
        // margin: '0px 0',
        borderRadius: '3px',
    },
    gridContainer: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: '20px',
    },
    info: {
        fontSize: '0.9em',
        color: '#444', 
        
    },
    link: {
        color: '#007BFF',
        textDecoration: 'none',
    },
    responsiveText: {
        fontSize: '0.95em',
    },
};

const calculateAge = (birthTimestamp) => {
    let birthDate = new Date(birthTimestamp);
    let currentDate = new Date();
    let years = currentDate.getFullYear() - birthDate.getFullYear();
    let months = currentDate.getMonth() - birthDate.getMonth();
    if (months < 0 || (months === 0 && currentDate.getDate() < birthDate.getDate())) {
        years--;
        months += 12;
    }
    return [years, months];
}

const HoroscopeHeaderDetails = () => {
    const { currDasha, currBukthi, dashaIrupu } = useContext(ResponseContext);
    const [name, setName] = useState('');
    const [openNotesDialog, setOpenNotesDialog] = useState(false);
    const { t } = useTranslation();
    const [age, setAge] = useState([]);
    const theme = useTheme();
    const bigScreen = useMediaQuery(theme.breakpoints.up('md'));
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [openEdit, setOpenEdit] = useState(false);


    const handleOpen = () => {
        setOpenEdit(true);
    };

    const handleClose = () => {
        setOpenEdit(false);
    };

    const handleNotesDialogClose = () =>{
        setOpenNotesDialog(false)
    }

    useEffect(() => {
        let requestData = JSON.parse(localStorage.getItem('requestData'));
        setName(requestData.name);
        let ageRes = calculateAge(requestData.birthTimeStamp);
        setAge(ageRes);
    }, [dashaIrupu, t]);

    return (
        <div style={styles.container}>
            <div style={styles.description}>
                <div style={styles.gridContainer}>
                    <div style={isMobileScreen?styles.titleSmall:styles.title}>{name}'s {t('chart')}</div>
                    <div style={styles.info} ><span style={{marginLeft:bigScreen?90:0}}>
                        {t('Age')}: <b>{age[0]}</b> {t('Years')} <b>{age[1]}</b> {t('Months')}
                        </span></div> 
                 
                    {(localStorage.getItem('userType')==='KRISH'  && 
    <div style={{marginLeft:bigScreen?60:0}}>
      <IconButton onClick={()=>{setOpenNotesDialog(true)}} style={{ padding: '4px', fontSize:15 ,color: '#4B61D1'}}>
        <EditNoteIcon style={{ fontSize: '20px', color: '#4B61D1' }} />Notes&nbsp;<BookmarksIcon style={{ fontSize: '14px', color: '#4B61D1' }} />&nbsp;Tags
      </IconButton>
      <ChartNotesDialog
        open={openNotesDialog}
        handleClose={handleNotesDialogClose}
      />
    </div>)}
                </div>
                <div style={styles.text}>
                    <Grid container style={{marginTop:bigScreen?5:0}} >
                        <Grid item xs={12} md={3} sm={6}>
                            <div><b>{t("Dasha Left")}</b>: {dashaIrupu}</div>
                        </Grid>
                        <Grid item xs={12} md={3} sm={6} >
                            <div style={{marginLeft:bigScreen?25:0}}>{t('Current Dasha-Bhukti')}: <b><span style={{color:getPlanet(currDasha).color}}>{t(currDasha)}</span>&nbsp;-&nbsp;
                            <span style={{color:getPlanet(currBukthi).color}}>{t(currBukthi)}</span></b></div>
                        </Grid>
                        <Grid item xs={12} md={4} sm={6}>
                        <div style={{ fontSize: styles.responsiveText.fontSize}}>
                        🔥 : {t('Combust')} &nbsp;◀ : {t('Retrograde')}&nbsp;&nbsp;<span style={{ fontSize: '0.8em' }}>🔲</span>: {t('Varkotham')}
                        
                        </div>
                        </Grid>
                        <Grid item xs={12} md={2} sm={6}>
                        <a 
                            href="#"
                            onClick={handleOpen} 
                            style={{
                                textDecoration: 'none', 
                                color: 'blue', 
                                fontWeight: 'bold', 
                                cursor: 'pointer'
                            }}
                            >
                            👉&nbsp;{t("Today's planet positions")}
                            </a>
                            

                        </Grid>
                    </Grid>
                  
                    
                </div>
                <TodayPlanetsDialog open={openEdit} onClose={handleClose} />
            </div>
            <div style={styles.divider} />
            <div style={styles.content}>
                {/* Main content will be here */}
            </div>
         
        </div>
    );
};

export default HoroscopeHeaderDetails;
