// src/pages/PrivacyPolicy.js
import React from 'react';
import styles from './Legal.module.css'; // Importing CSS Module

const PrivacyPolicy = () => {
  return (
    <div className={styles.page}>
      <h1 className={styles.h1}>Privacy Policy</h1>

      <h2 className={styles.h2}>1. Introduction</h2>
      <p className={styles.p}>Welcome to [Your Website Name]. We value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and safeguard your data when you visit our website.</p>

      <h2 className={styles.h2}>2. Information We Collect</h2>
      <ul className={styles.ul}>
        <li className={styles.li}><strong>Personal Information:</strong> When you sign up, we collect your email address, password, and name.</li>
        <li className={styles.li}><strong>Horoscope Data:</strong> We collect and store information related to your horoscope charts, including date of birth, time of birth, place of birth, and name.</li>
        <li className={styles.li}><strong>Profile Information:</strong> If you update your profile, we collect any changes made to your name, password, gender, and other optional details.</li>
      </ul>

      <h2 className={styles.h2}>3. How We Use Your Information</h2>
      <ul className={styles.ul}>
        <li className={styles.li}>To provide and manage your account and horoscope services.</li>
        <li className={styles.li}>To communicate with you, including sending verification emails and notifications.</li>
        <li className={styles.li}>To improve our website and services based on user feedback and usage patterns.</li>
      </ul>

      <h2 className={styles.h2}>4. Data Security</h2>
      <p className={styles.p}>We implement industry-standard security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.</p>

      <h2 className={styles.h2}>5. Cookies and Tracking Technologies</h2>
      <p className={styles.p}>We use cookies and similar technologies to enhance your experience on our website. For more information, please see our Cookies Notice.</p>

      <h2 className={styles.h2}>6. Your Rights</h2>
      <p className={styles.p}>You have the right to access, correct, or delete your personal information. You may also opt out of certain data collection practices.</p>

      <h2 className={styles.h2}>7. Changes to This Privacy Policy</h2>
      <p className={styles.p}>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date.</p>

      <h2 className={styles.h2}>8. Contact Us</h2>
      <p className={styles.p}>If you have any questions or concerns about this Privacy Policy, please contact us at [Your Contact Email].</p>
    </div>
  );
};

export default PrivacyPolicy;
