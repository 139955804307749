// src/pages/ContactUs.js
import React, { useContext, useState } from 'react';
import { Container, Typography, TextField, Button, Box, Grid, Paper } from '@mui/material';
import axios from 'axios';
import { contactUs } from '../service/UserService';
import { ResponseContext } from '../App';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const [formStatus, setFormStatus] = useState(null);
  const {setGotResponse } = useContext(ResponseContext);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
  
    e.preventDefault();
    
    setGotResponse(false)
        contactUs(formData).then(()=> {
            setFormStatus('Your message has been sent. Thank you!');
            setFormData({ name: '', email: '', subject: '', message: '' }); // Clear form
            setGotResponse(true)
        }).catch(error => {
            
            setFormStatus('There was an error sending your message. Please try again.');
            setGotResponse(true)
            
        });
      
   
  };

  return (
    <Container maxWidth="sm"  sx={{ padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'top', minHeight: '50vh',marginTop:5 }}>
      <Paper elevation={3} sx={{ padding: '30px', borderRadius: '12px', backgroundColor: '#ffffff', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', width: '100%' }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'left', color: '#333', fontWeight: '600' }}>
          Contact Us
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                variant="outlined"
                multiline
                rows={6}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ padding: '12px 20px', fontSize: '1em' }}
              >
                Send Message
              </Button>
            </Grid>
          </Grid>
          {formStatus && (
            <Typography variant="body1" sx={{ marginTop: '20px', textAlign: 'center', color: formStatus.includes('error') ? '#f44336' : '#4caf50', fontWeight: 'bold' }}>
              {formStatus}
            </Typography>
          )}
        </Box>
      </Paper>
    </Container>
  );
};

export default ContactUs;
