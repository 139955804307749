import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createAshtakVarga } from '../../../service/AstroService';
import { Divider, Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Chart from '../charts/Chart';
import StatusIndicators from './StatusIndicators';
import { ResponseContext } from '../../../App';

// Enhanced CSS styling for the table
const styles = {
  tableContainer: {
    width: '90%',
    
    overflowX: 'auto', // Allows horizontal scrolling on small screens
  },
  table: {
    minWidth:800,
    width: '70%',
    borderCollapse: 'collapse',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Adds shadow for a modern look
    borderRadius: '8px', // Rounded corners for the table
    border: '1px solid lightblue', 
  },
  th: {
    backgroundColor: 'rgba(100, 149, 237, 0.1)',
    padding: '6px',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '16px',
    color: 'darkblue',
    height:50,
    // border: '1px solid rgba(100, 149, 237, 0.1)', 
  },
  td: {
    padding: '7px',
    textAlign: 'center',
    fontSize: '16px',
  },
  header: {
    backgroundColor: 'rgba(100, 149, 237, 0.4)',
  },
  rowEven: {
    backgroundColor: '#ffffff',height:40,
    // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Adds shadow for a modern look
    borderRadius: '8px', // Rounded corners for the table
    border: '1px solid rgba(100, 149, 237, 0.1)', 
  },
   rowOdd: {
    backgroundColor: 'rgba(100, 149, 237, 0.05)',height:40,
    // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Adds shadow for a modern look
    borderRadius: '8px', // Rounded corners for the table
    border: '1px solid rgba(100, 149, 237, 0.1)', 
  },
  boldRow: {
    fontWeight: 'bold',
    borderRadius: '8px',
    backgroundColor: 'rgba(100, 149, 237, 0.05)',height:50,
    border: '1px solid rgba(100, 149, 237, 0.1)', 
  },
};
function filterKeys(obj) {
  let valuesArray = [];
  for (let key in obj) {
    let intKey = parseInt(key, 10);
    if (intKey >= 1 && intKey <= 12) {
      valuesArray[intKey - 1] = obj[key];
    }
  }
  return valuesArray.filter(value => value !== undefined);
}
const AshtakVargaDetails = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [ashtakData, setAshtakData] = useState([]);
  const [ashtakValues, setAshtakValues] = useState();
  const {setGotResponse} = useContext(ResponseContext);
  useEffect(() => {
    console.log("-----------ashtakvargadetails useEffect----------");
    if(location.pathname.includes('tamil') && i18n.language !=='ta')
      i18n.changeLanguage('ta') ;
    else if(location.pathname.includes('telugu') && i18n.language !=='te')
      i18n.changeLanguage('te');
    else if(location.pathname.includes('english') && i18n.language !=='en')
      i18n.changeLanguage('en');
    let ashtakInput = JSON.parse(localStorage.getItem("ashtakInput"));
    
    setGotResponse(false);
    createAshtakVarga(ashtakInput).then(response => {
      // Assuming response.data.final_result is the array we need
      const finalResult = response.data.final_result;
      const planets = ['Sun', 'Moon', 'Mars', 'Mercury', 'Jupiter', 'Venus', 'Saturn', 'Total'];
      const rows = finalResult.map((row, index) => {
        const rowData = {};
        for (let i = 0; i < row.length; i++) {
          const field = (i + 1).toString(); // Field names '1', '2', ..., '12'
          rowData[field] = row[i];
          rowData['PLANETS'] = t(planets[index]);
        }
        
        return { id: index + 1, ...rowData };
      });
      // console.log("==3333=="+JSON.stringify(rows[7]))
      let lastRow=rows[7]
      let removedVal = filterKeys(lastRow)
      setAshtakValues(removedVal)
      setAshtakData(rows);
      setGotResponse(true);
    }).catch(error => {
      setGotResponse(true);
      console.error('Error fetching data:', error);
    });
  }, [location, i18n, t]);
const getRowClass = (index) => {
  if(index ===7)
    return styles.boldRow
  else if (index % 2 === 0)
    return styles.rowEven
  else
  return styles.rowOdd
}
  const columns = [
    { field: 'PLANETS', headerName: t("Planets"), width: 90 },
    { field: '1', headerName: t("Ar"), width: 70 },
    { field: '2', headerName: t("Ta"), width: 70 },
    { field: '3', headerName: t("Ge"), width: 70 },
    { field: '4', headerName: t("Ca"), width: 70 },
    { field: '5', headerName: t("Le"), width: 70 },
    { field: '6', headerName: t("Vi"), width: 70 },
    { field: '7', headerName: t("Li"), width: 70 },
    { field: '8', headerName: t("Sc"), width: 70 },
    { field: '9', headerName: t("Sag"), width: 70 },
    { field: '10', headerName: t("Ca"), width: 70 },
    { field: '11', headerName: t("Aq"), width: 70 },
    { field: '12', headerName: t("Pi"), width: 70 }
  ];

  return (
    <div>
      <h1 style={{ color: '#003366', fontSize: '24px', marginBottom: '10px',textAlign:'left' }}>
        {t("Ashtakvarga - Sarvashtakvarga")}
      </h1>
      <Divider sx={{ filter: 'blur(1px) opacity(0.5)', borderColor: 'blue', marginBottom: 2, width: '100%' }} />
      <div style={styles.container}>
        <table style={styles.table}>
          <thead>
            <tr style={styles.header}>
              {columns.map((col) => (
                <th key={col.field} style={styles.th}>{col.headerName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {ashtakData.map((row, index) => (
              <tr
                key={index}
                style={ getRowClass(index)}
                // style={{fontWeight:index==3?'bold':'none'}}
                className={styles.hoverEffect}
                // onMouseEnter={(e) => e.currentTarget.style.backgroundColor = styles.hoverRow.backgroundColor}
                // onMouseLeave={(e) => e.currentTarget.style.backgroundColor = index % 2 === 0 ? styles.rowEven.backgroundColor : styles.rowOdd.backgroundColor}
              >
                {columns.map((col) => (
                  <td key={col.field} style={styles.td}>{row[col.field]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Grid container style={{marginTop:20}}>
        <Grid item>
        <Chart vargaInput={ashtakValues} arrayMode={'arrayMode'} />
      
        </Grid>
        <Grid item>
        <StatusIndicators/>
        </Grid>
      </Grid>
      
    </div>
  );
};

export default AshtakVargaDetails;
