import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createAshtakVarga } from '../../../service/AstroService';
import { Divider, Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Chart from '../charts/Chart';
import StatusIndicators from './StatusIndicators';
import { ResponseContext } from '../../../App';

// Sample styling for the table
const styles = {
  tableContainer: {
    width: '100%',
    overflowX: 'auto', // Allows horizontal scrolling on small screens
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    backgroundColor: 'rgba(100, 149, 237, 0.1)',
    padding: '6px',
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: '14px',
    color: 'darkblue',
  },
  td: {
    padding: '5px',
    textAlign: 'left',
    fontSize: '16px',
  },
  header: {
    backgroundColor: 'rgba(100, 149, 237, 0.4)',
  },
  rowEven: {
    backgroundColor: '#ffffff',height:35
  },
  rowOdd: {
    backgroundColor: 'rgba(213, 255, 255, 0.1)',height:35
  },
  boldRow: {
    fontWeight: 'bold',
  },
};

const AshtakVargaDetailsMobile = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [ashtakData, setAshtakData] = useState([]);
  const [ashtakValues, setAshtakValues] = useState([])
  const {setGotResponse} = useContext(ResponseContext);
  useEffect(() => {
    // alert('ss')
    console.log("-----------ashtakvargadetailsMobile useEffect----------");
    if(location.pathname.includes('tamil') && i18n.language !=='ta')
      i18n.changeLanguage('ta') ;
    else if(location.pathname.includes('telugu') && i18n.language !=='te')
      i18n.changeLanguage('te');
    else if(location.pathname.includes('english') && i18n.language !=='en')
      i18n.changeLanguage('en');
    
    let ashtakInput = JSON.parse(localStorage.getItem("ashtakInput"));
    // alert(ashtakInput)
    setGotResponse(false);
    createAshtakVarga(ashtakInput).then(response => {
      const finalResult = response.data.final_result;

      // Transpose rows to columns
      const transposedData = [];
      for (let i = 0; i < finalResult[0].length; i++) {
        const rowData = { id: i + 1 };
        for (let j = 0; j < finalResult.length; j++) {
          rowData[`Planet_${j + 1}`] = finalResult[j][i];
        }
        transposedData.push(rowData);
      }
      let lastRow=finalResult[7]
      
      lastRow.pop();
      setAshtakValues(lastRow);
      // alert(JSON.stringify(lastRow))
      setAshtakData(transposedData);
      setGotResponse(true);
    }).catch(error => {
      console.error('Error fetching data:', error);
    });
  }, [location, i18n, t]);

  const planets = [t('Sun'), t('Moon'), t('Mars'), t('Mercury'), t('Jupiter'), t('Venus'), t('Saturn'), t('Tot')];
  const headerNames = [t("Planets"), t("Ar"), t("Ta"), t("Ge"), t("Ca"), t("Le"), t("Vi"), t("Li"), t("Sc"), t("Sag"), t("Ca"), t("Aq"), t("Pi"), t('Tot')];
  // Create table header
  const headers = [t('Ra'), ...planets];

  // Render table rows
  const rows = ashtakData.map((row, index) => (
    <tr key={index} style={index % 2 === 0 ? styles.rowEven : styles.rowOdd}>
      <td style={{fontWeight:'bold'}}>{row.id}</td>
      {headers.slice(1).map((_, idx) => (
        <td style={{fontWeight:idx===7?'bold':'none'}} key={idx}>{row[`Planet_${idx + 1}`]}</td>
      ))}
    </tr>
  ));

  return (
    <div>
      <h1 style={{ color: 'darkblue', float: 'left', fontSize: '20px' }}>
        {t("Ashtakvarga - Sarvashtakvarga")}
      </h1>
      <Divider sx={{ filter: 'blur(1px) opacity(0.5)', borderColor: 'blue', marginBottom: 2, width: '100%' }} />
      <div style={styles.tableContainer}>
        <table style={styles.table}>
          <thead>
            <tr style={styles.header}>
              {headers.map((header, idx) => (
                <th key={idx} style={styles.th}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
      <Grid container style={{marginTop:50}}>
        <Grid item >
        <Chart vargaInput={ashtakValues} arrayMode={'arrayMode'} />
      
        </Grid>
        <Grid item>
        <StatusIndicators/>
        </Grid>
      </Grid>
    </div>
  );
};

export default AshtakVargaDetailsMobile;
