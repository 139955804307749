
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Filter8Icon from '@mui/icons-material/Filter8';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import LanguageIcon from '@mui/icons-material/Language';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import CalculateIcon from '@mui/icons-material/Calculate';
import GridOnIcon from '@mui/icons-material/GridOn';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import Filter9PlusIcon from '@mui/icons-material/Filter9Plus';
import { useTranslation } from 'react-i18next';
import ScoreboardIcon from '@mui/icons-material/Scoreboard';
import PublicIcon from '@mui/icons-material/Public';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import PrintIcon from '@mui/icons-material/Print';
// import OpenChartDialog from "./search/OpenChartDialog";
// import EditChartDialog from "./search/EditChartDialog";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
  import { ConstructionSharp, North, Settings, South } from "@mui/icons-material";
  
  export const mainNavigation = (language) => [
    {
      name: "New Horoscope",
      icon:  NoteAddIcon,
      url: `openCreatePopup`,
    },
    {
      name: "Birth Chart",
      icon:  GridOnIcon,
      url: `/${language}/details`,
    },
    // {
    //   name: "Planets Strength",
    //   icon:  ScoreboardIcon,
    //   url: `/${language}/details/planetstrength`,
    // },
    {
      name: "Calculations",
      icon: CalculateIcon,
      url: `expand`,
      navigationData: [
        {
          name: "Ashtak Varga",
          icon:  Filter8Icon,
          url: `/${language}/details/ashtakvarga`,
        },
        {
          name: "Prastharashtakvarga",
          icon:  Filter8Icon,
          url: `/${language}/details/prastharashtakvarga`,
        },
        {
          name: "Shodashvarga Charts",
          icon:  GridOnIcon,
          url: `/${language}/details/shodashvargacharts`,
        },
      ],
    },
     {
      name: "Sign In",
      icon: LoginIcon,
      url: `openSignIn`,
    },
    {
      name: "Sign Up",
      icon: AppRegistrationIcon,
      url: `openSignUp`,
    },
    // {
    //   name: "Sign In",
    //   icon: LoginIcon,
    //   url: `openSignIn`,
    //   navigationData: [
    //     {
    //       name: "Sign In",
    //       icon: LoginIcon,
    //       url: `openSignIn`,
    //     },
    //     {
    //       name: "Sign Up",
    //       icon: AppRegistrationIcon,
    //       url: `openSignUp`,
    //     },
        
    //     {
    //       name: "Edit Profile",
    //       icon: ManageAccountsIcon,
    //       url: `/account/usage-by-tool`,
    //     },
    //   ],
    // },
    {
      name: "Language",
      icon: LanguageIcon,
      url: `expand`,
      navigationData: [
        {
          name: "English",
          icon: PublicIcon,
          url: `changeToEn`,
        },
        {
          name: "தமிழ்",
          icon: PublicIcon,
          url: `changeToTa`,
        },
        {
          name: "తెలుగు",
          icon: PublicIcon,
          url: `changeToTe`,
        }
       
      ],
    },
    // {
    //     name: "Change Chart type",
    //     icon: StackedBarChartIcon,
    //     url: `expand`,
    //     navigationData: [
    //       {
    //         name: "North India",
    //         icon: North,
    //         url: `/tool/all`,
    //       },
    //       {
    //         name: "South India",
    //         icon: South,
    //         url: `/tool/curl`,
    //       },
          
    //       {
    //         name: "East India",
    //         icon: NorthEastIcon,
    //         url: `/tool/curl`,
    //       },
         
    //     ],
    //   }
  ];

  export const mainNavigationAfterLogin =(language) => [
    {
      name: "New Horoscope",
      icon:  NoteAddIcon,
      url: `openCreatePopup`,
    },
    {
      name: "Open Horoscope",
      icon: FileOpenIcon,
      url: `openHoroscopePopup`,
    },
    {
        name: "Edit Horoscope",
        icon: DriveFileRenameOutlineIcon,
        url: `openEditPopup`,
      },
      {
        name: "Birth Chart",
        icon:  GridOnIcon,
        url: `/${language}/details`,
      },
      // {
      //   name: "Planets Strength",
      //   icon:  ScoreboardIcon,
      //   url: `/${language}/details/planetstrength`,
      // },
      {
        name: "Calculations",
        icon: CalculateIcon,
        url: `expand`,
        navigationData: [
          {
            name: "Ashtak Varga",
            icon:  Filter8Icon,
            url: `/${language}/details/ashtakvarga`,
          },
          {
            name: "Prastharashtakvarga",
            icon:  Filter8Icon,
            url: `/${language}/details/prastharashtakvarga`,
          },
          {
            name: "Shodashvarga Charts",
            icon:  GridOnIcon,
            url: `/${language}/details/shodashvargacharts`,
          },
        ],
      },
    {
      name: "Profile",
      icon: AccountCircleIcon,
      url: `expand`,
      navigationData: [
        {
          name: "Sign Out",
          icon: LogoutIcon,
          url: `signOut`,
        },
        
        {
          name: "Edit Profile",
          icon: ManageAccountsIcon,
          url: `openEditProfilePopup`,
        },
      ],
    },
    {
      name: "Language",
      icon: LanguageIcon,
      url: `expand`,
      navigationData: [
        {
          name: "English",
          icon: PublicIcon,
          url: `changeToEn`,
        },
        {
          name: "தமிழ்",
          icon: PublicIcon,
          url: `changeToTa`,
        },
        {
          name: "తెలుగు",
          icon: PublicIcon,
          url: `changeToTe`,
        }
      
      ],
    },
    // {
    //     name: "Change Chart type",
    //     icon: StackedBarChartIcon,
    //     url: `expand`,
    //     navigationData: [
    //       {
    //         name: "North India",
    //         icon: North,
    //         url: ``,
    //       },
    //       {
    //         name: "South India",
    //         icon: South,
    //         url: ``,
    //       },
          
    //       {
    //         name: "East India",
    //         icon: NorthEastIcon,
    //         url: ``,
    //       },
         
    //     ],
    //   },
      {
        name: "Settings",
        icon:  Settings,
        url: `settings`,
      },
      {
        name: "Print PDF",
        icon:  PrintIcon,
        url: `printPDF`,
      },
  ];
  