import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { IconButton, Link } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { ResponseContext } from '../App';
import { resendVerificationLink } from '../service/UserService';

const styles = {
    snackbar: {
        top: '120px',
        left: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
        width: '90%',
        maxWidth: '600px',
        position: 'fixed',
        zIndex: 1100, // Ensure it's on top of other content
    },
    alert: {
        position: 'relative',
        backgroundColor: '#FFC000', // Yellow background
        color: '#000', // Black text
        borderRadius: '8px',
        fontWeight: 'bold',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
    },
    username: {
        position: 'absolute',
        top: '8px',
        left: '8px',
        fontWeight: 'bold',
        fontSize: '16px',
    },
    closeButton: {
        position: 'absolute',
        top: '8px',
        right: '8px',
        color: '#000',
    },
    link: {
        color: '#000',
        textDecoration: 'underline',
        cursor: 'pointer',
    },
};

function EmailVerificationAlert() {
    const { loggedInUser } = React.useContext(ResponseContext);

    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState('');

    useEffect(() => {
        setOpen(localStorage.getItem('emailVerified') === 'false');
    }, [loggedInUser]);

    const onClose = () => {
        setOpen(false);
        localStorage.removeItem('emailVerified');
    };

    const resendVerification = () => {
        resendVerificationLink().then(() => {
            alert("Verification link sent successfully");
        }).catch(error =>{
            alert(error)
        });
    };

    return (
        <Snackbar
            open={open}
            autoHideDuration={60000}
            // onClose={onClose}
            style={styles.snackbar}
        >
            <div style={styles.alert}>
                <div style={styles.username}>Hi {loggedInUser}</div>
                <IconButton
                    style={styles.closeButton}
                    onClick={onClose}
                >
                    <CancelIcon />
                </IconButton>
                <div style={{marginTop:20}}>
                Your email isn’t verified yet. Check your inbox (and spam) for the verification email, or <Link
                        onClick={resendVerification}
                        style={styles.link}
                    >
                        click here
                    </Link> to resend it.
                   
                </div>
            </div>
        </Snackbar>
    );
}

export default EmailVerificationAlert;
