import { Box, Grid, lighten, useMediaQuery } from "@mui/material";
import RaashiChart from "../charts/RaashiChart";
import NavamshaChart from "../charts/NavamshaChart";
import DashaTable from "./DashaTable";
import PlanetsDetails from "./PlanetsDetails";
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import PlanetsStarLordTable from "./PlanetsStarLordTable";
import {  makeStyles } from '@material-ui/core/styles';
import { ResponseContext } from "../../../App";
import PanchangDetails from "./PanchangDetails";
import AvasthaDetails from "./AvasthaDetails";
import { useLocation } from "react-router-dom";
import HoroscopeHeaderDetails from "./HoroscopeHeaderDetails";
import PlanetStrength from "./PlanetsStrength";
import PlanetsAspects from "./PlanetsAspects";
const useStyles = makeStyles((theme) => ({
    roundedBox: {
      borderRadius: theme.spacing(3), // Adjust the border radius as needed
      [theme.breakpoints.down('md')]: {
        border: 'none', 
        backgroundColor:lighten( '#e6ecff',0.7) 
      },
      [theme.breakpoints.up('md')]: {
        border: `4px solid #ccd9ff`, 
        padding: theme.spacing(1),
        margin:10,
        backgroundColor:'white',
        
      },
      
    },
    roundedBox2: {
       border: 'none',
        [theme.breakpoints.down('md')]: {
            
        },
        [theme.breakpoints.up('md')]: {
            padding:10,margin:15
        },
        
      },
  }));
  
export default function HoroscopeDetails() {
const {responseData,setGotResponse } = useContext(ResponseContext);
const [dashaTableReloadkey, setDashaTableReloadkey] = useState(Date.now());
const classes = useStyles();
 const { i18n} = useTranslation();
 const location =useLocation();
 const theme = useTheme();
 const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

//  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
 const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//  const isMd = useMediaQuery(theme.breakpoints.down('md'));
 const bigScreen = useMediaQuery(theme.breakpoints.up('md'));
//  const isXl = useMediaQuery(theme.breakpoints.down('xl'));
//  const scrollToTarget = () => {
//   if (targetRef.current) {
//       targetRef.current.scrollIntoView({
//           behavior: 'smooth',
//           block: 'start'
//       });
//   }
// };
  useEffect(() => {
    console.log("HoroscopeDetailsTamil useEffect render test ")
    setGotResponse(false);
    const handleLanguageChange = async () => {
      setGotResponse(false);

      if (location.pathname.includes('tamil') && i18n.language !== 'ta') {
        await i18n.changeLanguage('ta');
      } else if (location.pathname.includes('telugu') && i18n.language !== 'te') {
        await i18n.changeLanguage('te');
      } else if (location.pathname.includes('english') && i18n.language !== 'en') {
        await i18n.changeLanguage('en');
      }

      setGotResponse(true);
    };
    handleLanguageChange();
    setDashaTableReloadkey(Date.now())
    // if (isXs) {
    //   console.log('Screen size is extra small (xs)');
    // } else if (isSm) {
    //   console.log('Screen size is small (sm)');
    // } else if (isMd) {
    //   console.log('Screen size is medium (md)');
    // } else if (isLg) {
    //   console.log('Screen size is large (lg)');
    // } else if (isXl) {
    //   console.log('Screen size is extra large (xl)');
    // } else {
    //   console.log('Screen size is larger than extra large (xl)');
    // }
    // if(gotResponse)
    //   scrollToTarget();
  },[responseData,location,i18n])
    return (
        <div id="contentToPrint" > 
          
       <HoroscopeHeaderDetails/>
       
        <Box sx={{width:isSmallScreen?'100%':'950px'}}  >
          
            <Grid container spacing={2} >
            {/* First Row */}
                <Grid item xs={12} sm={6} xl={6} >
                    <RaashiChart />
                </Grid>
                <Grid item xs={12} sm={6} xl={6}>
                    <NavamshaChart />
                </Grid>
           
            </Grid>
            
           
       
        </Box>
        <Box sx={{width:isMobile?'100%':'950px'}} className={classes.roundedBox2}>

      {( isMobile && 
        <Grid container spacing={2} style={{marginLeft:0,marginTop:10,paddingTop:10}}>
              <div style={{marginTop:-20}}>
                <PlanetsDetails />
                </div>
                <div style={{marginTop:10}}>
                <DashaTable key={dashaTableReloadkey}  />
                </div>
                <div style={{marginTop:10}}>
                <PlanetStrength/>
                </div>
                <PlanetsAspects/>
                <PlanetsStarLordTable/>
                <AvasthaDetails />
                <div style={{marginTop:-40}}>
                <PanchangDetails />
                </div>
        </Grid>

      )}

 {( !isMobile && 
  <Grid container spacing={bigScreen?7:2}>
          <Grid item >
                <PlanetsDetails />
                
                <Grid>
                  <PlanetStrength/>
                  <PlanetsAspects/>
                  <div style={{marginLeft:isSmallScreen?0:-40}}>
                  <PanchangDetails />
                  </div>
                  </Grid>
                
            </Grid>
            <Grid item style={{marginLeft:15}} >
            
                <DashaTable key={dashaTableReloadkey} />
                
                <div style={{marginTop:20,marginLeft:-30}}>
                <PlanetsStarLordTable/>
                </div>
                
                <AvasthaDetails />
                
            </Grid>
        </Grid>
 )}
        
        </Box>
        {/* <button onClick={scrollToTarget} >
                Scroll to Target
            </button> */}
            
    </div>
    )
};