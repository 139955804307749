// export const API_URL = "http://localhost:5000/";
export const API_URL = "https://172.235.27.132/";

export const GENERATE_HOROSCOPE_URL = `${API_URL}ui/horoscope/generate`;
export const FETCH_LOCATIONS_URL = `${API_URL}ui/locations/`;
export const GET_MY_HOROSCOPES_URL = `${API_URL}ui/get_my_horoscopes`;
export const DELETE_MY_HOROSCOPE = `${API_URL}ui/delete_my_horoscope/`;
export const LOGIN_URL = `${API_URL}ui/login`;
export const LOGOUT_URL = `${API_URL}ui/logout`;
export const SIGNUP_URL = `${API_URL}ui/sign_up`;
export const GET_PROFILE_URL = `${API_URL}ui/get_profile`;
export const UPDATE_PROFILE_URL = `${API_URL}ui/update_profile`;
export const UPDATE_SETTINGS_URL = `${API_URL}ui/update_settings`;
export const FETCH_TODAY_PANCHANG_URL = `${API_URL}ui/panchang/today`;
export const GENERATE_ASHTAK_VARGA_URL = `${API_URL}ui/ashtakvarga/details`;
export const GENERATE_VARGA_CHARTS_URL = `${API_URL}ui/vargacharts/details`;

export const GET_HOROSCOPE_NOTES_TAGS = `${API_URL}ui/get_horoscope_notes_tags`;
export const UPDATE_HOROSCOPE_NOTES_TAGS = `${API_URL}ui/update_horoscopes_notes_tags`;
export const GET_ALL_TAGS = `${API_URL}ui/get_tags`;

export const RESEND_EMAIL_VERIFICATION_LINK = `${API_URL}ui/resend_verification_link`;
export const VERIFY_EMAIL_URL = `${API_URL}ui/verify_email`;
export const FORGOT_PASSWORD_URL = `${API_URL}ui/forgot_password`;
export const RESET_PASSWORD_URL = `${API_URL}ui/reset_password`;

export const CONTACT_US_URL = `${API_URL}ui/contact_us`;