// src/pages/PrimeBenefits.jsx
import React, { useState } from 'react';

const PrimeBenefits = () => {
  const [hoverIndex, setHoverIndex] = useState(null);

  const tooltips = [
    "Save or update unlimited horoscopes and add or edit notes for each one. Easily search and browse through all your horoscopes using convenient pagination",
    "Tag similar horoscopes by applying descriptive tags, then use these tags to search and filter the horoscopes. This method allows you to easily compare similar horoscopes and analyze patterns, helping you streamline your research and gain valuable insights",
    "As a Prime customer, you'll experience faster and more reliable website access. We prioritize your experience by minimizing traffic and optimizing load balancing to ensure you connect to the most responsive server.",
    "Securely back up your horoscope data in the cloud, ensuring that it is safely stored and can be easily recovered if needed."
  ];

  return (
    <div style={styles.container}>
     {( localStorage.getItem('userType') !== 'KRISH' && <h2 style={styles.heading}>Unlock Prime Benefits</h2>)}
     {( localStorage.getItem('userType') === 'KRISH' && <div style={{color:'darkblue',marginBottom:20}}><h3 style={styles.headingPrime}>  Welcome, Prime User!</h3> <br/>Explore the features below. For any issues, please contact support at [will_add_soon].</div>)}
      <div style={styles.paper}>
        <ul style={styles.benefitList}>
          {[
            "Save Unlimited Horoscopes with Notes",
            "Tag and Identify Similar Horoscopes for Easy Research",
            "Enjoy Faster, More Reliable Website Access",
            "Backup Your Horoscope Data in the Cloud"
          ].map((text, index) => (
            <li
              key={index}
              style={styles.tooltipContainer}
              onMouseEnter={() => setHoverIndex(index)}
              onMouseLeave={() => setHoverIndex(null)}
            >
              <span style={styles.checkIcon}>✔</span>
              <span style={styles.benefitText}>{text}</span>
              {hoverIndex === index && (
                <div style={styles.tooltip}>
                  {tooltips[index]}
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
      {( localStorage.getItem('userType') !== 'KRISH' && <button style={styles.joinButton} onClick={() => alert("Coming Soon...")}>
        Join Prime
      </button>)}
      {( localStorage.getItem('userType') === 'KRISH' && <button style={styles.joinButton} onClick={() => alert("Coming Soon...")}>
      See All Features Guide
      </button>)}
    </div>
  );
};

// Inline CSS styles
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1rem',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    width: '380px',
    margin: 'auto',
    textAlign: 'center',
    border: '1px solid #e0e0e0',
  },
  heading: {
    margin: '5px',
    fontWeight: 'bold',
    fontSize: '1.8rem',
    background: 'linear-gradient(to right, #003366, #004080, #002F6C, #003B6F, #4B0082)',
    backgroundClip: 'text',
    color: 'transparent',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    textShadow: '3px 3px 6px rgba(0, 0, 0, 0.3)',
    display: 'inline',
  },
  headingPrime: {
    margin: '5px',
    fontWeight: 'bold',
    fontSize: '1rem',
    background: 'linear-gradient(to right, #003366, #004080, #002F6C, #003B6F, #4B0082)',
    backgroundClip: 'text',
    color: 'transparent',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    textShadow: '3px 3px 6px rgba(0, 0, 0, 0.3)',
    display: 'inline',
  },
  paper: {
    width: '100%',
    padding: '1rem',
    borderRadius: '4px',
    backgroundColor: '#f0f4f8',
    border: '1px solid #dcdcdc',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  benefitList: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  tooltipContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0',
    cursor: 'pointer',
  },
  checkIcon: {
    fontSize: '24px',
    color: '#4B61D1',
    marginRight: '8px',
  },
  benefitText: {
    fontSize: '1rem',
    fontWeight: 'medium',
    color: 'darkblue',
    fontFamily: 'Roboto, sans-serif',
  },
  tooltip: {
    position: 'absolute',
    bottom: '125%',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '320px',
    backgroundColor: 'skyblue',
    color: 'darkblue',
    textAlign: 'center',
    borderRadius: '4px',
    padding: '10px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
    fontSize: '0.875rem',
    border: '5px solid transparent',
    borderBottomColor: '#4B61D1',
    zIndex: 1,
    opacity: 1,
    transition: 'opacity 0.3s ease-in-out',
  },
  joinButton: {
    marginTop: '1rem',
    padding: '8px 24px',
    fontSize: '1rem',
    borderRadius: '4px',
    backgroundColor: '#4B61D1',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
  },
};

export default PrimeBenefits;
