import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import '../../css/MyTableDesign.css'
import { ResponseContext } from '../../../App';
// Example response data context


export default function PlanetStrength() {
  const { t, i18n } = useTranslation();
  const { responseData } = useContext(ResponseContext);
  const [data, setData] = useState([]);

 
  const columns = [
    { field: 'PLANETS', headerName: t("Planets"), width: 150 },
    { field: 'RAASHI', headerName: t("Rashi") },
    { field: 'STHANAPALAM', headerName: t("Sthana Bala"), width: 200 },
    { field: 'THKPALAM', headerName: t("Thik Bala"), width: 200 },
    
  ];

  

  useEffect(() => {
    let result = JSON.parse(localStorage.getItem('responseData'));
    if(result) {
        const planetStrength = result['planetsStrength'];
        const rows = Object.keys(planetStrength.sthanaPalam).map((key) => ({
            id: key,
            PLANETS: key,
            RAASHI: planetStrength.raashi[key],
            STHANAPALAM: planetStrength.sthanaPalam[key],
            THKPALAM: planetStrength.thikPalam[key],
          }));
          setData(rows);
  }
  }, [responseData, i18n.language]);

  return (
    <div>

      <div className="table-container">
        <table className="table">
          <thead className="thead">
            <tr>
              {columns.map((col) => (
                <th className="th" key={col.field}>{col.headerName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row) => (
              <tr className="tr" key={row.id}>
                <td className="td">{t(row.PLANETS)}</td>
                <td className="td">{t(row.RAASHI)}</td>
                <td className="td">{t(row.STHANAPALAM)}</td>
                <td className="td">{t(row.THKPALAM)+'%'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
