
import * as React from 'react';
import './App.css';
import { BrowserRouter } from "react-router-dom";
import './astro/common/i18n'
import { ThemeProvider, createTheme, lighten } from '@mui/material';
// import { MainLayout } from './layouts';
import { mainNavigation, mainNavigationAfterLogin,  } from './layouts/navigation';
import MainLayout from './layouts/MainLayout';
import { useTranslation } from 'react-i18next';


export const ResponseContext = React.createContext();

function App() {
  const { i18n } = useTranslation();
  const [responseData, setResponseData] = React.useState([]);
  const [requestData, setRequestData] = React.useState([]);
  const [gotResponse, setGotResponse] = React.useState(true);
  const [loggedInUser, setLoggedInUser] = React.useState(localStorage.getItem('username'))
  const [openChart, setOpenChart] = React.useState(false)
  const [openLogin, setOpenLogin] = React.useState(false)
  const [openSignup, setOpenSignup] = React.useState(false)
  const [openForgotPassword, setOpenForgotPassword] = React.useState(false)
  const [openEdit, setOpenEdit] = React.useState(false)
  const [openCreate, setOpenCreate] = React.useState(false)
  const [currDasha,setCurrDasha] = React.useState("")
  const [currBukthi,setCurrBukthi] = React.useState("")
  const [dashaIrupu,setDashaIrupu] = React.useState("")
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [panchangData, setPanchangData ] = React.useState();
  const [openEditProfile, setOpenEditProfile] = React.useState(false)
  const [chartSettings,setChartSettings] = React.useState({})
  const [openChartSettings,setOpenChartSettings] = React.useState(false)
  const [isDateManual,setIsDateManual] = React.useState(false);
  const [lastResponse,setLastResponse] = React.useState('');
  let cc = 3;
  const combinations = [
    ["#21A3A3", "#135757", "#00D3BA", "#207373"],
    ["#477FF0", "#03379e", "#3e78ff", "#4141cc"],
    ["#8D4BF2", "#4e05bc", "#8D4BF2", "#4e05bc"],
    ["#191970","#4169E1","#4B61D1"]
  ];
  // Create a custom theme with breakpoints and spacing values
  const theme = createTheme({
    palette: {
      type: "light",
      primary: {
        main: combinations[cc][0],
      },
      secondary: {
        main: combinations[cc][2],
      },
    },
    typography: {
      fontFamily: "Ubuntu, Roboto, Arial, sans-serif",
    },
    breakpoints: {
      values: {
        xs: 0,   // extra small screens
        sm: 600, // small screens
        md: 960, // medium screens
        lg: 1280, // large screens
        xl: 1920 // extra large screens
      }
    },
    spacing: 8, // default spacing unit
    // Adjust padding values based on screen size
    paddingMain: {
      xs: '0px',
      sm: '10px',
      md: '16px',
      lg: '24px',
      xl: '40px'
    },

    components: {
      MuiTextField: {
          styleOverrides: {
              root: {
                  // Default styles for the root element
              },
          },
      },
      MuiOutlinedInput: {
          styleOverrides: {
              root: {
                  '& fieldset': {
                      
                      borderColor:  lighten("#6495ED", 0.3), // Default border color
                  },
                  '&:hover fieldset': {
                      borderColor: 'red', // Border color on hover
                  },
                  '&.Mui-focused fieldset': {
                      borderColor: 'red', // Border color on focus
                  },
                  '&.Mui-error fieldset': {
                      borderColor: 'red', // Border color for error state
                  },
              },
              input: {
                  color: 'darkblue', // Text color for the input
              },
          },
      },
      MuiFormLabel: {
          styleOverrides: {
              root: {
                  color: '#4B61D1', // Default label color
                  '&.Mui-focused': {
                      color: 'darkblue', // Label color on focus
                  },
              },
          },
      },
  },
  palette: {
      primary: {
          main: '#4B61D1', // Primary color for MUI components
      },
      secondary: {
          main: '#28a745', // Secondary color for MUI components
      },
      error: {
          main: '#dc3545', // Error color for MUI components
      },
      // Add more palette colors as needed
  },




  }); 

  const getLanguage = () =>{
    if(i18n.language ==='ta')
      return 'tamil'
    else 
      return 'english'

  }
  React.useEffect(()=>{
    console.log("-----use effect app-------")
    // if(localStorage.getItem('lang')) {
    // setLanguage(localStorage.getItem('lang'))
    // }
    setLoggedInUser(localStorage.getItem('username'))
  },[]);

  return (


    <div className="App" style={{backgroundColor:lighten( '#e6ecff',0.7)}}>
      
      <ThemeProvider theme={theme} >
      <ResponseContext.Provider value={{ responseData, setResponseData, requestData, setRequestData,
        loggedInUser, setLoggedInUser,openChart, setOpenChart,openLogin,setOpenLogin,openSignup,setOpenSignup,
        openEdit, setOpenEdit,currDasha,setCurrDasha,currBukthi,setCurrBukthi,dashaIrupu,setDashaIrupu,
        gotResponse, setGotResponse,mobileOpen, setMobileOpen,panchangData, setPanchangData,
        openEditProfile, setOpenEditProfile,chartSettings,setChartSettings,
        openChartSettings,setOpenChartSettings,isDateManual,setIsDateManual,
        openForgotPassword, setOpenForgotPassword,openCreate, setOpenCreate,lastResponse,setLastResponse
        }}>
     
        <BrowserRouter basename='/'>
      
        <MainLayout navigationData={loggedInUser? mainNavigationAfterLogin(getLanguage()): mainNavigation(getLanguage())} />
         
        </BrowserRouter>
        
      </ResponseContext.Provider>
      </ThemeProvider>
      
    </div>
    
  );
};

export default App;
