import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useState, useRef } from 'react';
import { SearchParamsContext } from './CreateChart';
import { FETCH_LOCATIONS_URL } from '../../config/constants';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, CircularProgress } from '@mui/material'; // Import CircularProgress for loading indicator
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import { ResponseContext } from '../../App';
import axios from 'axios';

export default function CountrySelect() {
  const { birthLocation, setBirthLocation, setLat, setLon } = React.useContext(SearchParamsContext);
  const { openEdit} = React.useContext(ResponseContext);
  const [options, setOptions] = useState([]);
  const [defaultSelect, setDefaultSelect] = useState();
  const [editPlace, setEditPlace] = useState(!openEdit);
  const [loading, setLoading] = useState(false); // State to manage loading indicator
  const { t } = useTranslation();
  const previousController = useRef();

  const getData = (searchTerm) => {
    setLoading(true); // Set loading state to true when fetching data
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    axios
      .get(`${FETCH_LOCATIONS_URL}${searchTerm}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        signal,
      })
      .then(function (response) {
        setOptions(response.data);
        setLoading(false); // Set loading state to false after receiving data
      })
      .catch(function (error) {
        console.error('Error fetching locations:', error);
        setLoading(false); // Make sure to set loading state to false on error too
      });
  };

  let timeout = null;
  const onInputChange = (event, value, reason) => {
    if (value && value.length>=3) {
      console.log(value)
      clearTimeout(timeout);
      timeout = setTimeout(function () {
        getData(value);
      }, 1000);
    } else {
      setOptions([]);
    }
  };

  const handleOnSelect = (event, value, reason) => {
    setBirthLocation(JSON.stringify(value));
    setLat(value.lat);
    setLon(value.lon);
  };

  React.useEffect(() => {
    if (birthLocation) {
      let birthVal = JSON.parse(birthLocation);
      setDefaultSelect(`${birthVal.place},${birthVal.state},${birthVal.country}`);
    }
  }, [birthLocation]);

  return (
    <div>
      <Grid display={editPlace ? 'none' : 'display'}>
        {defaultSelect}{' '}
        <IconButton size="small" onClick={() => setEditPlace(true)}>
          <DriveFileRenameOutlineIcon variant="contained" color="primary" />
        </IconButton>
      </Grid>
      <Grid display={editPlace ? 'display' : 'none'} sx="auto">
        <Grid display={openEdit ? 'display' : 'none'}>
          <IconButton size="small" sx={{ fontWeight: 'bold', float: 'right', marginRight: -5 }} onClick={() => setEditPlace(false)}>
            <CancelIcon variant="contained" color="primary" />
          </IconButton>
        </Grid>
        <Autocomplete
          id="country-select-demo"
          sx={{ width: openEdit ? 270 : 300 }}
          onChange={handleOnSelect}
          onInputChange={onInputChange}
          options={options}
          autoHighlight
          defaultValue={defaultSelect}
          getOptionLabel={(option) => `${option.place},${option.state}${option.state === '' ? '' : ','}${option.country}`}
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              {option.place},{option.state}
              {option.state === '' ? '' : ','}
              {option.country}
            </Box>
          )}
          renderInput={(params) => (
            <TextField 
              {...params}
              label={t('Birth place') + t(' (3 chars min)')}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'off', // disable autocomplete and autofill
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading && <CircularProgress color="inherit" size={20} />} {/* Loading indicator */}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Grid>
    </div>
  );
}
