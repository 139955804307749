import * as React from "react"
import Box from "@mui/material/Box"
import Collapse from "@mui/material/Collapse"
import IconButton from "@mui/material/IconButton"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import moment from "moment"
import { styled, Typography } from "@mui/material"
import { green } from "@mui/material/colors"
import { useTranslation } from 'react-i18next';
import { ResponseContext } from "../../../App"
import { useTheme } from "@emotion/react";
import { useMediaQuery } from '@mui/material';
import i18n from "../../common/i18n"
import '../../css/DashaTableDesign.css'
function calculateDateDifference(dateStr1, dateStr2) {
    const date1 = new Date(dateStr1);
    const date2 = new Date(dateStr2);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const years = Math.floor(diffDays / 365);
    const months = Math.floor((diffDays % 365) / 30);
    const days = diffDays % 30;
    return { years, months, days };
}
const StyledTable = styled(Table)(({ theme }) => ({
    width: '100%', 
    borderCollapse: 'collapse',
  }));
const CustomTableHead = styled(TableHead)({
    // backgroundColor: 'rgba(100, 149, 237, 0.4)',height: '20px',
    padding:0
  });
const CustomTableRow = styled(TableRow)(({ theme }) => ({
    borderColor:'red',
    minHeight: '20px',
    border: '0.5px solid lightblue',
    '&:nth-of-type(odd)': {
      },
    //   '&:nth-of-type(even)': {
    //     backgroundColor: 'rgba(100, 149, 237, 0.1)'
    //   },
    //   '&:hover': {
    //   backgroundColor: '#8fa6f1;'
    // },
  }));
  const CustomTableCell = styled(TableCell)(({ theme }) => ({
    // minHeight: '20px',
    // height:'20px',
    // borderWidth:0.7,
    // borderColor:'lightblue',
    border:'none'

  }));

function RowChild(props) {
    const { t } = useTranslation();
    const { row } = props
    const [open, setOpen] = React.useState(!true)
    const {setCurrBukthi} = React.useContext(ResponseContext);

    React.useEffect(() => {
        // Example of effect that runs only once on mount
        let is_curr_per = moment().isAfter(row.start_dt) && moment().isBefore(row.end_dt);
        if (is_curr_per) {
          setCurrBukthi(row.name);
        }
      }, [row, setCurrBukthi, i18n.language]);

   
    const is_current_period = (andhara_row) =>{
       return moment().isAfter(andhara_row.start_dt) && moment().isBefore(andhara_row.end_dt)
    }
    
    return (
        <React.Fragment >
            <CustomTableRow key={row} sx={{ "& > *": { borderBottom: "unset"} }} >

                <CustomTableCell>
                    <IconButton style={{ color: green ,paddingBottom: 0, paddingTop: 0}}
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >{open ? <KeyboardArrowUpIcon color="info"/> : <KeyboardArrowDownIcon color="info" />}</IconButton>


                </CustomTableCell>
                <CustomTableCell style={{ font:"16px Arial sans-serif",color: is_current_period(row) ? '#cc5200' : 'black', fontWeight:is_current_period(row)?'bold':'normal' }}>{t(row.name)}</CustomTableCell>
                <CustomTableCell align="center" style={{ color: is_current_period(row) ? '#cc5200' : 'black', fontWeight:is_current_period(row)?'bold':'normal' }}>{moment(row.start_dt).format('DD/MM/YYYY')}</CustomTableCell>
                <CustomTableCell align="center" style={{ color: is_current_period(row) ? '#cc5200' : 'black', fontWeight:is_current_period(row)?'bold':'normal' }}>{moment(row.end_dt).format('DD/MM/YYYY')}</CustomTableCell>
            </CustomTableRow>


            <CustomTableRow>
                <CustomTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box >
                            <Typography variant="h7" gutterBottom component="div"  style={{color:'darkblue',font:"bold 17px Arial sans-serif"}}>
                                {t("Andharam")}
                            </Typography>
                            <StyledTable size="small" aria-label="purchases">
                                <TableBody>
                                    {row.andhara_data.map(andhara_row => (
                                        
                                        <CustomTableRow key={andhara_row.name}>
                                           
                                            <CustomTableCell >
                                            </CustomTableCell>
                                            <CustomTableCell align="right" style={{font:"16px Arial sans-serif", color: is_current_period(andhara_row) ? '#cc5200' : 'black', fontWeight:is_current_period(andhara_row)?'bold':'normal' }}>{t(andhara_row.name)}</CustomTableCell>
                                            <CustomTableCell align="right" style={{ color: is_current_period(andhara_row) ? '#cc5200' : 'black', fontWeight:is_current_period(andhara_row)?'bold':'normal' }}>{moment(andhara_row.start_dt).format('DD/MM/YYYY')}</CustomTableCell>
                                            <CustomTableCell align="right" style={{ color: is_current_period(andhara_row) ? '#cc5200' : 'black', fontWeight:is_current_period(andhara_row)?'bold':'normal' }}>{moment(andhara_row.end_dt).format('DD/MM/YYYY')}</CustomTableCell>

                                        </CustomTableRow>
                                    ))}

                                </TableBody>
                            </StyledTable>
                        </Box>
                    </Collapse>
                </CustomTableCell>
            </CustomTableRow>
        </React.Fragment>
    )
}








function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(!row.open)
    const {setCurrDasha} = React.useContext(ResponseContext);
  
    const is_current_period = (row) =>{
        let is_curr_per = moment().isAfter(row.start_dt) && moment().isBefore(row.end_dt);
        if(is_curr_per) {
        setCurrDasha(row.name);
        }
        return is_curr_per
     }
     const { t } = useTranslation();
    return (
        <React.Fragment>
            <CustomTableRow sx={{ "& > *": { borderBottom: "unset"} }} >
                <CustomTableCell sx={{padding:0}}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)} 
                    >
                        {open ? <KeyboardArrowUpIcon color="info" /> : <KeyboardArrowDownIcon color="info" />}
                    </IconButton>
                </CustomTableCell>
                <CustomTableCell component="th" scope="row" style={{font:"17px Arial sans-serif", color: is_current_period(row) ? '#cc5200' : 'black', fontWeight:is_current_period(row)?'bold':'normal' }}>
                    {t(row.name)}
                </CustomTableCell >
                <CustomTableCell align="center" style={{ color: is_current_period(row) ? '#cc5200' : 'black', fontWeight:is_current_period(row)?'bold':'normal' }}>{moment(row.start_dt).format('DD/MM/YYYY')}</CustomTableCell>
                <CustomTableCell align="center" style={{ color: is_current_period(row) ? '#cc5200' : 'black', fontWeight:is_current_period(row)?'bold':'normal' }}>{moment(row.end_dt).format('DD/MM/YYYY')}</CustomTableCell>

            </CustomTableRow>
            <CustomTableRow >
                <CustomTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    {/* moment().isAfter(row.start_dt) && moment().isBefore(row.end_dt) */}
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h7"  component="div" fontWeight="bold" style={{color:'darkblue',font:"bold 17px Arial sans-serif"}}>
                                {t("Bukthi")}
                            </Typography>
                            <StyledTable size="small" aria-label="purchases">

                                <TableBody>



                                    {row.bukthi_data.map(historyRow => (

                                        <RowChild key={historyRow.name} row={historyRow} >

                                        </RowChild>
                                    ))}

                                </TableBody>
                            </StyledTable>
                        </Box>
                    </Collapse>
                </CustomTableCell>
            </CustomTableRow>
        </React.Fragment>
    )
}



export default function DashaTable() {
    const { t } = useTranslation();
    const [rows, setRows] = React.useState([])
    const {responseData,setDashaIrupu} = React.useContext(ResponseContext);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    React.useEffect(() => {

        
        let result = JSON.parse(localStorage.getItem('responseData'));
        if (result) {
            let first_dasha_end =result["dashaDetails"][0]["end_dt"];
            let dasha_name = result["dashaDetails"][0]["name"]
            let birthTimestamp=JSON.parse(localStorage.getItem('requestData')).birthTimeStamp;
            // console.log(birthTimestamp)
            const date = new Date(birthTimestamp);
            let birthDate=date.toISOString().split('T')[0];
            // let birth_date = result.data.input.input.year+"-"+result.data.input.input.month+"-"+result.data.input.input.date;
            const { years, months, days } = calculateDateDifference(birthDate, first_dasha_end);
            // console.log(years+","+months,","+days)
            let dashaIrupu = t(dasha_name) +" "+ years+" "+ t('Years') +" "+ months+" "+ t('Months') +" "+ days +" "+ t('Days') 
            setDashaIrupu(dashaIrupu)
            setRows(result["dashaDetails"])
        }

    }, [i18n.language])
    return (
        <div style={{width:isSmallScreen?'380px':'420px'}}>
        <TableContainer component={Paper}  >
            <StyledTable aria-label="collapsible table" >
                <CustomTableHead>
                    <CustomTableRow style={{ backgroundColor: 'rgba(100, 149, 237, 0.4)', height:30, }} >
                    <CustomTableCell />
                        <CustomTableCell style={{color:'darkblue',font:"bold 17px Arial sans-serif"}}>{t("Dasha")}</CustomTableCell>
                        
                        <CustomTableCell align="right" style={{color:'darkblue',font:"bold 17px Arial sans-serif"}}>{t("From")}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</CustomTableCell>
                        <CustomTableCell align="right" style={{color:'darkblue',font:"bold 17px Arial sans-serif"}}>{t("To")}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</CustomTableCell>

                    </CustomTableRow>
                </CustomTableHead>
                <TableBody>    

                    {rows.map(row => (
                        <Row key={row.name} row={row} >

                        </Row>


                    ))}
                </TableBody>
            </StyledTable>
        </TableContainer>
        </div>
    )
}
