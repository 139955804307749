import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { List, makeStyles } from "@material-ui/core";
import NavigationItem from "./NavigationItem";
import {useLocation } from "react-router-dom";

const useStyles = makeStyles({
  list: {
    listStyle: "none",
  },
});

const Navigation = ({ data, collapsed }) => {
  const classes = useStyles();
  const location =useLocation();
  console.log("-----useEffect Navigation-------")
  useEffect(() =>{

  },[]);

  if (!Array.isArray(data)) return null;

  let renderData = data?.map((item, index) => {
    if((!location.pathname.includes("details") && item.name!=="Calculations" && item.name!=="Edit Horoscope" && item.name!=="Birth Chart" && item.name!=="New Horoscope") || location.pathname.includes("details"))
    return <NavigationItem key={index} item={item} collapsed={collapsed} />;
  });

  return (
    <List className={classes.list} component='nav'>
      {renderData}
    </List>
  );
};

export default Navigation;

Navigation.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  collapsed: PropTypes.bool,
};
