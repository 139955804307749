// src/pages/CookiesPolicy.js
import React from 'react';
import styles from './Legal.module.css'; // Importing CSS Module

const CookiesPolicy = () => {
  return (
    <div className={styles.page}>
      <h1 className={styles.h1}>Cookies Policy</h1>

      <h2 className={styles.h2}>1. What Are Cookies?</h2>
      <p className={styles.p}>Cookies are small data files placed on your device that help us enhance your browsing experience. They allow us to remember your preferences, improve functionality, and provide personalized content based on your interactions with our website.</p>

      <h2 className={styles.h2}>2. Types of Cookies We Use</h2>
      <ul className={styles.ul}>
        <li className={styles.li}><strong>Essential Cookies:</strong> These cookies are crucial for the website to function correctly. They enable features such as account creation, login, and the generation and saving of horoscope charts. Without these cookies, essential services like secure access and form submissions may not work properly.</li>
        <li className={styles.li}><strong>Performance Cookies:</strong> These cookies help us understand how you interact with our website by collecting data on usage patterns. This information allows us to improve the performance and functionality of our site, ensuring a better user experience.</li>
        <li className={styles.li}><strong>Functional Cookies:</strong> These cookies enable the website to remember your preferences, such as your login status and custom settings. They also help in personalizing the site’s appearance and functionality based on your interactions, such as saving and updating your horoscope charts and profile information.</li>
        <li className={styles.li}><strong>Targeting Cookies:</strong> Used to deliver advertisements and content that are relevant to your interests. These cookies track your browsing behavior to provide personalized marketing and improve the relevance of the ads you see.</li>
      </ul>

      <h2 className={styles.h2}>3. Third-Party Cookies</h2>
      <p className={styles.p}>We may use third-party cookies from services that help us analyze site traffic and enhance our website’s functionality. These cookies might include:</p>
      <ul className={styles.ul}>
        <li className={styles.li}><strong>[Analytics Service Name]:</strong> Helps us understand visitor behavior and improve site performance.</li>
        <li className={styles.li}><strong>[Advertising Service Name]:</strong> Delivers personalized ads based on your interests and browsing history.</li>
      </ul>

      <h2 className={styles.h2}>4. How We Use Cookies</h2>
      <p className={styles.p}>Cookies are used for the following purposes:</p>
      <ul className={styles.ul}>
        <li className={styles.li}><strong>Account Management:</strong> Essential cookies help in managing your account details, such as registering, logging in, and verifying your email address. They ensure that your login status and user preferences are maintained across sessions.</li>
        <li className={styles.li}><strong>Horoscope Services:</strong> Cookies help in the generation and saving of your horoscope charts. They ensure that your horoscope information is stored and can be accessed or updated when logged in.</li>
        <li className={styles.li}><strong>Personalization:</strong> Cookies remember your profile settings and preferences, such as updates to your name, password, and optional profile details like gender and astrology interests.</li>
        <li className={styles.li}><strong>Site Customization:</strong> Functional cookies adjust how the site appears and operates based on your preferences, providing a personalized browsing experience.</li>
      </ul>

      <h2 className={styles.h2}>5. Managing Cookies</h2>
      <p className={styles.p}>You can manage your cookie preferences through your browser settings. Most browsers allow you to block or delete cookies. However, please be aware that blocking cookies might impact the functionality of our website, including features related to account management and personalized content.</p>

      <h2 className={styles.h2}>6. Changes to This Cookies Policy</h2>
      <p className={styles.p}>We may update this Cookies Policy from time to time to reflect changes in our practices or legal requirements. Any updates will be posted on this page with an updated effective date. We encourage you to review this policy periodically to stay informed about how we use cookies.</p>

      <h2 className={styles.h2}>7. Contact Us</h2>
      <p className={styles.p}>If you have any questions or concerns about our Cookies Policy, please contact us at [Your Contact Email].</p>
    </div>
  );
};

export default CookiesPolicy;
