
import * as React from 'react';

import MyLocationPicker from './BirthPlacePicker'

import Box from '@mui/material/Box';

import Grid from '@mui/material/Grid';
import { Checkbox, lighten, styled, Switch, TextField, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';

import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { ResponseContext } from '../../App';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createHoroscope } from '../../service/AstroService';
import { useTheme } from '@emotion/react';
import DateTimeEntry from './DateTimeEntry';
import moment from 'moment';
import DateTimeCombo from './DateTimeCombo';
export const SearchParamsContext = React.createContext();
export default function CreateChart(param) {
    // alert("editPage="+JSON.stringify(param))
    // alert(param.editPage?true:false)

    const { setResponseData, setRequestData, setOpenEdit,setOpenCreate, loggedInUser,setGotResponse,isDateManual,setIsDateManual,setLastResponse} = React.useContext(ResponseContext);
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const [id, setId] = React.useState();
    const [name, setName] = React.useState()
    const [birthTimeStamp, setBirthTimeStamp] = React.useState()
    const [birthLocation, setBirthLocation] = React.useState()
    const [lat, setLat] = React.useState();
    const [lon, setLon] = React.useState();
    const [gender, setGender] = React.useState('Male');
    const [isSave,setIsSave] =React.useState(localStorage.getItem('username')?true:false)
    const d = new Date();
    const [day, setDay] = React.useState(d.getDate());
    const [month, setMonth] = React.useState(d.getMonth());
    const [year, setYear] = React.useState(d.getFullYear());
    const [hour, setHour] = React.useState(d.getHours() % 12);
    const [minute, setMinute] = React.useState(d.getMinutes());
    const [ampm, setAmpm] = React.useState(d.getHours() % 12 === 0 ? 'AM' : 'PM');
    const [isManual, setIsManual] = React.useState(false);
    const [dateEntry, setDateEntry]  = React.useState('');
    const [timeEntry, setTimeEntry]  = React.useState('');
    const [amPmEntry, setAmPmEntry]  = React.useState('');
    // const [responseData, setResponseData] = React.useState();
   
    const navigation = useNavigate()

    const handleChange = (event) => {
        setIsManual(event.target.checked);
      };
    const handleGenderChange = (event, newGender) => {
        // alert(newGender)
        if (newGender !== null) {
            setGender(newGender); // Update state with the selected gender
        }
    };
    const handleCheckboxChange = (event) => {
        setIsSave(event.target.checked);
    };
    const CustomTooltipContent = () => (
        <div style={{ textAlign: 'center', fontSize: 14}}>
          <strong>{isManual ?  t('Change Date/Time components to dropdown menus.')
                                        : t('Change Date/Time fields to text boxes for quicker manual entry.')}</strong>
        </div>
      );

     // Helper function to validate date format (DD-MM-YYYY)
  const validateDate = () => {
    const datePattern = /^\d{2}-\d{2}-\d{4}$/;
    if (!dateEntry) return 'Date is required';
    if (!datePattern.test(dateEntry)) return 'Invalid date format (DD-MM-YYYY)';
    const [day, month, year] = dateEntry.split('-').map(Number);
    const dateObj = new Date(year, month - 1, day);
    if (dateObj.getFullYear() !== year || dateObj.getMonth() !== month - 1 || dateObj.getDate() !== day) {
      return 'Invalid date';
    }
    return '';
  };

  // Helper function to validate time format (HH:mm AM/PM)
  const validateTime = () => {
    const timePattern = /^([01]?[0-9]|2[0-3]):([0-5][0-9])$/;
    if (!timeEntry) return 'Time is required';
    if (!timePattern.test(timeEntry)) return 'Invalid time format (HH:mm)';
    if(timeEntry.split(':').map(Number)[0]>12) return 'Invalid hour';

    return '';
  };
    const getTimestampFromEntry = () => {
        
        const [day, month, year] = dateEntry.split('-').map(Number);
        const [hour, minute] = timeEntry.split(':').map(Number);
        
        let adjustedHour = hour;
        if (amPmEntry === 'PM' && hour < 12) {
          adjustedHour += 12;
        } else if (amPmEntry === 'AM' && hour === 12) {
          adjustedHour = 0;
        }
        
        const dateObj = new Date(year, month - 1, day, adjustedHour, minute);
        return dateObj.getTime();
      };
    const handleClick = () => {
        
        if (!name)
            alert("Please enter name")
        else if (!birthLocation)
            alert("Please enter birth place")
        else {
        if(isManual) {
            let errorMessage = validateDate();
            if(errorMessage) {
                alert(errorMessage);
                return;
            }
                
            errorMessage = validateTime();
            if(errorMessage) {
                alert(errorMessage);
                return;
            }

        }
        const hour_24 = hour + (ampm === 'AM' ? 0 : 12)
        const birthDate = moment().year(year).month(month).date(day).hour(hour_24).minute(minute);
        let myRequestData = {
            id: id,
            name: name,
            birthTimeStamp: isManual?getTimestampFromEntry():birthDate.valueOf(),
            birthPlace: JSON.parse(birthLocation),
            lat: lat,
            lon: lon,
            gender: gender,
        }
        
       
            setGotResponse(false)
            setOpenEdit(false)
            setOpenCreate(false)

            let pathPrefix = "/english"
                if (i18n.language === 'ta')
                    pathPrefix = '/tamil'
                else if (i18n.language === 'te')
                    pathPrefix = '/telugu'
            createHoroscope(isSave, myRequestData).then((response) => {
                myRequestData.id = response.data.requestId
                setRequestData(myRequestData)
                
                setResponseData(response)
                // this.props.history.push('/details')
                
                navigation(pathPrefix + "/details")
                setGotResponse(true)
                const timestamp = new Date().toISOString();
                setLastResponse(timestamp)
            }).catch(error => {
                const timestamp = new Date().toISOString();
                setLastResponse(timestamp)
                // navigation(pathPrefix)
                setGotResponse(true)
            })


        }
    }

    const formatDateValue = (value) => {
        return value > 9 ? value.toString() : `0${value}`;
      };
    React.useEffect(() => {
        
        setIsSave(localStorage.getItem('username')?true:false);
        const storedSettings = localStorage.getItem('chartSettings');
        const parsedSettings = storedSettings ? JSON.parse(storedSettings) : {};
        const dateComponent = parsedSettings.dateComponent;
        setIsDateManual(dateComponent === 'Dropdown' ? false : true);
        setIsManual(dateComponent === 'Dropdown' ? false : true);
        
        console.log("CreateChart useEffect render test ")

        if (param.editPage) {
            let request = JSON.parse(localStorage.getItem('requestData'));
            let birthDate = new Date(request.birthTimeStamp)
            setId(request["id"])
            setName(request["name"])
            setDay(birthDate.getDate())
            setMonth(birthDate.getMonth())
            setYear(birthDate.getFullYear())
            setHour(birthDate.getHours() % 12)
            setMinute(birthDate.getMinutes())
            // alert(birthDate.getHours())
            setAmpm(birthDate.getHours() > 11 ? 'PM' : 'AM')
            setDateEntry(formatDateValue(birthDate.getDate())+"-"+formatDateValue(birthDate.getMonth()+1)+"-"+birthDate.getFullYear())
            setAmPmEntry(birthDate.getHours() > 11 ? 'PM' : 'AM')
            // alert("++++>"+request["birthPlace"])
            let hourVal = birthDate.getHours() % 12;
            if(hourVal ===0)
                hourVal="12";
            setTimeEntry((formatDateValue(hourVal)+":"+formatDateValue(birthDate.getMinutes())));
            setBirthLocation(JSON.stringify(request["birthPlace"]))

            setLat(request["lat"])
            setLon(request["lon"])
            setGender(request["gender"])
        }
    }, [param.editPage,loggedInUser,isDateManual])

    const CustomSwitch = styled(Switch)(({ theme }) => ({
        transform: 'rotate(90deg)',
        '& .MuiSwitch-switchBase': {
          color: '#4B61D1', // Color of the switch knob
          '&.Mui-checked': {
            color: '#4caf50', // Color when the switch is on
          },
          '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#4caf50', // Color of the track when switch is on
          },
        },
        '& .MuiSwitch-track': {
          backgroundColor: 'blue', // Neutral gray color when the switch is off
        },
      }));
      const gradientBackground = `linear-gradient(
  
      rgba(100, 149, 237, 0.8) 0%,    /* Lightened Cornflower Blue */
      rgba(100, 149, 237, 0.5) 50%
    )`;
      
    return (

        <div >
            <SearchParamsContext.Provider value={{ name, setName, birthTimeStamp, setBirthTimeStamp, birthLocation, setBirthLocation, gender, setGender, setLat, setLon, 
                day, setDay, month, setMonth, year, setYear, hour, setHour, minute, setMinute, ampm, setAmpm ,
                dateEntry, setDateEntry, timeEntry, setTimeEntry, amPmEntry, setAmPmEntry }}>
                <Box sx={{ backgroundColor:'white' ,flexGrow: 1, width: 369, border: 5, borderColor: lighten("#6495ED", 0.4) ,borderRadius: theme.spacing(2)}}>
                    <Grid item xs="auto" sx={{ background: lighten("#6495ED", 0.4),borderTopLeftRadius: theme.spacing(1),borderTopRightRadius: theme.spacing(1), color: 'darkblue', fontWeight: 'bold', textAlign: 'left', padding: 1, fontSize: 17 }}>

                        {param.editPage ? t("Edit Horoscope") : t("New Horoscope")} 

                    </Grid>
                    <Grid container spacing={2} padding={3}>

                        <Grid item xs="auto">

                            <TextField sx={{ width: 300 }} value={name} label={t('Enter your name')} onChange={(event) => setName(event.target.value)} />
                        
                            
                        </Grid>
                        <Grid container style={{ width: 400,paddingLeft:15,paddingTop:15 }}>
                                {/* Main content area (98%) */}
                                <Grid item xs={11} style={{ display: 'flex', alignItems: 'center' }}>
                                    {isManual ? (
                                    <div>
                                        <DateTimeEntry />
                                    </div>
                                    ) : (
                                    <div>
                                        <DateTimeCombo />
                                    </div>
                                    )}
                                </Grid>

                                {/* Switch area (2%) */}
                                <Grid item xs={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <div style={{ zIndex: 1000, marginTop: -90, height: 20, fontSize: 10, width: 20,  }}>
                                    <Tooltip title={<CustomTooltipContent/>} arrow>
                                        <FormControlLabel
                                        control={
                                            <CustomSwitch
                                            checked={isManual}
                                            onChange={handleChange}
                                            color="primary"
                                            />
                                        }
                                        />
                                    </Tooltip>
                                    </div>
                                </Grid>
                                </Grid>

                        <Grid item xs="auto">
                       
                            <MyLocationPicker />
                        </Grid>
                        
                        <Grid item xs="auto">
                            <Grid container spacing={2}>
                                <Grid item>
                                    <ToggleButtonGroup color="secondary"
                                        value={gender}
                                        exclusive

                                        aria-label="gender"
                                    >
                                        <ToggleButton onChange={handleGenderChange}
                                            value="Male"
                                            aria-label="male"
                                            style={{
                                                height: 9,
                                                backgroundColor: gender === 'Male' ? '#4169E1' : 'inherit',
                                                color: gender === 'Male' ? 'white' : 'inherit',
                                            }}
                                        >
                                            {t('Male')}
                                        </ToggleButton>
                                        <ToggleButton onChange={handleGenderChange}
                                            value="Female"
                                            aria-label="female"
                                            style={{
                                                height: 9,
                                                backgroundColor: gender === 'Female' ? '#4169E1' : 'inherit',
                                                color: gender === 'Female' ? 'white' : 'inherit',
                                            }}
                                        >
                                            {t('Female')}
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid>
                                <Grid item mt={-1} mb={-2} display={loggedInUser?'display':'none'} >
                                    <FormControlLabel value={isSave}
                                        control={<Checkbox color='secondary' checked={isSave} onChange={handleCheckboxChange}/>}
                                        label={t("Save")}
                                    />
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid>
                            
                            <Button sx={{ width: 300, marginLeft: param.editPage ? 2 : 0, fontWeight: "bold",mt:2,ml:2 }} variant="contained" color="primary" onClick={handleClick}>
                                {t("Get My Horoscope")}
                            </Button >
                        </Grid>
                    </Grid>
                </Box>


            </SearchParamsContext.Provider>
        </div>
    );
};