// src/pages/VerifyEmail.jsx
import React, { useContext, useEffect, useState } from 'react';
import {  verifyEmail } from '../service/UserService';
import { ResponseContext } from '../App';

// Define inline styles as JavaScript objects
const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center', marginTop:-70,
    height: '100vh',
    background: 'linear-gradient(135deg, #f5f7fa, #c3cfe2)',
    fontFamily: 'Arial, sans-serif'
  },
  messageBox: (status) => ({
    textAlign: 'center',
    padding: '20px',
    borderRadius: '10px',
    maxWidth: '500px',
    width: '100%',
    background: '#fff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    color: status === 'success' ? '#4caf50' : '#f44336',
    borderLeft: `5px solid ${status === 'success' ? '#4caf50' : '#f44336'}`
  }),
  heading: {
    fontSize: '2.5rem',
    marginBottom: '15px'
  },
  paragraph: {
    fontSize: '1.2rem',
    color: '#333'
  }
};

const VerifyEmail = () => {
  const [status, setStatus] = useState('pending');
  const [message, setMessage] = useState('');
  const {setGotResponse} = useContext(ResponseContext);

  useEffect(() => {
    setGotResponse(false);
    const params = new URLSearchParams(window.location.search);
    const verificationId = params.get('verification_id');
    const token = params.get('token');

    if (verificationId && token) {
      verifyEmail(verificationId,token).then(response => {
        setStatus('success');
        setMessage('Your email has been successfully verified!');
        setGotResponse(true);
      })
      .catch(error => {
        setStatus('error');
        setMessage(error);
        setGotResponse(true);
      });
    } else {
      setStatus('error');
      setMessage('Invalid request, missing parameters.');
      setGotResponse(true);
    }
  }, []);
  return (
    <div style={styles.container}>
      <div style={styles.messageBox(status)}>
        <h1 style={styles.heading}>{status === 'success' ? 'Success!' : 'Oops!'}</h1>
        <p style={styles.paragraph}>{message}</p> 
        <div style={{color:'black'}}>Please re-login and check</div>
      </div>
      
    </div>
  );
};

export default VerifyEmail;
