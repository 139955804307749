import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import '../../css/MyTableDesign.css'
import { ResponseContext } from '../../../App';

export default function PlanetsStarLordTable() {
  const { t, i18n } = useTranslation();
  const { responseData } = useContext(ResponseContext);
  const [data, setData] = useState([]);

  const columns = [
    { field: 'PLANETS', headerName: t("Planets") },
    { field: 'DEGREE', headerName: t("Degree") },
    { field: 'RASHI', headerName: t("Ra") },
    { field: 'NAKSHATRA', headerName: t("Na") },
    { field: 'SUBLORD', headerName: t("Sub") },
    { field: 'SUBSUBLORD', headerName: t("S.S") }
  ];

  const convertFractDegree = (val) => {
    let array = val.split(".");
    let val1 = array[0];
    let val2 = parseInt(array[1]) * 60 / 100;
    if (val2 < 10) {
      val2 = "0" + val2;
    }
    return parseFloat(val1 + "." + val2).toFixed(2);
  };

  useEffect(() => {
    let result = JSON.parse(localStorage.getItem('responseData'));
    if(result && result.planetsPositions) {
    let newData = [];
    for (let i = 0; i < 10; i++) {
      let row = result.planetsPositions[i];
      if (!row) break; // Break if there's no data for this index

      let planetName = t(row.name);
      let isRetro = row.isRetro;
      let isCombustion = row.isCombustion;
      let isVarkotham = row.current_sign===row.navamsha_sign
      let degree = convertFractDegree(row.fullDegree.toFixed(2)) + "°";
      let rashilord = t(row.rashi_lord);
      let starlord = t(row.star_lord);
      let sublord = t(row.sub_star_lord);
      let subsublord = t(row.sub_sub_star_lord);
      newData.push({
        id: i + 1,
        PLANETS: planetName ,
        isCombustion:isCombustion,
        isRetro:isRetro,
        isVarkotham:isVarkotham,
        DEGREE: degree,
        RASHI: rashilord,
        NAKSHATRA: starlord,
        SUBLORD: sublord,
        SUBSUBLORD: subsublord
      });
    }
    setData(newData);      
  }
  }, [i18n.language, responseData]);

  return (
    <div>
 
      <div className="table-container">
        <table className="table">
          <thead className="thead">
            <tr>
              {columns.map((col) => (
                <th className="th" key={col.field}>{col.headerName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row) => (
              <tr className="tr" key={row.id}>
                <td className="td">{row.PLANETS}<span style={{fontSize:11}}>{row.isCombustion ? '🔥' : ''}</span><span style={{fontSize:11}}>{row.isRetro ? '◀' : ''}</span>
                <span style={{fontSize:6}}>{row.isVarkotham ? '🔲' : ''}</span></td>
                <td className="td">{row.DEGREE}</td>
                <td className="td">{row.RASHI}</td>
                <td className="td">{row.NAKSHATRA}</td>
                <td className="td">{row.SUBLORD}</td>
                <td className="td">{row.SUBSUBLORD}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
