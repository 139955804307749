// src/pages/TermsOfService.js
import React from 'react';
import styles from './Legal.module.css'; // Importing CSS Module

const TermsOfService = () => {
  return (
    <div className={styles.page}>
      <h1 className={styles.h1}>Terms of Service</h1>

      <h2 className={styles.h2}>1. Acceptance of Terms</h2>
      <p className={styles.p}>By using [Your Website Name], you agree to these Terms of Service. If you do not agree, please do not use our website.</p>

      <h2 className={styles.h2}>2. User Responsibilities</h2>
      <ul className={styles.ul}>
        <li className={styles.li}>You must provide accurate information when signing up or using our services.</li>
        <li className={styles.li}>You are responsible for maintaining the confidentiality of your account information, including your username and password.</li>
        <li className={styles.li}>You must comply with all applicable laws and regulations while using our website.</li>
      </ul>

      <h2 className={styles.h2}>3. Services Provided</h2>
      <p className={styles.p}>We provide horoscope chart generation and related services. We reserve the right to modify or discontinue services at our discretion.</p>

      <h2 className={styles.h2}>4. Intellectual Property</h2>
      <p className={styles.p}>All content on our website, including text, graphics, and software, is owned by [Your Website Name] and protected by copyright laws. You may not reproduce, distribute, or create derivative works without our express written permission.</p>

      <h2 className={styles.h2}>5. Limitation of Liability</h2>
      <p className={styles.p}>[Your Website Name] is not liable for any indirect, incidental, or consequential damages arising from your use of our website or services.</p>

      <h2 className={styles.h2}>6. Termination</h2>
      <p className={styles.p}>We reserve the right to suspend or terminate your account if you violate these Terms of Service.</p>

      <h2 className={styles.h2}>7. Governing Law</h2>
      <p className={styles.p}>These Terms of Service are governed by and construed in accordance with the laws of India. Any disputes arising out of or in connection with these Terms of Service shall be subject to the exclusive jurisdiction of the courts located in Tiruchirappalli, Tamil Nadu, India.</p>

      <h2 className={styles.h2}>8. Changes to Terms</h2>
      <p className={styles.p}>We may update these Terms of Service from time to time. Changes will be posted on this page with an updated effective date.</p>

      <h2 className={styles.h2}>9. Contact Us</h2>
      <p className={styles.p}>For any questions regarding these Terms of Service, please contact us at [Your Contact Email].</p>
    </div>
  );
};

export default TermsOfService;
