import * as React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { Dialog, DialogContent, IconButton, styled, Typography } from '@mui/material';
import RaashiChart from '../details/charts/RaashiChart'; // Adjust the import path as needed

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(1),
        width: '440px',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
        width: '465px',
        marginLeft: -27,
    },
    [theme.breakpoints.up('sm')]: {
        maxWidth: '100%',
    },
    height: 'auto',
}));

const HeadingContainer = styled('div')(({ theme }) => ({
    backgroundColor: '#4B61D1',  // Main blue color
    color: '#fff',
    fontWeight: 'bold',
    padding: theme.spacing(1),
    textAlign: 'left',
    borderBottom: `1px solid ${theme.palette.divider}`, marginTop:-10,marginLeft:-10,marginBottom:20,marginRight:-10
}));

const Heading = styled(Typography)(({ theme }) => ({
    fontSize: '1.1rem',
    fontWeight: 700,
    margin: theme.spacing(0.5),
}));



export default function TodayPlanetsDialog({ open, onClose }) {
    return (
        <BootstrapDialog
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            PaperProps={{
                sx: {
                    borderRadius: 5,
                },
            }}
        >
            <IconButton
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    color: 'white',
                }}
            >
                <CancelIcon />
            </IconButton>
            <DialogContent >
                <HeadingContainer style={{width:442}}>
                    <Heading>Today's Planets Position</Heading>
                    {/* <Divider /> */}
                </HeadingContainer>
                <RaashiChart showTodayPlanets={'true'} />
            </DialogContent>
        </BootstrapDialog>
    );
}
