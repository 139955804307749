import { useTranslation } from 'react-i18next';

export const cleanLocalStorage = (error) => {
  
  if (String(error.message).includes('401') || String(error.message).includes('422')) {
    localStorage.removeItem('access_token');
    localStorage.removeItem('username');
    }
}
export const useGetHomePageUrl = () => {
    const { i18n } = useTranslation();
  
    return () => {
      if (i18n.language === 'ta') return '/tamil';
      else return '/english';
    };
  };