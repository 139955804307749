import { useContext, useEffect, useState } from "react"
import { createVargaCharts } from "../../../service/AstroService";
import NavamshaChart from "./NavamshaChart";
import Chart from "./Chart";

import { useTranslation } from "react-i18next";
import { Divider, Grid, lighten, Paper, Typography } from "@mui/material";
import {useLocation } from "react-router-dom";
import { ResponseContext } from "../../../App";

export default function VargaCharts () {
    const { t,i18n } = useTranslation();
    const location =useLocation();
    const [d1,setD1] = useState();
    const [d2,setD2] = useState();
    const [d3,setD3] = useState();
    const [d4,setD4] = useState();
    const [d7,setD7] = useState();
    const [d9,setD9] = useState();
    const [d10,setD10] = useState();
    const [d12,setD12] = useState();
    const [d16,setD16] = useState();
    const [d20,setD20] = useState();
    const [d24,setD24] = useState();
    const [d27,setD27] = useState();
    const [d30,setD30] = useState();
    const [d40,setD40] = useState();
    const [d45,setD45] = useState();
    const [d60,setD60] = useState();
    const {setGotResponse} = useContext(ResponseContext);
    const convertData = (data) => {
        return Object.values(data).reduce((result, item) => {
            if(item) {
            const { name, fullDegree } = item;
            if(name)
            result[name] = fullDegree;
            }
            return result;
        }, {});
    };
    const convertD1 = (data) => {
        return Object.values(data).reduce((result, item) => {
            if(item) {
            const { name, current_sign } = item;
            if(name)
            result[name] = current_sign;
            }
            return result;
        }, {});
    };
    useEffect(() =>{
// alert(location.pathname)
        console.log("-----------vargcharts useEffect----------")
        if(location.pathname.includes('tamil') && i18n.language !=='ta')
            i18n.changeLanguage('ta') ;
          else if(location.pathname.includes('telugu') && i18n.language !=='te')
            i18n.changeLanguage('te');
          else if(location.pathname.includes('english') && i18n.language !=='en')
            i18n.changeLanguage('en');
        // alert('test')
        let data = JSON.parse(localStorage.getItem("responseData"))
        const vargaInput = convertData(data.planetsPositions);
        setD1(convertD1(data.planetsPositions));
        setGotResponse(false);
        createVargaCharts(vargaInput).then((response)=>{
            setD2(response.data.d2)
            setD3(response.data.d3)
            setD4(response.data.d4)
            setD7(response.data.d7)
            setD9(response.data.d9)
            setD10(response.data.d10)
            setD12(response.data.d12)
            setD16(response.data.d16)
            setD20(response.data.d20)
            setD24(response.data.d24)
            setD27(response.data.d27)
            setD30(response.data.d30)
            setD40(response.data.d40)
            setD45(response.data.d45)
            setD60(response.data.d60)
            setGotResponse(true);

        })
        

    },[location])

    return(         <div style={{ padding: '10px' }}>
        <Typography variant="h5" component="h1" align="left" gutterBottom style={{fontWeight:'bold',color: 'darkblue' }}>
            {t("Shodashvarga Charts")}
        </Typography>
        <Divider sx={{ filter: 'blur(1px) opacity(0.5)', borderColor: 'blue', width: '100%', marginBottom: '10px' }} />
        {/* <div style={{width:800}}> */}
        <Grid container spacing={3} justifyContent="left" sx={{maxWidth:900}} >
            <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="h6" component="h2" style={{fontWeight:'bold', marginBottom: '5px', color: 'darkblue', paddingBottom:10 }}>
                        {t("Lagna - D1")}
                    </Typography>
                    <Chart vargaInput={d1} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} >
                    <Typography variant="h6" component="h2" style={{fontWeight:'bold', marginBottom: '5px', color: 'darkblue',paddingBottom:10 }}>
                        {t("Wealth - D2")}
                    </Typography>
                    <Chart vargaInput={d2} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="h6" component="h2" style={{fontWeight:'bold', marginBottom: '5px', color: 'darkblue',paddingBottom:10 }}>
                        {t("Siblings - D3")}
                    </Typography>
                    <Chart vargaInput={d3} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="h6" component="h2" style={{fontWeight:'bold', marginBottom: '5px', color: 'darkblue',paddingBottom:10 }}>
                        {t("Luck - D4")}
                    </Typography>
                    <Chart vargaInput={d4} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="h6" component="h2" style={{fontWeight:'bold', marginBottom: '5px', color: 'darkblue',paddingBottom:10 }}>
                        {t("Childrens - D7")}
                    </Typography>
                    <Chart vargaInput={d7} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="h6" component="h2" style={{fontWeight:'bold', marginBottom: '5px', color: 'darkblue',paddingBottom:10 }}>
                        {t("Spouse - D9")}
                    </Typography>
                    <Chart vargaInput={d9} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="h6" component="h2" style={{fontWeight:'bold', marginBottom: '5px', color: 'darkblue',paddingBottom:10 }}>
                        {t("Profession - D10")}
                    </Typography>
                    <Chart vargaInput={d10} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="h6" component="h2" style={{fontWeight:'bold', marginBottom: '5px', color: 'darkblue',paddingBottom:10 }}>
                        {t("Parents - D12")}
                    </Typography>
                    <Chart vargaInput={d12} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="h6" component="h2" style={{fontWeight:'bold', marginBottom: '5px', color: 'darkblue',paddingBottom:10 }}>
                        {t("Vehicles - D16")}
                    </Typography>
                    <Chart vargaInput={d16} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="h6" component="h2" style={{fontWeight:'bold', marginBottom: '5px', color: 'darkblue',paddingBottom:10 }}>
                        {t("Religious - D20")}
                    </Typography>
                    <Chart vargaInput={d20} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="h6" component="h2" style={{fontWeight:'bold', marginBottom: '5px', color: 'darkblue',paddingBottom:10 }}>
                        {t("Education - D24")}
                    </Typography>
                    <Chart vargaInput={d24} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="h6" component="h2" style={{fontWeight:'bold', marginBottom: '5px', color: 'darkblue',paddingBottom:10 }}>
                        {t("Strengths and Weakness - D27")}
                    </Typography>
                    <Chart vargaInput={d27} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="h6" component="h2" style={{fontWeight:'bold', marginBottom: '5px', color: 'darkblue',paddingBottom:10 }}>
                        {t("Misfortune - D30")}
                    </Typography>
                    <Chart vargaInput={d30} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="h6" component="h2" style={{fontWeight:'bold', marginBottom: '5px', color: 'darkblue',paddingBottom:10 }}>
                        {t("Auspicious Result - D40")}
                    </Typography>
                    <Chart vargaInput={d40} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="h6" component="h2" style={{fontWeight:'bold', marginBottom: '5px', color: 'darkblue',paddingBottom:10 }}>
                        {t("General Well Being - D45")}
                    </Typography>
                    <Chart vargaInput={d45} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="h6" component="h2" style={{fontWeight:'bold', marginBottom: '5px', color: 'darkblue',paddingBottom:10 }}>
                        {t("General Well Being - D60")}
                    </Typography>
                    <Chart vargaInput={d60} />
            </Grid>
        </Grid>
        {/* </div> */}
    </div>)

}