import React, { useContext, useEffect } from "react"

import { useTranslation } from 'react-i18next';
import CreateChart from "../astro/search/CreateChart";
import { Box, CircularProgress, Grid, Typography, useMediaQuery } from "@mui/material";
import { ResponseContext } from "../App";
import { useTheme } from "@emotion/react";
import PanchangDetails from "../astro/details/horoscopes/PanchangDetails";
import RaashiChart from "../astro/details/charts/RaashiChart";
import { lighten, makeStyles } from "@material-ui/core";
import { fetchTodayPanchang } from "../service/AstroService";
import SignUpBenefits from "../user/SignUpBenefits";
import PrimeBenefits from "../user/PrimeBenefits";
const useStyles = makeStyles((theme) => ({
  roundedBox: {
    borderRadius: theme.spacing(2), // Adjust the border radius as needed
    [theme.breakpoints.down('md')]: {
      // border: `2px solid ${lighten( '#6495ED',0.4) }`,
      backgroundColor: 'white', height: 770,
      minWidth: '98%', marginLeft: 7
    },
    [theme.breakpoints.up('md')]: {
      // border: `4px solid #ccd9ff`, 
      border: `4px solid ${lighten('#6495ED', 0.4)}`,
      // borderTop: `40px solid #ccd9ff`, 
      // padding: theme.spacing(1),
      margin: 7,
      backgroundColor: 'white', height: 470,
      minWidth: '800px'
    },

  },
}));

export default function HomePageTamil() {
  const { t, i18n } = useTranslation();
  const { setPanchangData,loggedInUser,setGotResponse } = useContext(ResponseContext)
  const [pageLoading, setPageLoading] = React.useState()
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const classes = useStyles();
  useEffect(() => {
    console.log("HomePageTamil useEffect------")
    setGotResponse(false);
    const handleLanguageChange = async () => {
      setGotResponse(false);

    await i18n.changeLanguage('ta');
    setGotResponse(true);
  };
  handleLanguageChange();

    setPageLoading(true)
    fetchTodayPanchang().then(response => {
      setPageLoading(false)
      setPanchangData(true)
    }).catch(error => {
      console.log("Error while fetching panchang")
      setPageLoading(false)
    });

    // setEnglish('active')
    // setTamil("")

  }, [i18n])
  return (
    <>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="keywords" content="astrology, horoscope, zodiac signs, birth charts, astrology predictions, astrological readings"/>

      <Grid container >

      <Grid container spacing={2} paddingTop={2} paddingBottom={2} style={{width:850}} >
      <Grid item xs={12} sm={4} xl={6} md={6} sx={{minWidth:390}}>
      <div style={{marginLeft:7}}><CreateChart  /></div>
      </Grid>
      <Grid item xs={12} sm={4} xl={6} md={6} sx={{minWidth:390}}>
      <Grid display={loggedInUser?'block':'none'}> <PrimeBenefits/> </Grid>
      <Grid display={!loggedInUser?'block':'none'}> <SignUpBenefits/> </Grid>
      </Grid>
    </Grid>
        

        <Box className={classes.roundedBox}>
          <Grid item xs="auto" sx={{ borderTopLeftRadius: theme.spacing(1), borderTopRightRadius: theme.spacing(1), background: lighten("#6495ED", 0.4), color: 'darkblue', fontWeight: 'bold', textAlign: 'left', padding: 1, fontSize: 17 }}>
            {t("Daily Panchang")}
          </Grid>

          <Grid container spacing={1} sx={{ paddingTop: 20, paddingLeft: isSmallScreen ? 15 : 40, display: pageLoading ? 'display' : 'none' }}>
            <CircularProgress /><Typography pl={0} variant="h5"> Loading...</Typography>
          </Grid>
          <Grid container spacing={1} sx={{ display: pageLoading ? 'none' : 'display' }} >
            {/* First Row */}
            <Grid item xs={12} sm={5} xl={5}>
              <PanchangDetails />
            </Grid>
            <Grid item xs={12} sm={7} xl={7}  >
              <RaashiChart />
            </Grid>

          </Grid>
        </Box>
      </Grid>
    
    </>

  )
}