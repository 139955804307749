import React, { Fragment } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  // CssBaseline,
  // Divider,
  Drawer,
  Hidden,
  IconButton,
  Toolbar,
  // Typography,
} from "@material-ui/core";

import {
  Box,
  Button,
  // AppBar,
  CssBaseline,
  Divider,
  lighten,
  Tooltip,
  // Drawer,
  // Hidden,
  // IconButton,
  // Toolbar,
} from '@mui/material';
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  HomeOutlined,
  Settings,
} from '@mui/icons-material';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Backdrop, CircularProgress, Grid, useMediaQuery } from "@mui/material";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Navigation from "./navigation/Navigation";

import { Routes, Route } from "react-router-dom";
import OpenChartDialog from "../astro/search/OpenChartDialog";
import LoginIcon from '@mui/icons-material/Login';
import { useTranslation } from 'react-i18next';
import { ResponseContext } from "../App";
import AshtakVargaDetails from "../astro/details/calculations/AshtakVargaDetails";
import PrastharashtakvargaDetails from "../astro/details/calculations/PrastharashtakvargaDetails";
import VargaCharts from "../astro/details/charts/VargaCharts";
import HoroscopeDetails from "../astro/details/horoscopes/HoroscopeDetails";
import AshtakVargaDetailsMobile from "../astro/details/calculations/AshtakVargaDetailsMobile";
import PrastharashtakvargaDetailsMobile from "../astro/details/calculations/PrastharashtakvargaDetailsMobile";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ProfileMenu from "./menu/ProfileMenu";
import LanguageMenu from "./menu/LanguageMenu";
import EditProfile from "../user/EditProfileDialog";
import PlanetStrength from "../astro/details/horoscopes/PlanetsStrength";
import SettingsDialog from "../astro/details/horoscopes/SettingsDialog";
import PrintableComponent from "../astro/search/PrintableComponent";
import EmailVerificationAlert from "../user/EmailVerificationAlert";
import VerifyEmail from "../user/VerifyEmail";
import ForgotPasswordDialog from "../user/ForgotPasswordDialog";
import ResetPassword from "../user/ResetPassword";
import LoginSignupDialog from "../user/LoginSignupDialog";
import Footer from "./Footer";
import HomePageTamil from "../home/HomePageTamil";
import HomePageTelugu from "../home/HomePageTelugu";
import HomePage from "../home/HomePage";
import HoroscopeSaveAlert from "../astro/details/horoscopes/HoroscopeSaveAlert";
import CreateEditChartDialog from "../astro/search/CreateEditChartDialog";
import PrivacyPolicy from "../legal/PrivacyPolicy";
import TermsOfService from "../legal/TermsOfService";
import Disclaimer from "../legal/Disclaimer";
import CookiesPolicy from "../legal/CookiesPolicy";
import ContactUs from "../legal/ContactUs";

const drawerWidth = 290;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
  },
  appBarShift: {
    [theme.breakpoints.up("md")]: {
      zIndex: theme.zIndex.drawer + 2,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      zIndex: theme.zIndex.drawer + 2,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  collapseButton: {
    color: "inherit",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  extendButton: {
    marginRight: 36,
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  extendButtonHidden: {
    display: "none",
  },
  toolbar: { paddingRight: 24, ...theme.mixins.toolbar },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    width: drawerWidth,
    display: "flex",
    position: "fixed",
    height: "100vh",
    whiteSpace: "nowrap",
    transition: theme.transitions.create(["width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),

    color: theme.palette.type === "light" && theme.palette.grey[100],
    backgroundColor: "#191970"
    // backgroundColor: theme.palette.secondary.main,

  },
  drawerPaperClose: {
    [theme.breakpoints.up("md")]: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
    },
  },
  appBarTitle: {
    flex: 1,
    fontWeight: 200,
  },
  contentShift: {
    flexGrow: 1,
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(7),
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  content: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },

  drawerFooter: {
    flex: 1,
    alignItems: "flex-end",
    justifyContent: "center",
    padding: theme.spacing(2),
  },
  copyrightText: {
    fontSize: 11,
    transition: "all .3s",
    [theme.breakpoints.up("md")]: {
      opacity: (extend) => (extend ? 1 : 0),
    },
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: "inherit",
  },
}));

const ProtectedRoute = ({ element }) => {
  const responseData = localStorage.getItem('responseData');
  const location = useLocation();
  if (!responseData) {
    let url = '/english';
    if (location.pathname.includes('tamil'))
      url = '/tamil';
    return <Navigate to={url} />;
  }

  // Otherwise, render the passed component
  return element;
};
const MainLayout = ({ navigationData, children }) => {
  const { setGotResponse, loggedInUser, setOpenLogin, gotResponse, mobileOpen, setMobileOpen, setOpenEdit,setOpenCreate, setOpenChart,setOpenChartSettings } = React.useContext(ResponseContext)
  const location = useLocation();
  const navigation = useNavigate();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [extended, setExtended] = React.useState(true);
  const classes = useStyles(extended);

  const navigateToHome = () => {
    navigation(getHomePageUrl());
  }
  const getHomePageUrl = () => {
    var homePageUrl ="/english";
    if (i18n.language === 'ta')
      homePageUrl ="/tamil";
    else if (i18n.language === 'te')
      homePageUrl ="/telugu";
    return homePageUrl;
  }


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleExtendOpen = () => {
    setExtended(true);
  };
  const handleExtendClose = () => {
    setExtended(false);
  };

  const openEditHoroscope = () => {
    setOpenEdit(true)
  }
  const openCreateHoroscope = () => {
    setOpenCreate(true)
  }
  const setOpenHoroscope = () => {
    if (loggedInUser) {
      setOpenChart(true)
    }
    else {
      setOpenChart(false)
      setOpenLogin(true)

    }

  }

  const CustomTooltipContent = ({ content }) => (
    <div style={{ textAlign: 'center', fontSize: 14}}>
      <strong>{content}</strong>
    </div>
  );

  function substringBeforeSpace(str) {
    const index = str.indexOf(' ');
    return index !== -1 ? str.substring(0, index) : str;
  }



  const textStyle = {
    fontFamily: 'Arial, sans-serif', // Font family
    fontSize: '26px',               // Font size
    width: '300px',                 // Width of the text block
    background: 'linear-gradient(to right, #FF9A9E, #FECFEF, #FAD0C4, #FECF7D, #F8E8A8, #B9FBC0, #E3F9F5, #D0F4C9, #C2E0FF, #B9E5F2, #AEEEEE)', // Multi-color gradient background
    WebkitBackgroundClip: 'text',   // For WebKit browsers to clip background to text
    WebkitTextFillColor: 'transparent', // Make the text color transparent so background shows
    backgroundClip: 'text',         // Standard property for background clipping
    textFillColor: 'transparent',   // Standard property for text fill
    fontWeight: 'bold',             // Make the font bold
    display: 'inline-block',        // Inline block display
    whiteSpace: 'nowrap',
  };

  const drawer = (
    <Fragment>
      <div className={classes.toolbarIcon}>
        <div style={textStyle}>🪐TheAstroKing</div>
        <IconButton
          onClick={handleExtendClose}
          className={classes.collapseButton}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <Navigation data={navigationData} collapsed={!extended} />


    </Fragment>
  );

  return (
    <div className={classes.root}>

      <CssBaseline />
      <AppBar style={{ backgroundColor: '#4B61D1'}}
        position='fixed'
        className={clsx(classes.appBarShift, extended && classes.appBar)}>
        <Toolbar className={classes.toolbar}>

          <IconButton
            edge='start'
            color='inherit'
            aria-label='extend drawer'
            onClick={handleExtendOpen}
            className={clsx(
              classes.extendButton,
              extended && classes.extendButtonHidden
            )}>
            <MenuIcon />
            {/* <div style={{color:'white',fontSize:23,fontWeight:'bold',marginLeft:15}}>꧁༺AstroKing༻꧂</div> */}
          </IconButton>

          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            className={classes.menuButton}>
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              // padding: '2px 4px',
              backgroundColor: 'inherit', // Match top bar color
              borderRadius: 2,
              // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // Shadow for the banner
            }}
          >
            <Tooltip  title={<CustomTooltipContent  content={t('Home')}/>} arrow>
              <IconButton
                color="inherit"
                onClick={navigateToHome}
                sx={{
                  fontSize: 20,
                  '&:hover': {
                    backgroundColor: '#003d80', // Darker blue for hover
                    color: '#ffffff',
                  },
                  marginRight: 2, // Space between icons
                }}
              >
                <HomeOutlined />
              </IconButton>
            </Tooltip>

            <Tooltip  title={<CustomTooltipContent  content={t('New Horoscope')}/>} arrow>
              <IconButton
                color="inherit"
                onClick={openCreateHoroscope}
                sx={{
                  fontSize: 20,
                  '&:hover': {
                    backgroundColor: '#003d80', // Darker blue for hover
                    color: '#ffffff',
                  },
                  marginRight: 2, // Space between icons
                }}
              >
                <NoteAddIcon />
              </IconButton>
            </Tooltip>
            
            <Tooltip title={<CustomTooltipContent content={t('Edit Horoscope')}/> } arrow style={{ display: location.pathname.includes('details') ? 'block' : 'none' }} >
              <IconButton onClick={openEditHoroscope}
                color="inherit"
                sx={{
                  fontSize: 20,
                  '&:hover': {
                    backgroundColor: '#003d80', // Darker blue for hover
                    color: '#ffffff',
                  },
                  marginRight: 2, // Space between icons
                }}
              >
                <DriveFileRenameOutlineIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={<CustomTooltipContent content={t('Open Horoscope')}/> }
              arrow
              style={{ display: loggedInUser ? 'block' : 'none' }} // Correct conditional display values
            >
              <IconButton
                onClick={setOpenHoroscope}
                color="inherit"
                sx={{
                  fontSize: 20,
                  '&:hover': {
                    backgroundColor: '#003d80', // Darker blue for hover
                    color: '#ffffff',
                  },
                }}
              >
                <FileOpenIcon />
              </IconButton>
            </Tooltip>

          </Box> 
          {!isSmallScreen && loggedInUser && (
          <Tooltip title={<CustomTooltipContent content={t('Settings')}/> } arrow>
              <IconButton
                color="inherit"
                onClick={()=>{setOpenChartSettings(true)}}
                sx={{
                  fontSize: 20,
                  '&:hover': {
                    backgroundColor: '#003d80', // Darker blue for hover
                    color: '#ffffff',
                  },
                  marginRight: 2, // Space between icons
                }}
              >
                <Settings  />
              </IconButton>
            </Tooltip>)}

            {! isMobile && location.pathname.includes('details') &&  (
            <Tooltip title={<CustomTooltipContent content={t('Print as PDF')}/>} arrow>
              <IconButton
                color="inherit"
                // onClick={()=>{setOpenChartSettings(true)}}
                sx={{
                  fontSize: 20,
                  '&:hover': {
                    backgroundColor: '#003d80', // Darker blue for hover
                    color: '#ffffff',
                  },
                  marginRight: 2, // Space between icons
                }}
              >
                <PrintableComponent/>
              </IconButton>
            </Tooltip>)}
            
          
          <LanguageMenu />
          <Grid container justifyContent="flex-end" p={1}>
            <Grid item>
              {/* Only display the button if the user is not logged in */}
              {!loggedInUser && (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<LoginIcon />} // Add icon to the button
                  onClick={() => setOpenLogin(true)} // Handle click event
                  sx={{
                    borderRadius: '10px',
                    padding: '5px 10px',
                    background: lighten('#4B61D1', 0.1),
                    textTransform: 'none',
                    fontWeight: 600,
                    color: '#fff',
                    transition: 'background 0.3s, box-shadow 0.3s',
                    '&:hover': {
                      background: 'linear-gradient(to right, #004bb5, #0095d9)',
                    },
                    '& .MuiButton-startIcon': {
                      marginRight: '8px', // Adjust spacing between icon and text
                    },
                    float: 'right'
                  }}
                >
                  Login
                </Button>
              )}
              {loggedInUser && (
                <div>
                  <ProfileMenu 
                    loggedInUser={loggedInUser}
                    substringBeforeSpace={substringBeforeSpace}
                  />
                </div>
              )}
            </Grid>
          </Grid>



        </Toolbar>

      </AppBar>



      <Hidden mdUp implementation='css'>
        <Drawer
          variant='temporary'
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}>
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation='css'>
        <Drawer
          variant='permanent'
          classes={{
            paper: clsx(
              classes.drawerPaper,
              !extended && classes.drawerPaperClose
            ),
          }}
          open={extended}>
          {drawer}
        </Drawer>
      </Hidden>
      <main className={clsx(classes.contentShift, extended && classes.content)}>
        <div className={classes.toolbar} />
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={!gotResponse}
        >
          <CircularProgress color="inherit" /> <div style={{ margin: 10, fontWeight: 'bold', fontSize: 19 }}>Loading...</div>
        </Backdrop>


        <div
          style={{
            flex: 1,
            flexDirection: "column", padding: 5, marginLeft: isSmallScreen ? 0 : 10, display: gotResponse ? 'flex' : 'none'
          }}>
          <Routes>

            <Route path="/tamil" element={<HomePageTamil />}></Route>
            <Route path="/telugu" element={<HomePageTelugu />}></Route>
            <Route path="/english" element={<HomePage />}></Route>
            <Route path="/" element={<HomePage />}></Route>

            <Route path="/english/details" element={<ProtectedRoute element={<HoroscopeDetails />} />}></Route>
            <Route path="/tamil/details" element={<ProtectedRoute element={<HoroscopeDetails />} />}></Route>
            <Route path="/telugu/details" element={<ProtectedRoute element={<HoroscopeDetails />} />}></Route>
            
            <Route path="/english/details/ashtakvarga" element={<ProtectedRoute element={isMobile ? <AshtakVargaDetailsMobile /> : <AshtakVargaDetails />} />} ></Route>
            <Route path="/tamil/details/ashtakvarga" element={<ProtectedRoute element={isMobile ? <AshtakVargaDetailsMobile /> : <AshtakVargaDetails />} />} ></Route>
            <Route path="/telugu/details/ashtakvarga" element={<ProtectedRoute element={isMobile ? <AshtakVargaDetailsMobile /> : <AshtakVargaDetails />} />} ></Route>
            
            <Route path="/english/details/prastharashtakvarga" element={<ProtectedRoute element={isMobile ? <PrastharashtakvargaDetailsMobile /> : <PrastharashtakvargaDetails />} />} ></Route>
            <Route path="/tamil/details/prastharashtakvarga" element={<ProtectedRoute element={isMobile ? <PrastharashtakvargaDetailsMobile /> : <PrastharashtakvargaDetails />} />} ></Route>
            <Route path="/telugu/details/prastharashtakvarga" element={<ProtectedRoute element={isMobile ? <PrastharashtakvargaDetailsMobile /> : <PrastharashtakvargaDetails />} />} ></Route>
            {/* <Route path="/shodashvargacharts" element={<VargaCharts />} ></Route> */}
            
            <Route path="/english/details/shodashvargacharts" element={<ProtectedRoute element={<VargaCharts />} />} ></Route>
            <Route path="/tamil/details/shodashvargacharts" element={<ProtectedRoute element={<VargaCharts />} />} ></Route>
            <Route path="/telugu/details/shodashvargacharts" element={<ProtectedRoute element={<VargaCharts />} />} ></Route>
            {/* <Route path="/tamil/details/dashadetails" element={<ProtectedRoute element={<DashaDetailsPage />} />} ></Route> */}
            <Route path="/tamil/details/planetstrength" element={<ProtectedRoute element={<PlanetStrength />} />} ></Route>
            <Route path="/verify-email" element={<ProtectedRoute element={<VerifyEmail />} />} ></Route>
            <Route path="/reset-password" element={<ProtectedRoute element={<ResetPassword />} />} ></Route>

            <Route path="/privacy" element={<ProtectedRoute element={<PrivacyPolicy />} />} ></Route>
            <Route path="/terms-of-service" element={<ProtectedRoute element={<TermsOfService />} />} ></Route>
            <Route path="/disclaimer" element={<ProtectedRoute element={<Disclaimer />} />} ></Route>
            <Route path="/cookie" element={<ProtectedRoute element={<CookiesPolicy />} />} ></Route>
            <Route path="/contact" element={<ProtectedRoute element={<ContactUs />} />} ></Route>
          </Routes>
          <EditProfile />
          <LoginSignupDialog />
          <OpenChartDialog />
          <CreateEditChartDialog />
          <SettingsDialog/>
          <EmailVerificationAlert/>
          <ForgotPasswordDialog/>
          
        </div>
        <HoroscopeSaveAlert/>
        <Footer/>
      </main>
      
    </div>
  );
};

export default MainLayout;

MainLayout.propTypes = {
  navigationData: PropTypes.arrayOf(PropTypes.object).isRequired,
  themeConfig: PropTypes.object,
  window: PropTypes.func,
  children: PropTypes.node,
};
