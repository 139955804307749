import React from 'react';
import { Snackbar, Alert } from '@mui/material';
import { ResponseContext } from '../../../App';

const AutoHideAlert = ({ message, onClose }) => {
  const autoHideDuration = 5000;

  return (
    <Snackbar
      open={!!message}  // Open if message is not empty
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}  // Position at the bottom center
      sx={{
        position: 'fixed',
        bottom: '20px',  // 20px from the bottom
        left: '50%',
        transform: 'translateX(-50%)',  // Center horizontally
        zIndex: (theme) => theme.zIndex.snackbar,  // Ensure it appears above other content
      }}
    >
      <Alert
        onClose={onClose}
        severity={message && message.includes('success') ? 'success' : 'error'}
        sx={{
          '& .MuiAlert-icon': {
            fontSize: '1.5rem',
            padding: '0.5rem',
          },
          '& .MuiAlert-message': {
            fontSize: '1rem',
          },
          ...(message && message.includes('success')
            ? { 
                bgcolor: '#28a745',  // Beautiful green color
                color: '#ffffff',
                borderRadius: '12px',
                padding: '1rem',
                boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                fontWeight: 'bold',
              }
            : {
                bgcolor: '#dc3545',  // Error red color
                color: '#ffffff',
                borderRadius: '12px',
                padding: '1rem',
                boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                fontWeight: 'bold',
              }
          )
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};
const HoroscopeSaveAlert = () => {
  const [message, setMessage] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const { setLoggedInUser,lastResponse } = React.useContext(ResponseContext);
  React.useEffect(() => {
    console.log("useEffect running");
   
    const result = localStorage.getItem('responseMessage');
    
    if (result !== undefined && result !==null) {
      setMessage(result);
      setOpen(true);
      localStorage.removeItem('responseMessage')
      const timer = setTimeout(() => {
        setOpen(false);
        if(result.includes("Session may have expired"))
          setLoggedInUser('')
        setMessage(''); // Clear the message
      }, 7000);
      return () => clearTimeout(timer); // Cleanup timer on unmount
    }
   
  }, [lastResponse]);

  console.log("Message:", message);
  console.log("Open:", open);

  return (
    <div>
      {open && <AutoHideAlert message={message} onClose={() => setOpen(false)} />}
    </div>
  );
};

export default HoroscopeSaveAlert;
