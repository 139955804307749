import React from 'react';
import { Button, Typography } from '@mui/material';
import { GetApp as GetAppIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import html2pdf from 'html2pdf.js';
import PrintIcon from '@mui/icons-material/Print';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
// Custom styles for the button
const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#4B61D1',  // Blue color
  color: '#fff',
  '&:hover': {
    backgroundColor: '#3a4da1',  // Darker blue on hover
  },
  borderRadius: '8px',
  padding: theme.spacing(1, 2),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const StyledIcon = styled(GetAppIcon)(({ theme }) => ({
  fontSize: '1.2rem',
}));

const ExportPDFButton = ({ onClick }) => {
  return (
    <StyledButton onClick={onClick}>
      <StyledIcon />
      <Typography variant="button">Export PDF</Typography>
    </StyledButton>
  );
};

const PrintableComponent = () => {
  const handleDownloadPDF101 = () => {
    const element = document.getElementById('contentToPrint');
    if (element) {
      html2canvas(element, { scale: 2, useCORS: true }).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const doc = new jsPDF('p', 'px', 'a4');
        const imgWidth = window.innerWidth > 1600 ? 610 : 410; 
        const pageHeight = 595; // A4 height in px
        const imgHeight = canvas.height * imgWidth / canvas.width;
        const headerHeight = 20; // Adjust this value to your header height
        let heightLeft = imgHeight;
        let position = headerHeight; // Start position below the header
  
        // Add the first page with header
        doc.text(`www.theastroking.com`, 15, headerHeight - 10); // Add header text
        doc.setTextColor(0, 0, 139)
        // Add the image of the canvas to the PDF
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
  
        while (heightLeft >= 0) {
          doc.addPage();
          position = heightLeft - imgHeight + headerHeight;
          doc.text(`My Header - `, 15, headerHeight - 10); // Add header text on new pages
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
  
        doc.save(`TheAstroKing.pdf`);
      }).catch(err => console.error('Error generating PDF:', err));
    } else {
      console.error('Element not found!');
    }
  };
  

  const handleDownloadPDF = () => {
    const element = document.getElementById('contentToPrint');
    const now = new Date();
    const formattedDate = now.toISOString().slice(0, 19).replace(/[-T:]/g, '');
    if (element) {
      const options = {
        margin: [0, 0], // Adjust margins if needed
        
      
        filename: `TheAstroKing_${formattedDate}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2, useCORS: true }, // Ensure high quality and CORS support
        jsPDF: { unit: 'px', format: [1150, element.offsetHeight], orientation: 'portrait' } 
        // jsPDF: { unit: 'px', format: [element.offsetWidth, element.offsetHeight], orientation: 'portrait' } // Use px and format based on content
      };
      html2pdf()
        .from(element)
        .set(options)
        .save()
        .catch(err => console.error('Error generating PDF:', err));
    } else {
      console.error('Element not found!');
    }
  };

  const handleDownloadPDF2 = () => {
    const element = document.getElementById('contentToPrint');
    if (element) {
      const options = {
        margin: [2, 2], // Adjust margins as needed
        filename: 'document.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' } // Fixed page size (A4)
      };
      html2pdf()
        .from(element)
        .set(options)
        .save()
        .catch(err => console.error('Error generating PDF:', err));
    } else {
      console.error('Element not found!');
    }
  };

  return (
    <div style={{marginTop:5}}>
    
      <PrintIcon onClick={handleDownloadPDF101} />
    </div>
  );
};

export default PrintableComponent;
