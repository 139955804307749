import React, { useState, useEffect, useContext } from 'react';
import { lighten } from '@mui/material';
import { ResponseContext } from '../App';

const footerStyles = {
  backgroundColor: lighten('#87CEEB', 0.7), // Light Blue Background
  color: 'darkblue', // Dark Blue Text Color
  padding: '20px',
  textAlign: 'center',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
  position: 'relative', 
  marginTop: 40,
};

const footerContentStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: '1200px',
  margin: '0 auto', // Center the content
};

const footerTextStyles = {
  margin: '0 0 10px 0',
  fontSize: '16px',
};

const footerLinksContainerStyles = {
  display: 'flex',
  justifyContent: 'center',
  gap: '20px', // Space between links
  flexWrap: 'wrap', // Allows wrapping on smaller screens
};

const footerLinkStyles = {
  color: 'blue', // Bright Blue for Links
  textDecoration: 'none',
  fontSize: '14px',
  transition: 'color 0.3s ease, text-decoration 0.3s ease',
};

const footerLinkHoverStyles = {
  color: '#1565C0', // Darker Blue on Hover
  textDecoration: 'underline',
};

const Footer = () => {
  const [showFooter, setShowFooter] = useState(false);
  const {gotResponse} = useContext(ResponseContext);

  useEffect(() => {
    setShowFooter(false);
    // Simulate loading completion
    const timer = setTimeout(() => {
      setShowFooter(gotResponse);
    }, 100); // Adjust the timeout as needed to fit your content loading time

    return () => clearTimeout(timer);
  }, [gotResponse]);

  return (
    showFooter && (
      <footer style={footerStyles}>
        <div style={footerContentStyles}>
          <p style={footerTextStyles}>
            &copy; {new Date().getFullYear()} Your Company Name
          </p>
          <div style={footerLinksContainerStyles}>
            <a
              href="/privacy"
              style={footerLinkStyles}
              onMouseEnter={(e) => (e.target.style.color = footerLinkHoverStyles.color)}
              onMouseLeave={(e) => (e.target.style.color = footerLinkStyles.color)}
            >
              Privacy Policy
            </a>
            <a
              href="/terms-of-service"
              style={footerLinkStyles}
              onMouseEnter={(e) => (e.target.style.color = footerLinkHoverStyles.color)}
              onMouseLeave={(e) => (e.target.style.color = footerLinkStyles.color)}
            >
              Terms of Service
            </a>
            <a
              href="/cookie"
              style={footerLinkStyles}
              onMouseEnter={(e) => (e.target.style.color = footerLinkHoverStyles.color)}
              onMouseLeave={(e) => (e.target.style.color = footerLinkStyles.color)}
            >
              Cookie Policy
            </a>
            <a
              href="/disclaimer"
              style={footerLinkStyles}
              onMouseEnter={(e) => (e.target.style.color = footerLinkHoverStyles.color)}
              onMouseLeave={(e) => (e.target.style.color = footerLinkStyles.color)}
            >
              Disclaimer
            </a>
            
            <a
              href="/contact"
              style={footerLinkStyles}
              onMouseEnter={(e) => (e.target.style.color = footerLinkHoverStyles.color)}
              onMouseLeave={(e) => (e.target.style.color = footerLinkStyles.color)}
            >
              Contact Us
            </a>
          </div>
        </div>
      </footer>
    )
  );
};

export default Footer;
