import React, { useContext, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import Autocomplete from '@mui/material/Autocomplete';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { getAllTags, getHoroscopeNotesTags, updateHoroscopeNotesTags } from '../../../service/AstroService';
import { ResponseContext } from '../../../App';
import { Backdrop, CircularProgress } from '@mui/material';

// Custom styling for dialog components
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: '#f5f5f5',
    borderRadius: '8px',
    maxWidth: '900px',minWidth: '388px',
    width: 600,
    boxShadow: `0px 4px 20px rgba(0, 0, 0, 0.1)`,
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: '#4B61D1', // Main blue color
  color: '#fff',
  fontWeight: 'bold',
  marginBottom: theme.spacing(3),
  padding: theme.spacing(1), // Reduced padding for a smaller header height
  textAlign: 'left',
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2), // Space between elements
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginTop: 7,
  width: '100%',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#4B61D1',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#3a4da1',
  },
}));

// Maximum number of tags allowed
const MAX_TAGS = 4;

const ChartNotesDialog = ({ open, handleClose }) => {
  const [horoscopeId, setHoroscopeId] = useState();
  const [notes, setNotes] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const { setGotResponse, gotResponse, setLoggedInUser, setOpenLogin } = useContext(ResponseContext);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [tagsOptions, setTagsOptions] = useState([]);
  const [isEditing, setIsEditing] = useState(false); // New state for toggling edit mode

  // Handle notes change
  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  };

  // Handle tag selection change
  const handleTagChange = (event, newValue) => {
    if (newValue.length > MAX_TAGS) {
      setErrorMessage(`You can select up to ${MAX_TAGS} tags only.`);
    } else {
      setErrorMessage('');
      setSelectedTags(newValue);
    }
  };

  // Handle save logic
  const handleSave = () => {
    setGotResponse(false);

    // Prepare the tag IDs from selectedTags
    const tagIds = selectedTags.map(tag => tag.id);

    // Save the notes data to localStorage
    let notesData = {
      horoscopeId: horoscopeId,
      notes: notes,
      tag_ids: tagIds
    };

    localStorage.setItem("notesData", JSON.stringify(notesData));

    // Call the API to update the horoscope notes and tags
    updateHoroscopeNotesTags(horoscopeId, notes, tagIds)
      .then((response) => {
        setSnackbarMessage(`Notes and Tags are updated successfully`);
        setSnackbarOpen(true);
        setGotResponse(true);
        setIsEditing(false); // Exit edit mode upon successful save
      })
      .catch((error) => {
        setGotResponse(true);

        if (error.response && (error.response.status === 422 || error.response.status === 401)) {
          alert("Your session has expired. Please log in again to continue.")
          handleClose();
          setOpenLogin(true);
          setLoggedInUser("");
          localStorage.setItem('username','')
          
        } else {
          const errorMessage = error.response?.data?.error || 'An unexpected error occurred.';
          setSnackbarMessage(errorMessage); 
          setSnackbarOpen(true);
        }


      });
  };

  useEffect(() => {
    if (open) {
      setGotResponse(false);
      
      let myRequest = JSON.parse(localStorage.getItem('requestData'));
      setHoroscopeId(myRequest.id);
      getAllTags()
        .then(response => {
          const tagsOptionsLocal = response;
          setTagsOptions(tagsOptionsLocal);
          getHoroscopeNotesTags(myRequest.id)
            .then(response => {
              setNotes(response.notes);

              // Map tag IDs to tag objects
              const tags = response.tag_ids.map(id => tagsOptionsLocal.find(tag => tag.id === id)).filter(tag => tag);
              setSelectedTags(tags);
              setGotResponse(true);
            })
            .catch((error) => {
              handleClose();
              setGotResponse(true);
              if (error.response && (error.response.status === 422 || error.response.status === 401)) {
               
                setLoggedInUser("");
                setOpenLogin(true);
              } else {
                const errMsg = error.response?.data?.error || 'An unexpected error occurred.';
                alert(errMsg)
              }
            });
        });
    }
  }, [open]);

  return (
    <StyledDialog open={open} onClose={handleClose}>
      <StyledDialogTitle>Chart Notes and Tags</StyledDialogTitle>
      <StyledDialogContent>
        <StyledTextField
          label="Notes"
          multiline
          rows={15}
          variant="outlined"
          value={notes}
          onChange={isEditing ? handleNotesChange : undefined} // Only handle change when in edit mode
          InputProps={{
            readOnly: !isEditing, // Set field as read-only if not editing
          }}
        />
        <Autocomplete readOnly={!isEditing}
          multiple
          options={tagsOptions}
          getOptionLabel={(option) => option.name} // Use the name property for display
          value={selectedTags}
          onChange={isEditing ? (event, newValue) => handleTagChange(event, newValue) : undefined} // Only handle tag change when in edit mode
          renderInput={(params) => (
            <TextField 
              {...params} 
              label="Tags" 
              variant="outlined" 
              placeholder="Select tags" 
              InputProps={{
                ...params.InputProps,
                // readOnly: !isEditing, // Set field as read-only if not editing
              }} 
            />
          )}
          renderTags={(tags, getTagProps) =>
            tags.map((tag, index) => (
              <Chip
                label={tag.name} // Display the name property
                key={index}
                {...getTagProps({ index })}
                onDelete={isEditing ? () => setSelectedTags(tags.filter(t => t.id !== tag.id)) : undefined} // Only handle delete if in edit mode
                deleteIcon={<CancelIcon />}
              />
            ))
          }
          disableCloseOnSelect={!isEditing} // Prevent closing tags if not in edit mode
        />
        {errorMessage && (
          <Stack spacing={2} sx={{ marginTop: 2 }}>
            <Alert severity="error">{errorMessage}</Alert>
          </Stack>
        )}
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={!gotResponse}
        >
          <CircularProgress color="inherit" />
          <div style={{ margin: 10, fontWeight: 'bold', fontSize: 19 }}>Loading....</div>
        </Backdrop>
      </StyledDialogContent>
      <DialogActions>
        <StyledButton onClick={() => setIsEditing(!isEditing)}>
          {isEditing ? 'Cancel' : 'Edit'}
        </StyledButton>
        {isEditing && <StyledButton onClick={handleSave}>Save</StyledButton>}
        <StyledButton onClick={handleClose}>Close</StyledButton>
      </DialogActions>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => { setSnackbarOpen(false) }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => { setSnackbarOpen(false) }} severity={snackbarMessage.includes('successfully') ? 'success' : 'error'}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </StyledDialog>
  );
};

export default ChartNotesDialog;
