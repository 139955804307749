import React, { useEffect, useState } from 'react';
import {
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Box,
  Popover,
  Divider,
  useMediaQuery,
  useTheme,
  Tooltip,
  lighten,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ResponseContext } from "../../App";
import { userLogout } from '../../service/UserService';
import i18n from '../../astro/common/i18n';
import { useNavigate } from 'react-router-dom';
import { Settings } from '@mui/icons-material';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LogoutIcon from '@mui/icons-material/Logout';
const ProfileMenu = ({ loggedInUser, substringBeforeSpace }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const { setGotResponse,setLoggedInUser,setRequestData,setOpenEditProfile,setOpenChartSettings} = React.useContext(ResponseContext)
  const navigation = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  
  const CustomTooltipContent = () => (
    <div style={{ textAlign: 'center',fontSize:14 }}>
      <strong>{loggedInUser}</strong>
    </div>
  );

  const handleClick = (event) => {
    if (isMobile) {
      setPopoverAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setPopoverAnchorEl(null);
  };
  const navigateToHome= () => {
    // alert(i18n.language)
    if (i18n.language === 'ta')
        navigation('/tamil')
    else
        navigation('/english')
  }
  const logoutPage = () => {
    handleClose()
    setGotResponse(false)
    
    userLogout().then(() => {
      setLoggedInUser("");
      setRequestData("");
      setGotResponse(true)
      navigateToHome();
    });
  };
  const setOpenEditProfilePage = () => {
    handleClose()
    setOpenEditProfile(true)
  }
  const handleSetOpenChartSettings = () => {
    handleClose()
    setOpenChartSettings(true)
  }
  
  const openPopover = Boolean(popoverAnchorEl);
  const openMenu = Boolean(anchorEl);
  const id = openMenu ? 'profile-menu' : undefined;

  const userName = substringBeforeSpace(loggedInUser);

  useEffect(()=> {
    console.log("----Profile Menu useEffect----")
  

  },[loggedInUser]);

  return (
    <div>
      {loggedInUser && (
        <>
          {isMobile ? (
            <>
              <IconButton
                size="small"
                onClick={handleClick}
                sx={{
                  fontWeight: 'bold', marginLeft:-1,
                  '&:hover': {
                    backgroundColor: 'transparent',
                    color: '#007BFF',
                  },
                }}
              >
                <PersonIcon sx={{ fontSize: 30, color: '#ffffff' }} />
                <ArrowDropDownIcon sx={{ fontSize: 30, color: '#ffffff' }} />
              </IconButton>
              <Popover
                open={openPopover}
                anchorEl={popoverAnchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                PaperProps={{
                  sx: {
                    width: '50vw',
                    maxWidth: 300,
                    backgroundColor: '#ffffff',
                    borderRadius: 1.5,
                    boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.2)',
                    padding: '8px 0',
                    border: '1px solid #ddd',
                  },
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    padding: '8px 16px',
                    backgroundColor: '#007BFF',
                    color: '#ffffff',
                    fontWeight: 'bold',
                    borderTopLeftRadius: 12,
                    borderTopRightRadius: 12,
                  }}
                >
                  {userName}
                </Typography>
                <Divider />
                <MenuItem onClick={logoutPage} sx={{ padding: '8px 16px' }}>
                <LogoutIcon style={{width:16,marginRight:3}}/> <Typography variant="body2" >Logout</Typography>
                </MenuItem>
                <MenuItem onClick={setOpenEditProfilePage} sx={{ padding: '8px 16px' }}>
                <ManageAccountsIcon style={{width:16,marginRight:3}}/> <Typography variant="body2">Edit Profile</Typography>
                </MenuItem>
                <MenuItem onClick={handleSetOpenChartSettings} sx={{ padding: '8px 16px' }}>
                <Settings style={{width:16,marginRight:3}}/> <Typography variant="body2">Settings</Typography>
                </MenuItem>
              </Popover>
            </>
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: lighten('#4B61D1',0.1),
                padding: '1px 2px',
                borderRadius: 2,
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                position: 'relative',
              }}
            >
              <Tooltip title={<CustomTooltipContent />} >
                <IconButton
                  size="small"
                  onClick={handleClick}
                  sx={{
                    fontWeight: 'bold',
                    color: '#ffffff',
                    '&:hover': {
                      backgroundColor: 'transparent',
                      color: '#007BFF',
                    },
                    marginRight: 1,
                  }}
                >
                  <PersonIcon sx={{ fontSize: 30 }} />
                </IconButton>
              </Tooltip>
              <Typography
                variant="body2"
                sx={{
                  color: '#ffffff',
                  fontWeight: 'bold',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  marginRight: 1,
                }}
              >
                {userName}
              </Typography>
              <IconButton
                size="small"
                onClick={handleClick}
                sx={{
                  color: '#ffffff',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    color: '#007BFF',
                  },
                }}
              >
                <ArrowDropDownIcon sx={{ fontSize: 30 }} />
              </IconButton>
              <Menu
                id={id}
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
                sx={{
                  mt: 1.5,
                  '& .MuiPaper-root': {
                    backgroundColor: '#ffffff',
                    borderRadius: 1.5,
                    boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.2)',
                    width: '15ch',
                    maxHeight: 48 * 4.5,
                    overflowY: 'auto',
                    border: '1px solid #ddd',
                  },
                }}
              >
                <MenuItem onClick={logoutPage} sx={{ padding: '8px 16px' }}>
                <LogoutIcon style={{width:16,marginRight:3}}/> <Typography variant="body2" >Logout</Typography>
                </MenuItem>
                <MenuItem onClick={setOpenEditProfilePage} sx={{ padding: '8px 16px' }}>
                <ManageAccountsIcon style={{width:16,marginRight:3}}/> <Typography variant="body2">Edit Profile</Typography>
                </MenuItem>
                <MenuItem onClick={handleSetOpenChartSettings} sx={{ padding: '8px 16px' }}>
                <Settings style={{width:16,marginRight:3}}/> <Typography variant="body2">Settings</Typography>
                </MenuItem>
              </Menu>
            </Box>
          )}
        </>
      )}
    </div>
  );
};

export default ProfileMenu;
