
import * as React from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import { Dialog, DialogContent, IconButton, lighten, styled } from '@mui/material';

import { ResponseContext } from '../../App';
import CreateChart from './CreateChart';
export const SearchParamsContext = React.createContext();

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(1), width: "388px" // Example padding for dialog content


  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2) // Example padding for dialog actions

  },
  // Custom width and height styles based on Bootstrap-like grid system
  [theme.breakpoints.down('sm')]: {
    width: '445px', // Full width on small screens
    marginLeft: -27
  },
  [theme.breakpoints.up('sm')]: {

    maxWidth: '100%', // Maximum width on screens from small (sm) and up
  },
  height: 'auto', // Auto height based on content

}));



export default function CreateEditChartDialog() {
  const { openEdit, setOpenEdit, openCreate, setOpenCreate } = React.useContext(ResponseContext);
  const handleClose = () => {
    setOpenEdit(false);
    setOpenCreate(false);
  };
  const gradientBackground = `linear-gradient(
  
    rgba(100, 149, 237, 0.8) 0%,    /* Lightened Cornflower Blue */
    rgba(100, 149, 237, 0.5) 50%
  )`;
  return (

    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={openEdit || openCreate} PaperProps={{
        sx: { background: gradientBackground,
          borderRadius: 5, // Customize border radius here
        },
      }}
    >
      {/* <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Modal title
        </DialogTitle> */}
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 9,
          top: 7,
          color: 'darkblue',
        }}
      >
        <CancelIcon />
      </IconButton>
      <DialogContent >



        <CreateChart editPage={!openCreate} />


      </DialogContent>

    </BootstrapDialog>

  );
};