import axios from "axios";
import { DELETE_MY_HOROSCOPE, FETCH_TODAY_PANCHANG_URL, GENERATE_ASHTAK_VARGA_URL, GENERATE_HOROSCOPE_URL, GENERATE_VARGA_CHARTS_URL, GET_ALL_TAGS, GET_HOROSCOPE_NOTES_TAGS, GET_MY_HOROSCOPES_URL, UPDATE_HOROSCOPE_NOTES_TAGS } from "../config/constants";
import moment from "moment";
import {cleanLocalStorage} from './Utitility'
import { getCsrfToken } from "./UserService";
export const createHoroscope = async (isSave,myRequestData) => {
    try {
        let headersData = {};
        if (isSave && myRequestData.id) {
            const { csrfToken, token } = await getCsrfToken();  
            headersData = {
                'Access-Control-Allow-Origin': '*',  
                'X-CSRF-Token': csrfToken,
                'Authorization': `Bearer ${token}`,
                'X-Server': localStorage.getItem('userType')
            };
        } else {
            headersData = {
                'Access-Control-Allow-Origin': '*' ,
                'X-Server': localStorage.getItem('userType') 
            };
        }

        // const token = localStorage.getItem('access_token');
        const params = new URLSearchParams({ isSave }).toString();
        // alert(token)
        const response = await axios.post(GENERATE_HOROSCOPE_URL+`?${params}`, myRequestData, {
            headers: headersData
        });
        response.data.output["dashaDetails"].forEach((value, index, array) => {
            if (moment().isAfter(value.start_dt) && moment().isBefore(value.end_dt)) {
                value.open = false
            } else {
                value.open = true
            }
        });
        myRequestData.id= response.data.requestId;
        localStorage.setItem('responseData',JSON.stringify(response.data.output));
        if(response.data.output.message)
        localStorage.setItem('responseMessage',response.data.output.message);
        else 
        localStorage.setItem('responseMessage','');
        localStorage.setItem('requestData',JSON.stringify(myRequestData));

        return response
    } catch (error) {
        console.error('create horoscope error:', error);
        const errorMessage = error.response?.data?.error ||  'An unexpected error occurred.';
        localStorage.setItem('responseMessage',errorMessage);
        throw error
    }
}
export const fetchMySavedHoroscopes = async (searchName2 = '',selectTagId = 0, page = 0, pageSize = 10) => {
    try {
        // const token = localStorage.getItem('access_token');
        const response = await axios.get(GET_MY_HOROSCOPES_URL, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'X-Server': localStorage.getItem('userType')
            },
            params: {
                searchName: searchName2 || 'RECENT_10',
                tagId: selectTagId || null,
                page: page,
                pageSize: pageSize
            }
        });
        
        return response
    } catch (error) {
        console.error('Signup error:', error);
        cleanLocalStorage(error);
        throw error
    }
}
export const deleteMyHoroscope = async (id,csrfToken) => {
    try {
        // const token = localStorage.getItem('access_token');
        const {csrfToken,token} = await getCsrfToken();
        const response = await axios.delete(DELETE_MY_HOROSCOPE+`${id}`,{
        headers: {
            'Access-Control-Allow-Origin': '*',
            'X-CSRF-Token': csrfToken,
            'Authorization': `Bearer ${token}`,
            'X-Server': localStorage.getItem('userType')
        }
    });
    if(localStorage.getItem('requestData') && id===JSON.parse(localStorage.getItem('requestData')).id)
    localStorage.removeItem('requestData')
        return response
    } catch (error) {
        console.error('Error while delete chart:', error);
        cleanLocalStorage(error);
        throw error
    }
}

export const fetchTodayPanchang = async (language) => {
    try {
        // alert(token)
        const response = await axios.get(FETCH_TODAY_PANCHANG_URL, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'X-Server': localStorage.getItem('userType')
            }
        });
        localStorage.setItem('panchangData',JSON.stringify(response.data.output["panchang"]))
        localStorage.setItem('todaysPlanetsData',JSON.stringify(response.data.output["planetsPositions"]))
        return response
    } catch (error) {
        console.error('Error while fetch today panchang:', error);
        throw error
    }
}
export const createAshtakVarga = async (ashtakvargaInput) => {
    try {
        const response = await axios.post(GENERATE_ASHTAK_VARGA_URL, ashtakvargaInput,{
            headers: {
                'Access-Control-Allow-Origin': '*',
                'X-Server': localStorage.getItem('userType')
            }
        });
        localStorage.setItem('ashtakData',JSON.stringify(response))
        return response
    } catch (error) {
        console.error('Error while create ashtak varga:', error);
        throw error
    }
}
export const createVargaCharts = async (vargaInput) => {
    try {
        const response = await axios.post(GENERATE_VARGA_CHARTS_URL, vargaInput,{
            headers: {
                'Access-Control-Allow-Origin': '*',
                'X-Server': localStorage.getItem('userType')
            }
        });
        localStorage.setItem('vargaData',JSON.stringify(response))
        return response
    } catch (error) {
        console.error('Error while create varga charts:', error);
        throw error
    }
}

export const getHoroscopeNotesTags = async (horoscopeId) => {
    try {
        // const token = localStorage.getItem('access_token');
        const response = await axios.get(GET_HOROSCOPE_NOTES_TAGS, {
            params: { horoscope_id: horoscopeId },
            headers: {
                'Access-Control-Allow-Origin': '*',
                'X-Server': localStorage.getItem('userType')
            }
        });
        return response.data;  // Assuming the response contains { notes, tag_ids }
    } catch (error) {
        console.error('Error while retrieving notes and tags:', error);
        throw error;
    }
};
export const updateHoroscopeNotesTags = async (horoscopeId, notes, tagIds) => {
    try {
        // const token = localStorage.getItem('access_token');
        const {csrfToken,token} = await getCsrfToken();
        const response = await axios.post(UPDATE_HOROSCOPE_NOTES_TAGS, {
            horoscope_id: horoscopeId,
            notes: notes,
            tag_ids: tagIds
        }, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'X-CSRF-Token': csrfToken,
                'Authorization': `Bearer ${token}`,
                'X-Server': localStorage.getItem('userType')
            }
        });
        return response.data;  // Assuming the response contains { message }
    } catch (error) {
        console.error('Error while updating notes and tags:', error);
        throw error;
    }
};
export const getAllTags = async () => {
    try {
        const response = await axios.get(GET_ALL_TAGS);
        return response.data; 
    } catch (error) {
        console.error('Error while retrieving tags:', error);
        throw error;
    }
};