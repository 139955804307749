
import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Alert, Autocomplete, Button, Chip, Dialog, DialogContent, IconButton, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography, lighten, styled, useMediaQuery } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { ResponseContext } from '../../App';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createHoroscope, deleteMyHoroscope, fetchMySavedHoroscopes, getAllTags } from '../../service/AstroService';
import { useTheme } from "@mui/material/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@mui/icons-material/Search';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import AdsClickRoundedIcon from '@mui/icons-material/AdsClickRounded';
import SkipNextRoundedIcon from '@mui/icons-material/SkipNextRounded';
import SkipPreviousRoundedIcon from '@mui/icons-material/SkipPreviousRounded';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import moment from 'moment';
export const SearchParamsContext = React.createContext();

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    height: 35,
    padding: '4px 8px', // Adjust padding to reduce height
    fontSize: '14px',  // Adjust font size
    width: '290px',
    [theme.breakpoints.down('sm')]: {
      width: '130px',
    }
  },
  '& .MuiFormControl-root': {
    margin: 0, // Remove default margin
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '4px', // Optional: Adjust border radius
  },
}));

const CustomAutocomplete = styled(Autocomplete)(({ theme }) => ({
  // Style the input field inside the Autocomplete
  '& .MuiInputBase-input': {
    height: '10px', // Adjust height for input
    padding: '4px 8px', // Adjust padding for better alignment
    fontSize: '14px', // Font size


  },
  // Style the outlined input container
  '& .MuiOutlinedInput-root': {
    width: '250px',
    [theme.breakpoints.down('sm')]: {
      width: '170px', marginLeft: '4px'
    },
    borderRadius: '4px', // Border radius
    '& fieldset': {
      borderColor: theme.palette.primary,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main, // Border color on hover
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main, // Border color when focused
    },
  },
  // Style the container for the Autocomplete
  '& .MuiAutocomplete-root': {
    width: '300px', // Default width
    [theme.breakpoints.down('sm')]: {
      width: '150px', // Responsive width for small screens
    },
  },
  // Remove margin for the form control if necessary
  '& .MuiFormControl-root': {
    margin: 0,
  },
}));

const CustomTableRow = styled(TableRow)(({ theme }) => ({
  minHeight: '20px',
  '&:nth-of-type(odd)': {
  },
  '&:nth-of-type(even)': {
    backgroundColor: 'rgba(100, 149, 237, 0.1)'
  },
  '&:hover': {
    backgroundColor: '#8fa6f1;'
  },
}));

const CustomTableCell = styled(TableCell)(({ theme }) => ({
  minHeight: '20px',
  padding: '8px',

  height: '20px', borderColor: 'lightblue',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '120px',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '30px',
  },
}));
const CustomTableCellSmall = styled(TableCell)(({ theme }) => ({

  width: '20px'

}));
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  width: '750px',
  maxWidth: '750px',
  margin: 'auto',
  marginTop: 15,
  border: '1px solid rgba(100, 149, 237, 0.3)',
  borderRadius: '8px',
  overflow: 'hidden',
  backgroundColor: '#fff',
  marginBottom: '20px',
  [theme.breakpoints.down('md')]: {
    width: '690px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '370px',
  },

  //   // Media query for medium screens
  //   [theme.breakpoints.between('sm', 'md')]: {
  //     width: '90%', // Adjust width for medium screens
  //     maxWidth: '600px', // Adjust maximum width
  //   },
}));

const StyledTable = styled(Table)(({ theme }) => ({
  width: '100%',
  borderCollapse: 'collapse',
}));
const CustomTableHead = styled(TableHead)({
  backgroundColor: 'rgba(100, 149, 237, 0.4)',
});
const StyledPaginationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: -10,
  padding: theme.spacing(1),
  backgroundColor: 'white',
  borderRadius: '8px',
  boxShadow: theme.shadows[2], maxWidth: '450px'
}));

const StyledPaginationButton = styled(Button)(({ theme }) => ({
  border: '1px solid',
  borderColor: 'lightblue',
  borderRadius: '8px',
  padding: theme.spacing(1),
  minWidth: '40px',
  minHeight: '26px',
  height: '30px',
  fontSize: '14px',
  fontWeight: 600,
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    [theme.breakpoints.up('md')]: {
      backgroundColor: theme.palette.primary.light,
    },
    boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.15)',
  },
  '&:disabled': {
    color: theme.palette.text.disabled,
    backgroundColor: theme.palette.action.disabledBackground,
    boxShadow: 'none',
  },
}));


// Styled pagination info
const StyledPaginationInfo = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  color: theme.palette.text.primary,
}));

// Styled select for rows per page
const StyledSelect = styled('select')(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.divider,
  borderRadius: '4px',
  padding: theme.spacing(1),
  fontSize: '16px',
  backgroundColor: theme.palette.background.paper,
}));
const StyledTablePagination = ({ count, rowsPerPage, page, onPageChange, onRowsPerPageChange, rowsPerPageOptions }) => (
  <StyledPaginationContainer>
    <StyledPaginationButton
      onClick={() => onPageChange(null, 0)}
      disabled={page === 0}
    >

      First
    </StyledPaginationButton>
    <StyledPaginationButton
      onClick={() => onPageChange(null, page - 1)}
      disabled={page === 0}
    >
      <SkipPreviousRoundedIcon />
    </StyledPaginationButton>
    <StyledPaginationButton
      onClick={() => onPageChange(null, page + 1)}
      disabled={page >= Math.ceil(count / rowsPerPage) - 1}
    >
      <SkipNextRoundedIcon />
    </StyledPaginationButton>
    <StyledPaginationButton
      onClick={() => onPageChange(null, Math.max(0, Math.ceil(count / rowsPerPage) - 1))}
      disabled={page >= Math.ceil(count / rowsPerPage) - 1}
    >
      Last
    </StyledPaginationButton>
    <StyledPaginationInfo>
      {`${page + 1} of ${Math.ceil(count / rowsPerPage)}`}
    </StyledPaginationInfo>
    <StyledSelect
      value={rowsPerPage}
      onChange={onRowsPerPageChange}
    >
      {rowsPerPageOptions.map(option => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </StyledSelect>
  </StyledPaginationContainer>
);



const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '270px',
    width: '100v', marginLeft: 200,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 100,
    }
  },
  progress: {
    margin: theme.spacing(2), marginLeft: 200
  },
}));
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(0.5), // Example padding for dialog content

    [theme.breakpoints.down('sm')]: {
      width: "380px", minHeight: '600px'
    },
    [theme.breakpoints.up('sm')]: {
      width: "780px", minHeight: '600px'
    },

  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2), // Example padding for dialog actions

  },
  // Custom width and height styles based on Bootstrap-like grid system
  [theme.breakpoints.down('sm')]: {
    width: '445px', // Full width on small screens
    marginLeft: -27,
  },
  [theme.breakpoints.up('sm')]: {
    maxWidth: '100%', // Maximum width on screens from small (sm) and up

  },
  height: 'auto', // Auto height based on content
  // minHeight:'500px'

}));

export default function OpenChartDialog() {

  const { setResponseData, setRequestData, openChart, setOpenChart, loggedInUser, setLoggedInUser, setOpenLogin, setGotResponse,setLastResponse } = React.useContext(ResponseContext);
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [totalRows, setTotalRows] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const dateFormat = moment();
  const [selectedTags, setSelectedTags] = React.useState();
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [tagsOptions, setTagsOptions] = React.useState([]);

  const handleTagChange = (event, newValue) => {
    // if (newValue.length > MAX_TAGS) {
    //   setErrorMessage(`You can select up to ${MAX_TAGS} tags only.`);
    // } else {
    // setErrorMessage('');
  
    if(newValue)
    setSelectedTags(newValue);
    else {
      setSelectedTags(null);
    }
    // }
  };
  // setOpen(openChart)
  const handleClose = () => {
    setOpenChart(false);
  };
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t, i18n } = useTranslation();

  // const { allData } = React.useContext(DataContext);
  const [data, setData] = React.useState([]);
  const [originalData, setOriginalData] = React.useState([]);


  React.useEffect(() => {
    console.log("OpenChartDialog useEffect render test ")

    setLoading(true);

    if (openChart) {
      getAllTags()
        .then(response => {
          setTagsOptions(response);
        });
      fetchMyHoroscopes(0, 10);
    }

  }, [openChart, setOpenChart])



  const cancelSearch = () => {
    setData(originalData);
  };


  const [name, setName] = React.useState();
  const [searchName, setSearchName] = React.useState();
  const [birthTimeStamp, setBirthTimeStamp] = React.useState(Date.now())
  const [birthLocation, setBirthLocation] = React.useState()
  const [lat, setLat] = React.useState();
  const [lon, setLon] = React.useState();
  const [csrfToken, setCsrfToken] = React.useState();
  const [gender, setGender] = React.useState();
  // const [responseData, setResponseData] = React.useState();

  const navigation = useNavigate()
  const fetchMyHoroscopesOnSearch = () => {
    fetchMyHoroscopes(0, 10)
  }
  const fetchMyHoroscopes = async (page, pageSize) => {
    setLoading(true);
    try {
      // const selectTagId = tagsOptions.indexOf(selectedTags) + 1;
      const selectedTag =selectedTags? selectedTags.id:null
      const response = await fetchMySavedHoroscopes(searchName, selectedTag, page, pageSize);

      const newData = response.data.data.map((row, index) => ({
        displayID: page * pageSize + index + 1,
        id: row.id,
        name: row.name,
        date: row.date,
        time: row.time,
        birthPlaceStr: `${row.birthPlace.place}, ${row.birthPlace.state}, ${row.birthPlace.country}`,
        gender: row.gender,
        lat: row.birthPlace.lat,
        lon: row.birthPlace.lon,
        timestamp: row.timestamp,
        birthPlace: row.birthPlace,
        lastUpdated: moment(row.lastUpdated).format('DD MMM YYYY')
      }));
      setData(newData);
      setOriginalData(newData);
      setTotalRows(response.data.totalCount);
      setCsrfToken(response.data.csrfToken)
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response && (error.response.status === 422 || error.response.status === 401)) {
        setOpenChart(false);
        setOpenLogin(true);
        setLoggedInUser("");
      }
      console.error('Error fetching data:', error);
      // Handle errors as needed
    }
  };


  const handleRowClick = (
    params, // GridCallbackDetails
  ) => {
    fetchHoroscope(params.id)

  };

  const fetchHoroscope = (id) => {
    let myRequestData = {}
    data.forEach((val) => {
      if (val.id === id) {
        myRequestData = {
          id: val.id,
          name: val.name,
          birthTimeStamp: val.timestamp,
          birthPlace: val.birthPlace,
          lat: val.lat,
          lon: val.lon,
          gender: val.gender
        }
      }
    })
    setGotResponse(false)
    setOpenChart(false)
    createHoroscope(false, myRequestData).then((response) => {
      myRequestData.id = response.data.requestId
      setRequestData(myRequestData)
      setResponseData(response)
      // this.props.history.push('/details')
      let pathPrefix = "/english"
      if (i18n.language === 'ta')
        pathPrefix = '/tamil'
      else if (i18n.language === 'te')
        pathPrefix = '/telugu'
      navigation(pathPrefix + "/details")
      setGotResponse(true)
      const timestamp = new Date().toISOString();
      setLastResponse(timestamp)
    }).catch(error => {
      const timestamp = new Date().toISOString();
      setLastResponse(timestamp)
      // navigation(pathPrefix)
      setGotResponse(true)
  })

  }

  const handleDeleteClick = (id, displayID) => () => {

    if (window.confirm(`Do you want to row no :${displayID} ?`)) {
      deleteMyHoroscope(id, csrfToken).then((response) => {
        setData(data.filter((row) => row.id !== id));
        setOpenChart(true)
        console.log(` ID ${id} deleted`);
        setSnackbarMessage(`Row no: ${displayID} was successfully deleted.`);
        setSnackbarOpen(true);
      }).catch((error) => {
        console.log(error)
        setSnackbarMessage(`Error occurred while trying to delete the record. Please try again.`);
        setSnackbarOpen(true);
      })
    }
  };

  const handleFilterChange = (event) => {
    const searchedVal = event.target.value.toLowerCase();
    console.log(searchedVal)
    setSearchName(searchedVal);
    setData(originalData.filter((data) => data.name.toLowerCase().includes(searchedVal)));
  };
  const handlePageChange = (event, newPage) => {
    setPage(newPage);

    fetchMyHoroscopes(newPage, pageSize);
  };


  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setPage(0); // Reset to first page on page size change
    fetchMyHoroscopes(0, newSize);
  };


  return (

    <BootstrapDialog
      onClose={handleClose} maxWidth='1200px'
      aria-labelledby="customized-dialog-title"
      open={openChart}
    >
      {/* <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Modal title
        </DialogTitle> */}
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 7,
          top: 5, color: 'white'
        }}
      >
        <CancelIcon />
      </IconButton>
      <DialogContent className="dialog-content">


        <div>   {openChart}
          <SearchParamsContext.Provider value={{ name, setName, birthTimeStamp, setBirthTimeStamp, birthLocation, setBirthLocation, gender, setGender, setLat, setLon }}>
            <Box >
              <Grid item xs="auto" sx={{ background: '#4B61D1', color: 'white', fontWeight: 'bold', height: 45, textAlign: 'left', padding: 1, fontSize: 18, margin: -0.5 }}>
                {t("Open Horoscope")}
              </Grid>
              <Grid container spacing={7} paddingTop={8} >
                <React.Fragment>
                  <Grid container ml={isSmallScreen ? 3 : 0} >
                    <Grid xs={1}>
                    </Grid>
                    <Grid xs={6}>
                      <Box
                        flexGrow='2'
                        width='100%'
                        display='flex-start'
                        flexDirection='column'
                        alignItems='center'
                        justifyContent='center'>
                        <div style={{ height: 'auto', width: isSmallScreen ? '350px' : '750px' }}>

                          <Grid container>
                            <Grid xs={5}>

                              <CustomTextField placeholder={t('Filter from the result')} value={searchName}
                                // onChange={(searchVal) => requestSearch(searchVal)}
                                onChange={handleFilterChange}
                                onCancelSearch={() => cancelSearch()}
                              />

                            </Grid>
                            {(localStorage.getItem('userType') === 'KRISH' &&
                              <Grid xs={4}>

                                <CustomAutocomplete

                                  options={tagsOptions}
                                  getOptionLabel={(option) => option.name}
                                  value={selectedTags}
                                  onChange={handleTagChange}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      // label="Select Tag" 
                                      variant="outlined"
                                      placeholder="Select a tag"
                                    />
                                  )}
                                  isOptionEqualToValue={(option, value) => option === value}
                                />
                              </Grid>
                            )}
                            {(localStorage.getItem('userType') === 'KRISH' &&
                              <Grid xs={3}>



                                <Button
                                  onClick={fetchMyHoroscopesOnSearch}
                                  variant="contained"
                                  color="primary"
                                  startIcon={<SearchIcon style={{ fontSize: 30 }} />}
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center', height: 40,
                                    padding: {
                                      xs: '8px', // Compact padding for small screens
                                      sm: '8px 16px' // Larger padding for medium screens and up
                                    },
                                    paddingTop: {
                                      xs: '15px',
                                    },
                                    paddingRight: {
                                      xs: '0',
                                    },
                                    marginLeft: {
                                      xs: '65px',
                                    },
                                    fontSize: {
                                      xs: '0.75rem', // Smaller font size for small screens
                                      sm: '1rem' // Larger font size for medium screens and up
                                    },
                                    minWidth: 'auto', // Ensure button width adjusts to content
                                    '& .MuiButton-startIcon': {
                                      display: isSmallScreen ? 'block' : 'none' // Hide text on small screens
                                    },
                                    '& .MuiButton-label': {
                                      display: isSmallScreen ? 'none' : 'block' // Show text on larger screens
                                    },
                                    '&:hover': {
                                      backgroundColor: '#0056b3',
                                    },
                                  }}
                                >
                                  {!isSmallScreen && 'Search'}
                                </Button>
                              </Grid>
                            )}

                          </Grid>


                          <div >

                            <StyledTableContainer component={Paper}>
                              <StyledTable>
                                <CustomTableHead>
                                  <CustomTableRow>
                                    {
                                      isSmallScreen ?
                                        <>
                                          <CustomTableCell className="header">{t('Name')}</CustomTableCell>
                                          <CustomTableCell className="header">{t('Time')}</CustomTableCell>
                                          <CustomTableCell className="header">{t('Place')}</CustomTableCell>
                                          <CustomTableCellSmall className="header" >{t('Act')}</CustomTableCellSmall></> :
                                        <><CustomTableCell className="header">ID</CustomTableCell>
                                          <CustomTableCell className="header">{t('Name')}</CustomTableCell>
                                          <CustomTableCell className="header">{t('Birth date')}</CustomTableCell>
                                          <CustomTableCell className="header">{t('Time')}</CustomTableCell>
                                          <CustomTableCell className="header">{t('Birth place')}</CustomTableCell>
                                          <CustomTableCell className="header">{t('Last modified')}</CustomTableCell>
                                          <CustomTableCell className="header">{t('Actions')}</CustomTableCell></>


                                    }
                                  </CustomTableRow>
                                </CustomTableHead>
                                {loading ? (
                                  <>
                                    <div className={classes.root}>
                                      <CircularProgress />
                                      <Typography variant="h5" >Loading...</Typography>
                                    </div>
                                  </>
                                ) : (


                                  <TableBody>
                                    {data.length > 0 ? (
                                      data.map((item) => (
                                        <CustomTableRow key={item.id} onClick={() => { handleRowClick(item); }} style={{ cursor: 'pointer' }}>
                                          {
                                            isSmallScreen ?
                                              <>
                                                <CustomTableCell>{item.name}</CustomTableCell>
                                                <CustomTableCell >{item.date} {item.time}</CustomTableCell>
                                                <CustomTableCell>{item.birthPlaceStr}</CustomTableCell>
                                                <CustomTableCell style={{ padding: 0 }}><AdsClickRoundedIcon style={{ height: 16 }} color='primary' />
                                                  <DeleteSharpIcon style={{ height: 16 }} onClick={handleDeleteClick(item.id, item.displayID)} color='primary' /></CustomTableCell>
                                              </> :
                                              <> <CustomTableCell>{item.displayID}</CustomTableCell>
                                                <CustomTableCell>{item.name}</CustomTableCell>
                                                <CustomTableCell>{item.date}</CustomTableCell>
                                                <CustomTableCell>{item.time}</CustomTableCell>
                                                <CustomTableCell>{item.birthPlaceStr}</CustomTableCell>
                                                <CustomTableCell>{item.lastUpdated}</CustomTableCell>
                                                <CustomTableCell style={{ padding: 0 }}><AdsClickRoundedIcon style={{ height: 16 }} color='primary' />
                                                  <DeleteSharpIcon style={{ height: 16 }} onClick={(event) => { event.stopPropagation(); handleDeleteClick(item.id, item.displayID)(); }} color='primary' /></CustomTableCell>
                                              </>
                                          }
                                        </CustomTableRow>
                                      ))
                                    ) : (
                                      <TableRow>
                                        <TableCell colSpan={6}>No data available</TableCell>
                                      </TableRow>
                                    )}
                                  </TableBody>

                                )}
                              </StyledTable>
                            </StyledTableContainer>

                            <StyledTablePagination
                              rowsPerPageOptions={[10, 25, 50]}
                              component="div"
                              count={totalRows}
                              rowsPerPage={pageSize}
                              page={page}
                              onPageChange={handlePageChange}
                              onRowsPerPageChange={handlePageSizeChange}
                              sx={{ mt: 2 }}
                            />


                          </div>
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                </React.Fragment>
              </Grid>
            </Box>
          </SearchParamsContext.Provider>
        </div>







      </DialogContent>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => { setSnackbarOpen(false) }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => { setSnackbarOpen(false) }} severity={snackbarMessage.includes('successfully') ? 'success' : 'error'}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

    </BootstrapDialog>




  );
};