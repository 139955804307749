import React, { useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useTheme } from "@emotion/react";
import { lighten, useMediaQuery } from '@mui/material';
// import response from '../resource/sample.json'

export default function Chart({vargaInput,arrayMode}) {
    const { t,i18n } = useTranslation();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [size,setSize] = useState(isSmallScreen?0.90:1);
    const canvasRef = useRef(null);
    const lagnam = {
        tamilShort: "ல",
        tamilFull: "லக்னம்",
        englishShort: "As",
        englishFull: "Ascendant",
        color: "blue"
    }
    const jupiter = {
        tamilShort: "கு",
        tamilFull: "குரு",
        englishShort: "Ju",
        englishFull: "Jupiter",
        color: "#cc00cc"
    }
    const venus = {
        tamilShort: "சு",
        tamilFull: "சுக்ரன்",
        englishShort: "Ve",
        englishFull: "Venus",
        color: "purple"
    }
    const mars = {
        tamilShort: "செ",
        tamilFull: "செவ்வாய்",
        englishShort: "Ma",
        englishFull: "Mars",
        color: "red"
    }
    const mercury = {
        tamilShort: "பு",
        tamilFull: "புதன்",
        englishShort: "Me",
        englishFull: "Mercury",
        color: "darkgreen"
    }
    const saturn = {
        tamilShort: "சனி",
        tamilFull: "சனி",
        englishShort: "Sa",
        englishFull: "Saturn",
        color: "darkblue"
    }
    const moon = {
        tamilShort: "சந்",
        tamilFull: "சந்திரன்",
        englishShort: "Mo",
        englishFull: "Moon",
        color: "#0080ff"
    }
    const sun = {
        tamilShort: "சூ",
        tamilFull: "சூரியன்",
        englishShort: "Su",
        englishFull: "Sun",
        color: "#cc5200"
    }
    const rahu = {
        tamilShort: "ரா",
        tamilFull: "ராகு",
        englishShort: "Ra",
        englishFull: "Rahu",
        color: "#000000"
    }
    const kethu = {
        tamilShort: "கே",
        tamilFull: "கேது",
        englishShort: "Ke",
        englishFull: "Kethu",
        color: lighten("#000000",0.3) 
    }
    const defaultVal = {
       
        color: "blue"
    }
    const raasiPosition = arrayMode?115:100;
    const raasiPositionY = arrayMode?115:100;

   
    function getPlanet(planetName) {
        if (planetName === "Jupiter")
            return jupiter;
        else if (planetName === "Rahu")
            return rahu;
        else if (planetName === "Venus")
            return venus;
        else if (planetName === "Mercury")
            return mercury;
        else if (planetName === "Saturn")
            return saturn;
        else if (planetName === "Mars")
            return mars;
        else if (planetName === "Ketu")
            return kethu;
        else if (planetName === "Sun")
            return sun;
        else if (planetName === "Moon")
            return moon;
        else if (planetName === "Ascendant")
            return lagnam;
        return defaultVal;


    }
    React.useEffect(() => {
       console.log("------- chart useEffect------")
        // alert(i18n.language)
        console.log('navamasha useEffect render ---- testing')
        let planetFontSize="19px Arial"
        if(i18n.language=='en')
            planetFontSize=isSmallScreen?"14px Arial": "17px Arial"
        else if(i18n.language=='ta')
            planetFontSize=isSmallScreen?"16px Arial": "19px Arial"
        // alert("==+++"+JSON.stringify(param.vargaInput))
        // alert(JSON.stringify(result))
        if (vargaInput) {
            let raasi = Array.from({ length: 12 }, () => []); // Creates an array of 12 empty arrays
            console.log("+/"+JSON.stringify(vargaInput))
            // alert(arrayMode)
            if(arrayMode) {
                let i=0;
                vargaInput.forEach((value)=>{
                    raasi[i].push({name:value,})
                    i++;
                });


            } else {
            Object.keys(vargaInput).forEach(function (key,value) {
                        console.log(vargaInput[key])
                        raasi[vargaInput[key]-1].push({
                            name: key,
                        });
                    
                
            });
        }
            console.log("-----"+JSON.stringify(raasi));
            // const canvas = document.getElementById("myCanvas2");
            const canvas = canvasRef.current;
            const ctx = canvas.getContext("2d");
            ctx.clearRect(0, 0, canvas.width, canvas.height)
            ctx.beginPath();
            
            ctx.rect(size * 1, size * 1, size * 100, size * 100);
            ctx.rect(size * 1, size * 101, size * 100, size * 100);
            ctx.rect(size * 1, size * 201, size * 100, size * 100);
            ctx.rect(size * 1, size * 301, size * 100, size * 100);

            ctx.rect(size * 1, size * 1, size * 200, size * 100);
            ctx.rect(size * 1, size * 1, size * 300, size * 100);
            ctx.rect(size * 1, size * 1, size * 400, size * 100);

            ctx.rect(size * 301, size * 101, size * 100, size * 100);
            ctx.rect(size * 301, size * 201, size * 100, size * 100);
            ctx.rect(size * 301, size * 301, size * 100, size * 100);

            ctx.rect(size * 101, size * 301, size * 100, size * 100); //viruchagam
            ctx.rect(size * 201, size * 301, size * 100, size * 100); //thulam

            ctx.strokeStyle = '#4B61D1';
            ctx.fillStyle = "blue"
           

            const drawRaasi = (raasiArray, positionX, positionY) => {
                let nextPosition = 0;
                let nextPositionY = 0;
                let inc = 1;

                raasiArray.forEach((val) => {
                    let planet = getPlanet(val.name);

                    if(arrayMode) {
                        let myColor='darkgreen';
                        if(val.name>=10 && val.name<=28)
                            myColor='red'
                        else if(val.name>=10 && val.name<=30)
                            myColor='blue'
                        else if(val.name<=3)
                            myColor='red'
                        else if(val.name<=4)
                            myColor='blue'
                        ctx.fillStyle = myColor;
                    } else 
                    ctx.fillStyle = getPlanet(val.name).color;
                    
                // ctx.fillStyle = getPlanet(val.name).color;
                ctx.font = arrayMode? "bold 25px Arial":planetFontSize;
                // let printVal = val.name
                ctx.fillText(planet.englishShort?t(planet.englishShort):val.name, size * (5 + nextPosition + raasiPosition * positionX), size * (27 + nextPositionY + raasiPositionY * positionY));

                if (inc % 2 == 1) {
                    nextPosition = 50;
                } else {
                    nextPosition = 0;
                    nextPositionY = nextPositionY + (raasiArray.length > 6 ? 20 : 30);
                }
                inc++;
                });
            };
            drawRaasi(raasi[0], 1, 0);
            drawRaasi(raasi[1], 2, 0);
            drawRaasi(raasi[2], 3, 0);
            drawRaasi(raasi[3], 3, 1);
            drawRaasi(raasi[4], 3, 2);
            drawRaasi(raasi[5], 3, 3);
            drawRaasi(raasi[6], 2, 3);
            drawRaasi(raasi[7], 1, 3);
            drawRaasi(raasi[8], 0, 3);
            drawRaasi(raasi[9], 0, 2);
            drawRaasi(raasi[10], 0, 1);
            drawRaasi(raasi[11], 0, 0);
            ctx.stroke();
            ctx.stroke();
        }

    }, [i18n.language,vargaInput]);
    // ⏹️ வர்கோத்தமம்
    return (
        <div style={{marginTop:isSmallScreen?-20:0}}>
           
            <canvas
                ref={canvasRef}
                width={405*size}
                height={405*size}
                style={{  border: "1px solid white", background: "white",margin:isSmallScreen?1:10  }}
            />
            
        </div>
    );
}