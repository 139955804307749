import React from 'react';
import { useTranslation } from 'react-i18next';

const StatusIndicators = () => {
    const { t } = useTranslation();
  const statusStyles = {
    good: {
      color: 'green',
      shape: 'circle',
      text: 'Good',
      containerStyle: { 
        marginBottom: '10px', 
        display: 'flex', 
        alignItems: 'center' 
      },
      shapeStyle: { 
        width: '20px', 
        height: '20px', 
        backgroundColor: 'green', 
        borderRadius: '50%', 
        marginRight: '8px' 
      },
      textStyle: { color: 'green', fontSize: '16px' },
    },
    bad: {
      color: 'red',
      shape: 'circle',
      text: 'Bad',
      containerStyle: { 
        marginBottom: '10px', 
        display: 'flex', 
        alignItems: 'center' 
      },
      shapeStyle: { 
        width: '20px', 
        height: '20px', 
        backgroundColor: 'red', 
        borderRadius: '50%', 
        marginRight: '8px' 
      },
      textStyle: { color: 'red', fontSize: '16px' },
    },
    average: {
      color: 'blue',
      shape: 'circle',
      text: 'Average',
      containerStyle: { 
        marginBottom: '10px', 
        display: 'flex', 
        alignItems: 'center' 
      },
      shapeStyle: { 
        width: '20px', 
        height: '20px', 
        backgroundColor: 'blue', 
        borderRadius: '50%', 
        marginRight: '8px' 
      },
      textStyle: { color: 'blue', fontSize: '16px' },
    },
  };

  const containerStyle = {
    maxWidth: '250px',
    padding: '15px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#f9f9f9',
    margin: '0 auto' // Centers the container horizontally
  };

  return (
    <div style={containerStyle}>
      <h2 style={{ marginBottom: '20px', color: '#333', textAlign: 'center' }}>{t('Indicators')}</h2>
      {Object.keys(statusStyles).map((key) => (
        <div key={key} style={statusStyles[key].containerStyle}>
          <div style={statusStyles[key].shapeStyle}></div>
          <span style={statusStyles[key].textStyle}>
            {t(statusStyles[key].text)}
          </span>
        </div>
      ))}
    </div>
  );
};

export default StatusIndicators;
