import React, { useState, useEffect, useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { ResponseContext } from '../../../App';
import { updateUserSettings } from '../../../service/UserService';
import { Alert, Backdrop, CircularProgress, Snackbar } from '@mui/material';

// Custom styling
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: '#f5f5f5',
    borderRadius: '8px',
    boxShadow: `0px 4px 20px rgba(0, 0, 0, 0.1)`,
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: '#4B61D1',  // Main blue color
  color: '#fff',
  fontWeight: 'bold',
  padding: theme.spacing(1),
  textAlign: 'left',
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3), // Increased gap for better spacing
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  paddingBottom: theme.spacing(1),
  marginBottom: theme.spacing(1),
  fontSize: 17,
  fontWeight: 'bold',
}));

const SectionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const RadioGroupStyled = styled(RadioGroup)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2), // Increased gap for better spacing
}));

const CheckboxContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  '& > *': {
    flexBasis: '45%',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: '#fff',
  backgroundColor: '#4B61D1',
  '&:hover': {
    backgroundColor: '#3a4da1',
  },
}));

const SettingsDialog = () => {
  const { setChartSettings, openChartSettings, setOpenChartSettings, setLoggedInUser, setOpenLogin,setIsDateManual } = useContext(ResponseContext);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('Updating Settings...');
  const [settings, setSettings] = useState({
    showCombust: false,
    showRetrograde: false,
    showVarkotham: false,
    showDegree: false,
    chartType: 'Automatic',
    dateComponent: 'Dropdown'
  });
  const { t } = useTranslation();

  useEffect(() => {
    const storedSettings = localStorage.getItem('chartSettings');
    if (storedSettings) {
      setSettings(JSON.parse(storedSettings));
    }
  }, [openChartSettings]);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setSettings(prevSettings => ({
      ...prevSettings,
      [name]: checked,
    }));
  };

  const handleChartTypeRadioChange = (event) => {
    setSettings(prevSettings => ({
      ...prevSettings,
      chartType: event.target.value,
    }));
  };

  const handleDateComponentRadioChange = (event) => {
    setSettings(prevSettings => ({
      ...prevSettings,
      dateComponent: event.target.value,
    }));
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSave = () => {
    setLoading(true)
    localStorage.setItem('chartSettings', JSON.stringify(settings));
    setChartSettings(settings);
    updateUserSettings(settings)
      .then(() => {
        // alert('Settings updated successfully'); 
        setSnackbarMessage("Settings updated successfully")
        setIsDateManual(settings.dateComponent === 'Dropdown' ? false : true)
        setLoading(false)
        setSnackbarOpen(true);
      }
    ).catch((error) => {
      setLoading(false)
        if (error.response && (error.response.status === 422 || error.response.status === 401)) {
          setOpenChartSettings(false);
          setOpenLogin(true);
          setLoggedInUser("");
          alert("Your session has expired. Please log in again to continue.")
        } else {
          const errorMessage = error.response?.data?.error || 'An unexpected error occurred.';
          setSnackbarMessage(errorMessage); 
          setSnackbarOpen(true);
        }
        
      });

    // setOpenChartSettings(false);
  };

  return (
    <StyledDialog open={openChartSettings} onClose={() => setOpenChartSettings(false)}>
      <StyledDialogTitle>{t("Settings")}</StyledDialogTitle>
      <StyledDialogContent>
        <SectionContainer>
          <SectionTitle variant="h6">{t("Chart Display Settings")}</SectionTitle>
          <CheckboxContainer>
            <FormControlLabel
              control={
                <Checkbox
                  checked={settings.showCombust}
                  onChange={handleCheckboxChange}
                  name="showCombust"
                  color="primary"
                />
              }
              label={t("Display Combust")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={settings.showRetrograde}
                  onChange={handleCheckboxChange}
                  name="showRetrograde"
                  color="primary"
                />
              }
              label={t("Display Retrograde")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={settings.showVarkotham}
                  onChange={handleCheckboxChange}
                  name="showVarkotham"
                  color="primary"
                />
              }
              label={t("Display Varkotham")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={settings.showDegree}
                  onChange={handleCheckboxChange}
                  name="showDegree"
                  color="primary"
                />
              }
              label={t("Display Degree")}
            />
          </CheckboxContainer>
        </SectionContainer>
        <SectionContainer>
          <SectionTitle variant="h6">{t("Default Chart Type")}</SectionTitle>
          <FormControl component="fieldset">
            <RadioGroupStyled
              aria-label={t("Chart type")}
              name="chartType"
              value={settings.chartType}
              onChange={handleChartTypeRadioChange}
            >
              <FormControlLabel value="Automatic" control={<Radio />} label={t("Automatic")} />
              <FormControlLabel value="North" control={<Radio />} label={t("North")} />
              <FormControlLabel value="South" control={<Radio />} label={t("South")} />
              <FormControlLabel value="East" control={<Radio />} label={t("East")} />
            </RadioGroupStyled>
          </FormControl>
        </SectionContainer>
        <SectionContainer>
          <SectionTitle variant="h6">{t("Default Date Components")}</SectionTitle>
          <FormControl component="fieldset">
            <RadioGroupStyled
              aria-label={t("Date components")}
              name="dateComponent"
              value={settings.dateComponent}
              onChange={handleDateComponentRadioChange}
            >
              <FormControlLabel value="Dropdown" control={<Radio />} label={t("Dropdown Menu")} />
              <FormControlLabel value="TextField" control={<Radio />} label={t("TextField Entry")} />
            </RadioGroupStyled>
          </FormControl>
        </SectionContainer>
      </StyledDialogContent>
      <DialogActions>
        <StyledButton onClick={() => setOpenChartSettings(false)}>
          {("Close")}
        </StyledButton>
        <StyledButton onClick={handleSave}>
          {("Save")}
        </StyledButton>
      </DialogActions>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
        <div style={{ margin: 10, fontWeight: 'bold', fontSize: 19 }}>{loadingMessage}</div>
      </Backdrop>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarMessage.includes('successfully') ? 'success' : 'error'}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </StyledDialog>
  );
};

export default SettingsDialog;
