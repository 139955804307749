import React, { useContext, useState } from 'react';
import { Box, Button, TextField, Typography, Alert, CircularProgress, Paper, useTheme, InputAdornment, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { userPasswordReset } from '../service/UserService';
import { ResponseContext } from '../App';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const ResetPassword = () => {
    const theme = useTheme();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const {gotResponse,setGotResponse,setOpenLogin} = useContext(ResponseContext);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);
    const verificationId = params.get('verification_id');
    const token = params.get('token');
    const [showPassword, setShowPassword] = useState(false);
    const handlePasswordChange = (e) => setPassword(e.target.value);
    const handleConfirmPasswordChange = (e) => setConfirmPassword(e.target.value);

    const [timeoutId, setTimeoutId] = useState(null);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        const id = setTimeout(() => {
            setShowPassword(false);
        }, 3000); // Auto-hide after 3 seconds
        setTimeoutId(id);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        setGotResponse(false);
   
            userPasswordReset(verificationId,token,password).then((response)=>{
                setSuccess(response.message);
                setError('');
                setGotResponse(true)
                setTimeout(() => {
                    navigate('/');
                    setOpenLogin(true);
                }, 3000); 
            }).catch((error)=>{
                setError(error);
                setGotResponse(true)
            })
          
         
    };

    return (
        <Box 
            sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', marginTop:-7,
                height: '100vh', 
                bgcolor: theme.palette.background.paper, // Lighter background color
                background: `linear-gradient(135deg, ${theme.palette.grey[100]}, ${theme.palette.grey[200]})`,
                padding: 2
            }}
        >
            <Paper 
                elevation={12} 
                sx={{ 
                    p: 4, 
                    maxWidth: 450, 
                    width: '100%', 
                    borderRadius: 4, 
                    textAlign: 'center',
                    boxShadow: `0px 10px 30px ${theme.palette.grey[400]}`,
                    background: 'white' // Clean, white background for the paper component
                }}
            >
                <Typography 
                    variant="h4" 
                    gutterBottom 
                    color="primary" 
                    sx={{ fontWeight: 700 }}
                >
                    Reset Your Password
                </Typography>
                {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
                {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}
                <Box component="form" onSubmit={handleSubmit} noValidate>
                    <TextField
                        label="New Password"
                        type={showPassword ? 'text' : 'password'}
                        fullWidth
                        required
                        margin="normal"
                        variant="outlined"
                        color="primary"
                        value={password}
                        onChange={handlePasswordChange}
                        sx={{ mb: 2, borderRadius: 2 }} 
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        label="Confirm Password"
                        type="password"
                        fullWidth
                        required
                        margin="normal"
                        variant="outlined"
                        color="primary"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        sx={{ mb: 2, borderRadius: 2 }}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ py: 1.5, fontWeight: 'bold', mt: 2, borderRadius: 2 }}
                    >
                        {!gotResponse ? <CircularProgress size={24} color="inherit" /> : 'Reset Password'}
                    </Button>
                </Box>
              
            </Paper>
        </Box>
    );
};

export default ResetPassword;
