import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Alert, Avatar, Backdrop, Box, Button, CircularProgress, TextField, Typography, Tabs, Tab, Link, InputAdornment } from '@mui/material';
import { ResponseContext } from '../App';
import { userLogin, userSignup } from '../service/UserService';
import { useMediaQuery, useTheme } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(1), width:"420px",
      [theme.breakpoints.down('sm')]: {
        width: '380px',
      }
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(2)
    },
    [theme.breakpoints.down('sm')]: {
      width: '445px',
      marginLeft:-27
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '100%',
    },
    height: 'auto',
}));
const StyledLink = styled(Link)(({ theme }) => ({
    fontSize: '16px',
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontWeight: 600,
    transition: 'color 0.3s, text-decoration 0.3s',
    cursor: 'pointer',
    '&:hover': {
        color: theme.palette.primary.dark,
        textDecoration: 'underline',
        fontWeight: 700,
    },
    '&:focus': {
        outline: 'none',
    },
    display: 'inline-flex',
    alignItems: 'center',
    gap: '4px',
}));
const StyledTabs = styled(Tabs)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(2),
    '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.primary.main,
        height: 4,
        borderRadius: 2,
        transition: 'width 0.3s ease',
    },
    '& .MuiTab-root': {
        borderRadius: 20,
        padding: theme.spacing(1, 3),
        transition: 'color 0.3s ease, background-color 0.3s ease',
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
    minWidth: 120,
    textTransform: 'none',
    fontWeight: 600,
    fontSize: 16,
    color: theme.palette.text.primary,
    '&.Mui-selected': {
        color: theme.palette.primary.main,
        fontWeight: 700,
    },
}));

export default function LoginSignupDialog() {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const navigation = useNavigate();
    const location = useLocation();
    const [showPassword, setShowPassword] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [gotResponse, setGotResponse] = useState(true);
    const [formData, setFormData] = useState({ name: '', email: '', password: '' });
    const [tabValue, setTabValue] = useState(0);
    const [errorMessage, setErrorMessage] = useState(0);
    const [errors, setErrors] = useState({
        usernameError: false,
        nameError: false,
        passwordError: false,
        userAlreadyExistError: false,
        invalidCredentials: false,
    });

    const { loggedInUser, setLoggedInUser, openLogin, setOpenLogin, openSignup, setOpenSignup, setOpenForgotPassword } = React.useContext(ResponseContext);
    const [timeoutId, setTimeoutId] = useState(null);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        const id = setTimeout(() => {
            setShowPassword(false);
        }, 3000); // Auto-hide after 3 seconds
        setTimeoutId(id);
    };
    const handleClose = () => {
        setOpenSignup(false);
        setOpenLogin(false);
    };

    const resetErrors = () => {
        setErrors({
            usernameError: false,
            nameError: false,
            passwordError: false,
            userAlreadyExistError: false,
            invalidCredentials: false,
        });
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        resetErrors();
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
        resetErrors();
    };

    const validateSignup = () => {
        let valid = true;
        if (!formData.name) {
            setErrors(prev => ({ ...prev, nameError: true }));
            valid = false;
        }
        if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
            setErrors(prev => ({ ...prev, usernameError: true }));
            valid = false;
        }
        if (!formData.password) {
            setErrors(prev => ({ ...prev, passwordError: true }));
            valid = false;
        }
        return valid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (tabValue === 1 && !validateSignup()) {
            return; // Prevent submission if validation fails
        }
        
        setLoadingMessage(tabValue === 0 ? "Signing In..." : "Signing Up...");
        setGotResponse(false);

        if (tabValue === 0) {
            userLogin(formData)
                .then(username => {
                    setLoggedInUser(username);
                    setOpenLogin(false);
                    setOpenSignup(false);
                    if(location.pathname.includes("verify-email"))
                    navigation("/")
                })
                .catch(e => {
                    setErrors(prev => ({ ...prev, invalidCredentials: true }));
                    setErrorMessage(e)
                })
                .finally(() => setGotResponse(true));
        } else {
            userSignup(formData)
                .then(username => {
                    setLoggedInUser(username);
                    setOpenLogin(false);
                    setOpenSignup(false);
                })
                .catch(e => {
                   setErrors(prev => ({ ...prev, userAlreadyExistError: true }));
                   setErrorMessage(e)
                })
                .finally(() => setGotResponse(true));
        }
    };
    const openForgotPasswordDialog =() =>{
        setOpenLogin(false);
        setOpenSignup(false);
        setOpenForgotPassword(true);
    }

    useEffect(()=> {
        setTabValue(openSignup?1:0)
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    },[openSignup])

    return (
        <React.Fragment>
            <BootstrapDialog
                fullScreen={false}
                onClose={handleClose}
                aria-labelledby="auth-dialog-title"
                open={openLogin || openSignup}
            >
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={!gotResponse}
                >
                    <CircularProgress color="inherit" />
                    <div style={{ margin: 10, fontWeight: 'bold', fontSize: 19 }}>{loadingMessage}</div>
                </Backdrop>

                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{ position: 'absolute', right: 7, top: 5, color: "darkblue" }}
                >
                    <CancelIcon />
                </IconButton>

                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 3 }}>
                        <Avatar sx={{ m: 1, bgcolor: "#191970" }}>
                            <LockOutlinedIcon />
                        </Avatar>

                        <StyledTabs
                            value={tabValue}
                            onChange={handleTabChange}
                            aria-label="auth-tabs"
                        >
                            <StyledTab label="Sign In" />
                            <StyledTab label="Sign Up" />
                        </StyledTabs>

                        <Typography component="h1" variant="h5" color='darkblue' sx={{ mb: 2 }}>
                            <Alert sx={{ display: errors.invalidCredentials ? 'display' : 'none', width: 300 }} severity="error">
                            {errorMessage}
                            </Alert>
                            <Alert sx={{ display: errors.userAlreadyExistError ? 'display' : 'none', width: 330 }} severity="error">
                            {errorMessage}
                            </Alert>
                        </Typography>

                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ width: '100%' }}>
                            {tabValue === 1 && (
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    autoComplete="name"
                                    error={errors.nameError}
                                    helperText={errors.nameError ? "Please enter your name" : ""}
                                />
                            )}

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                autoComplete="email"
                                error={errors.usernameError}
                                helperText={errors.usernameError ? "Please enter a valid email" : ""}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="email icon"
                                                edge="end"
                                            >
                                                <AlternateEmailIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                           
                        <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    value={formData.password}
                                    onChange={handleInputChange}
                                    autoComplete="current-password"
                                    error={errors.passwordError}
                                    helperText={errors.passwordError ? "Please enter your password" : ""}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                              <Typography variant="body2" color="textSecondary">
                                <StyledLink onClick={openForgotPasswordDialog}>
                                    Forgot your password?
                                </StyledLink>
                            </Typography>

                            <Button
                                fullWidth
                                type="submit"
                                color='primary'
                                variant="contained"
                                sx={{ mt: 3, mb: 2, fontWeight: "bold" }}
                            >
                                {tabValue === 0 ? 'Sign In' : 'Sign Up'}
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
            </BootstrapDialog>
        </React.Fragment>
    );
}
