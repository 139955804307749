import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import { Avatar, Box, Button, Radio, RadioGroup, FormControlLabel, TextField, Typography, useMediaQuery, useTheme, FormControl, Backdrop, CircularProgress, Snackbar, Alert } from '@mui/material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { ResponseContext } from '../App';
import { getProfile, updateProfile } from '../service/UserService';

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxHeight: '120vh',
      margin: 'auto',
    },
    [theme.breakpoints.up('sm')]: {
      width: '450px',
    },
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(-3),
    width: 440,
  },
  borderRadius: '12px',
  boxShadow: theme.shadows[10],
}));

const DialogBox = styled(Box)(({ theme, isLoading }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(4),
  borderRadius: '12px',
  backgroundColor: 'white',
  boxShadow: theme.shadows[8],
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  height: '100%',
  boxSizing: 'border-box',
  maxHeight: '100%',
  overflowY: 'auto',
  opacity: isLoading ? 0.3 : 1,
  visibility: isLoading ? 'hidden' : 'visible',
  pointerEvents: isLoading ? 'none' : 'auto',
  transition: 'opacity 0.3s ease, visibility 0.3s ease',
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: theme.palette.text.primary,
  fontWeight: 600,
}));

const RadioGroupInline = styled(RadioGroup)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));
var test="t";
const EditProfile = () => {
  
  const theme = useTheme();
  const { loggedInUser,setLoggedInUser, openEditProfile, setOpenEditProfile,setOpenLogin } = React.useContext(ResponseContext);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState(true);
  // const [csrfToken, setCsrfToken] = useState();
  const [loadingMessage, setLoadingMessage] = useState('Fetching Profile...');
  const [name, setName] = useState('');
  const [originalName, setOriginalName] = useState('');
  const [email, setEmail] = useState('');
  const [originalEmail, setOriginalEmail] = useState('');
  const [password, setPassword] = useState('*****');
  const [isAstrologer, setIsAstrologer] = useState(false);
  const [gender, setGender] = useState('');
  const [originalisAstrologer, setOriginalIsAstrologer] = useState(false);
  const [originalGender, setOriginalGender] = useState('');
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    password: false,
    gender: false,
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [isEditingPassword, setIsEditingPassword] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);


  let csrfToken = '';
  useEffect(() => {
    if (openEditProfile) {
      setIsEditingEmail(false);
      setIsEditingPassword(false);
      setPassword('*****')
      setLoading(true);
      setLoadingMessage("Fetching Profile...");
      getProfile().then((response) => {
        setLoading(false);
        const { name, email, isAstrologer, gender } = response.data; // Adjust based on actual response structure
        setName(name || '');
        setOriginalName(name || '');
        setEmail(email || '');
        setOriginalEmail(email || '');
        setIsAstrologer(isAstrologer || false);
        setGender(gender || 'Male');
        setOriginalIsAstrologer(isAstrologer || false);
        setOriginalGender(gender || 'Male');
        // alert("response.data.csrfToken="+response.data.csrfToken)
        // setCsrfToken(response.data.csrfToken);
        
      }).catch((error) => {
        setLoading(false);
        setSnackbarOpen(true);
        if (error.response && (error.response.status === 422 || error.response.status === 401)) {
          alert("Your session has expired. Please log in again to continue.")
          setOpenEditProfile(false)
          setLoggedInUser("");
          setOpenLogin(true)
        } else {

      const errorMessage = error.response?.data?.error || 'An unexpected error occurred.';
      setSnackbarMessage(errorMessage);
        }
       
          
        // Handle error
      });
    }
  }, [openEditProfile]);

  const validateFields = () => {
    const newErrors = {
      name: name.trim() === '',
      email: email.trim() === '' || !/\S+@\S+\.\S+/.test(email),
      password: password.trim() === '',
      gender: gender.trim() === '',
    };
    setErrors(newErrors);
    return !Object.values(newErrors).includes(true);
  };
 
  
  const saveProfile = () => {
    if (!validateFields()) {
      return;
    }
   
    const fieldsChanged = {
      name: name !== originalName,
      email: email !== originalEmail,
      password: password !== '*****',
      gender: gender !== originalGender,
      isAstrologer: isAstrologer !== originalisAstrologer
     
    };
    if (!Object.values(fieldsChanged).includes(true)) {
      setSnackbarMessage('No changes detected. Please modify the fields to update.');
      setSnackbarOpen(true);
      return;
    }
    if (password !== "*****") {
      let changePassword = window.confirm("Are you sure you want to update your password?");
      if (!changePassword) return;
    }
    if (email !== originalEmail) {
      let changeEmail = window.confirm("Are you sure you want to change your email?");
      if (!changeEmail) return;
    }

    const formData = {
      name,
      email,
      ...(password !== '*****' && { password }),  // Include password if it's not '*****'
      isAstrologer: isAstrologer,
      gender,
    };
    setLoading(true);
    setLoadingMessage("Updating Profile...");
    updateProfile(formData).then(() => {
      setLoading(false);
      localStorage.setItem("username",formData.name)
      setLoggedInUser(formData.name)
      setSnackbarMessage('Profile updated successfully');
      setSnackbarOpen(true);
      setOriginalEmail(formData.email);
      setOriginalGender(formData.gender);
      setOriginalIsAstrologer(formData.isAstrologer);
      setOriginalName(formData.name);
      setHasChanges(false);
      // alert(JSON.stringify(formData))
      // alert(name+""+email+""+gender+""+isAstrologer)
      // Optionally close the dialog after saving
    }).catch((error) => {

      setLoading(false);
      setSnackbarOpen(true);
      if (error.response && (error.response.status === 422 || error.response.status === 401)) {
        alert("Your session has expired. Please log in again to continue.")
        setOpenEditProfile(false)
        setLoggedInUser("");
        setOpenLogin(true)
      } else {

    const errorMessage = error.response?.data?.error || 'An unexpected error occurred.';
    setSnackbarMessage(errorMessage);
      }

    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'name') setName(value);
    if (name === 'email') setEmail(value);
    if (name === 'password') setPassword(value);
   
    
    validateFields();
  };

  const handleIsAstrologerChange = (e) => {
    setIsAstrologer(e.target.value);
  };

  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };

  const handleClose = () => {
    setOpenEditProfile(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    saveProfile();
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleEditEmail = () => {
    setIsEditingEmail(true);
  };

  const handleCancelEmail = () => {
    setIsEditingEmail(false);
    // Optionally reset email value if needed
  };

  const handleEditPassword = () => {
    setIsEditingPassword(true);
    setPassword('')
  };

  const handleCancelPassword = () => {
    setIsEditingPassword(false);
    setPassword('*****')
    // Optionally reset password value if needed
  };

  return (
    <CustomDialog
      onClose={handleClose}
      aria-labelledby="edit-profile-dialog-title"
      open={openEditProfile}
      fullScreen={false}
    >
      <DialogContent>
        <DialogBox isLoading={loading && loadingMessage.includes("Fetching")}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', top: 10, right: 10, color: theme.palette.text.primary, zIndex: 1 }}
          >
            <CancelIcon />
          </IconButton>
          <Avatar sx={{ m: 1, bgcolor: theme.palette.primary.main, zIndex: 1 }}>
            <PersonOutlineIcon />
          </Avatar>
          <Title component="h1" variant="h5" style={{ color: 'darkblue' }}>
            Edit Profile
          </Title>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ width: '100%', zIndex: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              value={name}
              onChange={handleInputChange}
              error={errors.name}
              helperText={errors.name ? 'Name is required' : ''}
            />

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {isEditingEmail ? (
                <>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    value={email}
                    onChange={handleInputChange}
                    error={errors.email}
                    helperText={errors.email ? 'Valid email is required' : ''}
                  />
                  <IconButton onClick={handleCancelEmail}>
                    <CancelIcon />
                  </IconButton>
                </>
              ) : (
                <>
                  <Typography variant="body1" sx={{ flexGrow: 1 }}>
                    {email}
                  </Typography>
                  <IconButton onClick={handleEditEmail}>
                    <EditIcon />
                  </IconButton>
                </>
              )}
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {isEditingPassword ? (
                <>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="password"
                    label="New Password"
                    name="password"
                    type="password"
                    value={password}
                    onChange={handleInputChange}
                    error={errors.password}
                    helperText={errors.password ? 'Password is required' : ''}
                  />
                  <IconButton onClick={handleCancelPassword}>
                    <CancelIcon />
                  </IconButton>
                </>
              ) : (
                <>
                  <Typography variant="body1" sx={{ flexGrow: 1 }}>
                    {password}
                  </Typography>
                  <IconButton onClick={handleEditPassword}>
                    <EditIcon />
                  </IconButton>
                </>
              )}
            </Box>

            <FormControl fullWidth>
              <Typography variant="subtitle1">Are you an astrologer</Typography>
              <RadioGroupInline
                aria-label="isAstrologer"
                name="isAstrologer"
                value={isAstrologer}
                onChange={handleIsAstrologerChange}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroupInline>
            </FormControl>

            <FormControl fullWidth>
              <Typography variant="subtitle1">Gender</Typography>
              <RadioGroupInline
                aria-label="gender"
                name="gender"
                value={gender}
                onChange={handleGenderChange}
              >
                <FormControlLabel value="Male" control={<Radio />} label="Male" />
                <FormControlLabel value="Female" control={<Radio />} label="Female" />
              </RadioGroupInline>
            </FormControl>

            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              sx={{ borderRadius: '8px', boxShadow: theme.shadows[2] }}
            >
              Save Changes
            </Button>
          </Box>
        </DialogBox>
      </DialogContent>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
        <div style={{ margin: 10, fontWeight: 'bold', fontSize: 19 }}>{loadingMessage}</div>
      </Backdrop>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarMessage.includes('successfully') ? 'success' : 'error'}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </CustomDialog>
  );
};

export default EditProfile;
