import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';

import { useTranslation } from 'react-i18next';
import { ResponseContext } from '../../../App';
import { styled } from '@mui/material/styles'
import { useLocation } from 'react-router-dom';
import { fetchTodayPanchang } from '../../../service/AstroService';
import moment from 'moment';
import { Grid, useMediaQuery,useTheme } from '@mui/material';
import '../../css/MyTableDesign.css'

export default function PanchangDetails() {
  const { t, i18n } = useTranslation();
  const { responseData,panchangData } = React.useContext(ResponseContext);
  const location = useLocation()
  const [showTable,setShowTable] = React.useState()
  const [sunRaise, setSunRaise] = React.useState()
  const [place, setPlace] = React.useState()
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const columns = [
    // { field: 'id', headerName: 'No', width: 10, headerClassName: 'super-app-theme--header'},
    { field: 'NAME', headerName: t("Panchang")+" : ", width: 190, headerClassName: 'super-app-theme--header',  },
    { field: 'VALUE', headerName: '', width: 190, headerClassName: 'super-app-theme--header' }
  ];
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    console.log("panchangDetails useEffect render------ ")
      setPlace('Delhi')
    let panchang ={}
    if(location.pathname.includes("details")) {
    let result = responseData != "" ? responseData.data.output : JSON.parse(localStorage.getItem('responseData'))
    panchang = result["panchang"];
    setShowTable(true)
    }
    else {
      // alert("====="+i18n.language)
      
      panchang =  JSON.parse(localStorage.getItem('panchangData'))
      setShowTable(false)
    }
    
    let newData = [];
    //  console.log("====="+JSON.stringify(result))
    if (panchang) {
    let i = 0
    newData.push({
      id: 1,
      NAME: t("Paksha"),
      VALUE: t(panchang.Paksha),
    });
    newData.push({
      id: 2,
      NAME: t("Tithi"),
      VALUE: t(panchang.Tithi),
    });
    newData.push({
      id: 3,
      NAME: t("Nakshatra"),
      VALUE: t(panchang.Nakshatra),
    });
    newData.push({
      id: 4,
      NAME: t("Day"),
      VALUE: t(panchang.Day),
    });
    newData.push({
      id: 5,
      NAME: t("Yoga"),
      VALUE: t(panchang.Yoga),
    });
    newData.push({
      id: 6,
      NAME: t("Sun Raise"),
      VALUE: panchang.SunRaise,
    });
    newData.push({
      id: 7,
      NAME: t("Sun Set"),
      VALUE: panchang.SunSet,
    });
    setSunRaise(panchang.SunRaise)
    setData(newData)
  }
  }, [responseData, i18n.language,panchangData])
  return (
    <div >
    
      <Grid sx={{ height: 425, width: 382,marginTop:1,display:showTable? "display": "none", marginLeft:isSmallScreen?0:5 }} >
      <div className="table-container">
        <table className="table">
          <thead className="thead">
            <tr>
              {columns.map((col) => (
                <th className="th" key={col.field}>{col.headerName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row) => (
              <tr className="tr" key={row.id}>
                <td className="td">{row.NAME}</td>
                <td className="td">{row.VALUE}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      </Grid>
      <div style={{  flexDirection: 'column', width: '370px',display:showTable? "none": "display",paddingTop:20  }}>
      <div style={{font:"bold 15px Arial",textAlign:"left",marginLeft:30,marginBottom:10}}>{moment().format('DD-MMM-YYYY')} {sunRaise} AM, {t(place)}</div>
      {data.map((item, index) => (
        <div 
          key={index}
          style={{ display: 'flex', justifyContent: 'space-between', padding: '8px',fontSize:17 }}
        >
          <div style={{flex:1,textAlign:'right',paddingRight:10}}>{item.NAME}</div>   :   
          <div style={{flex: 1,textAlign:'left',paddingLeft:10}}>{item.VALUE}</div>
        </div>
      ))}
    </div>
    </div>
  );
}