// src/pages/Disclaimer.js
import React from 'react';
import styles from './Legal.module.css'; // Import CSS Module

const Disclaimer = () => {
  return (
    <div className={styles.page}>
      <h1 className={styles.h1}>Disclaimer</h1>
      <h2 className={styles.h2}>1. No Professional Advice</h2>
      <p className={styles.p}>The content provided on [Your Website Name] is for informational purposes only and should not be considered as professional or medical advice. Always seek the advice of a qualified professional with any questions you may have regarding personal or health-related matters.</p>
      <h2 className={styles.h2}>2. Accuracy of Information</h2>
      <p className={styles.p}>We strive to ensure that the information on our website is accurate and up-to-date, but we make no warranties or representations about the completeness or accuracy of the information provided.</p>
      <h2 className={styles.h2}>3. Limitation of Liability</h2>
      <p className={styles.p}>[Your Website Name] and its affiliates are not liable for any direct, indirect, incidental, or consequential damages arising from your use of or inability to use our website or services.</p>
      <h2 className={styles.h2}>4. External Links</h2>
      <p className={styles.p}>Our website may contain links to external sites. We are not responsible for the content or practices of these external sites.</p>
      <h2 className={styles.h2}>5. Changes to Disclaimer</h2>
      <p className={styles.p}>We may update this Disclaimer from time to time. Any changes will be posted on this page with an updated effective date.</p>
      <h2 className={styles.h2}>6. Contact Us</h2>
      <p className={styles.p}>If you have any questions about this Disclaimer, please contact us at [Your Contact Email].</p>
    </div>
  );
};

export default Disclaimer;
