import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { createAshtakVarga } from '../../../service/AstroService';
import { Divider, Grid } from '@mui/material';
import { useLocation } from "react-router-dom";
import { styled } from '@mui/material/styles';
import { ResponseContext } from '../../../App';
import Chart from '../charts/Chart';
import StatusIndicators from './StatusIndicators';

// Styled components for CSS-in-JS
const Container = styled('div')({
  padding: '2px',
  fontFamily: 'Arial, sans-serif',
});

const Title = styled('h1')({
  color: 'darkblue',
  fontSize: '25px',
  marginBottom: '10px',
});

const Subtitle = styled('h2')({
  color: 'darkblue',
  fontSize: '20px',
  marginBottom: '10px',
  float:'left'
});

const TableContainer = styled('div')({
  marginTop: '20px',
});

const CustomTable = styled('table')({
  width: '70%',
  borderCollapse: 'collapse',
  marginBottom: '20px',
});

const TableHeader = styled('thead')({
  backgroundColor: 'rgba(100, 149, 237, 0.4)',
});

const TableCellHeader = styled('th')({
  padding: '7px',
  textAlign: 'left',
  fontSize: '16px',
  fontWeight: 'bold',
  color: 'darkblue',
});

const TableCell = styled('td')({
  padding: '7px',
  // border: '1px solid #ddd',
  textAlign: 'left',
});

const TableRowOdd = styled('tr')({
  backgroundColor: '#ffffff',
  '&:nth-of-type(even)': {
    backgroundColor: 'rgba(100, 149, 237, 0.1)',
  },
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
});

const PrastharashtakvargaDetailsMobile = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [sunData, setSunData] = React.useState([]);
  const [moonData, setMoonData] = React.useState([]);
  const [marsData, setMarsData] = React.useState([]);
  const [mercuryData, setMercuryData] = React.useState([]);
  const [jupiterData, setJupiterData] = React.useState([]);
  const [venusData, setVenusData] = React.useState([]);
  const [saturnData, setSaturnData] = React.useState([]);
  const [sunValues, setSunValues] = React.useState([]);
  const [moonValues, setMoonValues] = React.useState([]);
  const [marsValues, setMarsValues] = React.useState([]);
  const [mercuryValues, setMercuryValues] = React.useState([]);
  const [jupiterValues, setJupiterValues] = React.useState([]);
  const [venusValues, setVenusValues] = React.useState([]);
  const [saturnValues, setSaturnValues] = React.useState([]);
  const {setGotResponse} = React.useContext(ResponseContext);

  const planets = ['Sun', 'Moon', 'Mars', 'Mercury', 'Jupiter', 'Venus', 'Saturn', 'Ascendant', 'Total'];
  const transformDataColumnWise = (data) => {
    if (data.length === 0) return [];
    const numRows = data.length;
    const numCols = Object.keys(data[0]).length; 
    const transposedData = Array.from({ length: numCols - 1 }, (_, colIndex) =>
      data.slice(0).map(row => row[colIndex + 1])
    );
    return transposedData.map((colData, colIndex) => {
      const rowData = {};
      rowData['0'] = colIndex + 1; 
      colData.forEach((value, rowIndex) => {
        rowData[(rowIndex + 1).toString()] = value; 
      });
      if (colIndex === numCols - 1) { 
        rowData[(numRows + 1).toString()] = colData.reduce((sum, value) => sum + (Number(value) || 0), 0); // Sum all values
      }
      return rowData;
    });
  };
  
  function filterKeys(obj) {
    let valuesArray = [];
    for (let key in obj) {
      let intKey = parseInt(key, 10);
      if (intKey >= 1 && intKey <= 12) {
        valuesArray[intKey - 1] = obj[key];
      }
    }
    return valuesArray.filter(value => value !== undefined);
  }
 
  
  React.useEffect(() => {
    if(location.pathname.includes('tamil') && i18n.language !=='ta')
      i18n.changeLanguage('ta') ;
    else if(location.pathname.includes('telugu') && i18n.language !=='te')
      i18n.changeLanguage('te');
    else if(location.pathname.includes('english') && i18n.language !=='en')
      i18n.changeLanguage('en');

    let ashtakInput = JSON.parse(localStorage.getItem("ashtakInput"));
    setGotResponse(false);
    createAshtakVarga(ashtakInput).then(response => {
      
      const data = { 
        sun: response.data.sun, 
        moon: response.data.moon,
        mars: response.data.mars,
        mercury: response.data.mercury,
        jupiter: response.data.jupiter,
        venus: response.data.venus,
        saturn: response.data.saturn
      };

      Object.keys(data).forEach((key) => {
        const transformedData = data[key].map((row, index) => {
          const rowData = {};
          for (let i = 0; i < row.length; i++) {
            const field = (i+1).toString();
            rowData[field] = row[i];
          }
          rowData[0] = t(planets[index]);
          return rowData;
        });
        
        switch (key) {
          case 'sun': setSunData(transformDataColumnWise(transformedData)); setSunValues(filterKeys(transformedData[8])); break;
          case 'moon': setMoonData(transformDataColumnWise(transformedData));setMoonValues(filterKeys(transformedData[8])); break;
          case 'mars': setMarsData(transformDataColumnWise(transformedData));setMarsValues(filterKeys(transformedData[8])); break;
          case 'mercury': setMercuryData(transformDataColumnWise(transformedData));setMercuryValues(filterKeys(transformedData[8])); break;
          case 'jupiter': setJupiterData(transformDataColumnWise(transformedData));setJupiterValues(filterKeys(transformedData[8])); break;
          case 'venus': setVenusData(transformDataColumnWise(transformedData));setVenusValues(filterKeys(transformedData[8])); break;
          case 'saturn': setSaturnData(transformDataColumnWise(transformedData));setSaturnValues(filterKeys(transformedData[8])); break;
          default: break;
        }
      });
      setGotResponse(true)
    }).catch(error => {
      console.error('Error fetching data:', error);
    });
  }, [location, i18n]);

  const renderTable = (data, title) => (
    <TableContainer>
      <Subtitle>{t(title)}</Subtitle>
      <CustomTable>
        <TableHeader>
          <tr>
            <TableCellHeader>{t("Ra")}</TableCellHeader>
            <TableCellHeader>{t(planets[0])}</TableCellHeader>
            <TableCellHeader>{t(planets[1])}</TableCellHeader>
            <TableCellHeader>{t(planets[2])}</TableCellHeader>
            <TableCellHeader>{t(planets[3])}</TableCellHeader>
            <TableCellHeader>{t(planets[4])}</TableCellHeader>
            <TableCellHeader>{t(planets[5])}</TableCellHeader>
            <TableCellHeader>{t(planets[6])}</TableCellHeader>
            <TableCellHeader>{t(planets[7])}</TableCellHeader>
            <TableCellHeader>{t("Tot")}</TableCellHeader>
          </tr>
        </TableHeader>
        <tbody>
          {data.map((row, index) => (
            <TableRowOdd key={index}>
              {Object.entries(row).map(([key, value]) => (
                <TableCell key={key}>
                  {value}
                </TableCell>
              ))}
            </TableRowOdd>
          ))}
        </tbody>
      </CustomTable>
    </TableContainer>
  );

  return (
    <Container>
      <Title sx={{textAlign:'left}'}}>{t("Prastharashtakvarga")}</Title>
      <Divider sx={{ filter: 'blur(1px) opacity(0.5)', borderColor: 'blue', width: '100%' }} />
      
      {renderTable(sunData, "Sun_Full")}
      <Grid container style={{marginTop:50}}>
        <Grid item >
        <Chart vargaInput={sunValues} arrayMode={'arrayMode'} />
        </Grid>
        <Grid item>
        <StatusIndicators/>
        </Grid>
      </Grid>
      <Divider sx={{ filter: 'blur(1px) opacity(0.5)', borderColor: 'blue', width: '100%' }} />
      {renderTable(moonData, "Moon_Full")}
      <Grid container style={{marginTop:50}}>
        <Grid item >
        <Chart vargaInput={moonValues} arrayMode={'arrayMode'} />
        </Grid>
        <Grid item>
        <StatusIndicators/>
        </Grid>
      </Grid>
      <Divider sx={{ filter: 'blur(1px) opacity(0.5)', borderColor: 'blue', width: '100%' }} />
      {renderTable(marsData, "Mars_Full")}
      <Grid container style={{marginTop:50}}>
        <Grid item >
        <Chart vargaInput={marsValues} arrayMode={'arrayMode'} />
        </Grid>
        <Grid item>
        <StatusIndicators/>
        </Grid>
      </Grid>
      <Divider sx={{ filter: 'blur(1px) opacity(0.5)', borderColor: 'blue', width: '100%' }} />
      {renderTable(mercuryData, "Mercury_Full")}
      <Grid container style={{marginTop:50}}>
        <Grid item >
        <Chart vargaInput={mercuryValues} arrayMode={'arrayMode'} />
        </Grid>
        <Grid item>
        <StatusIndicators/>
        </Grid>
      </Grid>
      <Divider sx={{ filter: 'blur(1px) opacity(0.5)', borderColor: 'blue', width: '100%' }} />
      {renderTable(jupiterData, "Jupiter")}
      <Grid container style={{marginTop:50}}>
        <Grid item >
        <Chart vargaInput={jupiterValues} arrayMode={'arrayMode'} />
        </Grid>
        <Grid item>
        <StatusIndicators/>
        </Grid>
      </Grid>
      <Divider sx={{ filter: 'blur(1px) opacity(0.5)', borderColor: 'blue', width: '100%' }} />
      {renderTable(venusData, "Venus_Full")}
      <Grid container style={{marginTop:50}}>
        <Grid item >
        <Chart vargaInput={venusValues} arrayMode={'arrayMode'} />
        </Grid>
        <Grid item>
        <StatusIndicators/>
        </Grid>
      </Grid>
      <Divider sx={{ filter: 'blur(1px) opacity(0.5)', borderColor: 'blue', width: '100%' }} />
      {renderTable(saturnData, "Saturn")}
      <Grid container style={{marginTop:50}}>
        <Grid item >
        <Chart vargaInput={saturnValues} arrayMode={'arrayMode'} />
        </Grid>
        <Grid item>
        <StatusIndicators/>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PrastharashtakvargaDetailsMobile;
