import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import '../../css/MyTableDesign.css'
import { ResponseContext } from '../../../App';
// Example response data context


export default function PlanetsAspects() {
  const { t, i18n } = useTranslation();
  const { responseData } = useContext(ResponseContext);
  const [data, setData] = useState([]);

 
  const columns = [
    { field: 'PLANETS', headerName: '', width: 150 },
    { field: 'JUPASPECTS', headerName: t("Ju"), width: 200 },
    { field: 'VENASPECTS', headerName: t("Ve"), width: 200 },
    { field: 'SATASPECTS', headerName: t("Sa"), width: 200 },
    { field: 'MARASPECTS', headerName: t("Ma") , width: 200 },
    { field: 'MOONASPECTS', headerName: t("Mo"), width: 200 },
    { field: 'MERASPECTS', headerName: t("Me"), width: 200 },
    { field: 'SUNASPECTS', headerName: t("Su") , width: 200 },
  ];




  function getFormattedValue(value) {
    if(value && value!=="-")
    return value+ "°"
  return value;
  }

  useEffect(() => {
    let result = JSON.parse(localStorage.getItem('responseData'));
    const planets = ['Sun','Moon','Mars','Mercury','Jupiter','Venus','Saturn','Rahu','Ketu'];
    const planets_shorts = ['Su','Mo','Ma','Me','Ju','Ve','Sa','Ra','Ke'];
    if(result) {
        const planetStrength = result['planetsStrength'];
        const rows = planets.map(key => ({
            id: key,
            PLANETS: key,
            JUPASPECTS: getFormattedValue(planetStrength.jupAspects[key]),
            VENASPECTS: getFormattedValue(planetStrength.venAspects[key]),
            SATASPECTS: getFormattedValue(planetStrength.satAspects[key]),
            MARASPECTS: getFormattedValue(planetStrength.marAspects[key]),
            MOONASPECTS: getFormattedValue(planetStrength.moonAspects[key]),
            MERASPECTS: getFormattedValue(planetStrength.merAspects[key]),
            SUNASPECTS: getFormattedValue(planetStrength.sunAspects[key]),
          }));
          setData(rows);
          // console.log(JSON.stringify(rows))
  }
  }, [responseData, i18n.language]);

  return (
    <div>
      
      <div className="table-container">
      
        <table className="table">
        <caption style={{
          textAlign: 'center',
          fontWeight: 'bold',
          color:'darkblue',
          backgroundColor: 'rgba(100, 149, 237, 0.1)',
          padding: '7px',
          borderBottom: '1px solid rgba(100, 149, 237, 0.2)',
          fontSize:16,
        }}>{t('Planets Aspects')}</caption>
          <thead className="thead">
            {/* <tr><th
        colSpan={columns.length}
        style={{
          textAlign: 'center',
          fontWeight: 'bold',
          color:'darkblue',
          backgroundColor: 'rgba(100, 149, 237, 0.1)',
          padding: '7px',
          borderBottom: '1px solid rgba(100, 149, 237, 0.2)',
          fontSize:18,
        }}
      >{t('Planets Aspects')}</th></tr> */}
            <tr>
              {columns.map((col) => (
                <th className="th" key={col.field}>{col.headerName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row) => (
               
              <tr className="tr" key={row.id}>
                <td className="td" >{t(row.PLANETS)}</td>
                <td className="td" style={{fontSize:14,paddingLeft:0}}>{t(row.JUPASPECTS)}</td>
                <td className="td" style={{fontSize:14,paddingLeft:0}}>{t(row.VENASPECTS)}</td>
                <td className="td" style={{fontSize:14,paddingLeft:0}}>{t(row.SATASPECTS)}</td>
                <td className="td" style={{fontSize:14,paddingLeft:0}}>{t(row.MARASPECTS)}</td>
                <td className="td" style={{fontSize:14,paddingLeft:0}}>{t(row.MOONASPECTS)}</td>
                <td className="td" style={{fontSize:14,paddingLeft:0}}>{t(row.MERASPECTS)}</td>
                <td className="td" style={{fontSize:14,paddingLeft:0}}>{t(row.SUNASPECTS)}</td>
              </tr>
             
            ))}
          </tbody>
        </table>
        <div style={{fontSize:13,color:'gray'}}><b>Note:</b> 5|10° 👉 5th Aspects in 10°, if no '|' 👉 Conjunctions</div>
      </div>
    </div>
  );
}
