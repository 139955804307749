// src/utils/planetUtils.js

import { lighten } from '@mui/material/styles';

// Define planet data
const lagnam = {
    englishShort: "As",
    englishFull: "Ascendant",
    color: "blue"
};
const jupiter = {
    englishShort: "Ju",
    englishFull: "Jupiter",
    color: "#cc00cc"
};
const venus = {
    englishShort: "Ve",
    englishFull: "Venus",
    color: "purple"
};
const mars = {
    englishShort: "Ma",
    englishFull: "Mars",
    color: "red"
};
const mercury = {
    englishShort: "Me",
    englishFull: "Mercury",
    color: "darkgreen"
};
const saturn = {
    englishShort: "Sa",
    englishFull: "Saturn",
    color: "darkblue"
};
const moon = {
    englishShort: "Mo",
    englishFull: "Moon",
    color: "#0080ff"
};
const sun = {
    englishShort: "Su",
    englishFull: "Sun",
    color: "#cc5200"
};
const rahu = {
    englishShort: "Ra",
    englishFull: "Rahu",
    color: "#000000"
};
const kethu = {
    englishShort: "Ke",
    englishFull: "Kethu",
    color: lighten("#000000", 0.3)
};

// Create a mapping of planet names to their data
const planetData = {
    Jupiter: jupiter,
    Rahu: rahu,
    Venus: venus,
    Mercury: mercury,
    Saturn: saturn,
    Mars: mars,
    Ketu: kethu,
    Sun: sun,
    Moon: moon,
    Ascendant: lagnam
};

// Function to retrieve planet data by name
export function getPlanet(planetName) {
    return planetData[planetName] || lagnam;
}
