import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ResponseContext } from '../../../App';
import { Grid } from '@mui/material';
import '../../css/MyTableDesign.css'



export default function AvasthaDetails() {
  const { t, i18n } = useTranslation();
  const { responseData } = React.useContext(ResponseContext);
  const [showTable,setShowTable] = React.useState()

  const columns = [
    // { field: 'id', headerName: 'No', width: 10, headerClassName: 'super-app-theme--header'},
    { field: 'NAME', headerName: t("Planets"), width: 190, headerClassName: 'super-app-theme--header',  },
    { field: 'VALUE', headerName: t("Avastha"), width: 190, headerClassName: 'super-app-theme--header' }
  ];
  const [data, setData] = React.useState([]);

  
  React.useEffect(() => {
    console.log("-----Avastha useEffect render-------- ")
    let avastha ={}

    let result = responseData != "" ? responseData.data.output : JSON.parse(localStorage.getItem('responseData'))
    avastha = result["avastha"];
    setShowTable(true)
    
  
    let newData = [];
    //  console.log("====="+JSON.stringify(result))
    if (avastha) {
    let i = 0
    newData.push({
      id: 1,
      NAME: t("Sun_Full"),
      VALUE: t(avastha.Sun),
    });
    newData.push({
      id: 2,
      NAME: t("Moon_Full"),
      VALUE: t(avastha.Moon),
    });
    newData.push({
      id: 3,
      NAME: t("Mars_Full"),
      VALUE: t(avastha.Mars),
    });
    newData.push({
      id: 4,
      NAME: t("Mercury_Full"),
      VALUE: t(avastha.Mercury),
    });
    newData.push({
      id: 5,
      NAME: t("Jupiter"),
      VALUE: t(avastha.Jupiter),
    });
    newData.push({
      id: 6,
      NAME: t("Venus_Full"),
      VALUE: t(avastha.Venus),
    });
    newData.push({
      id: 7,
      NAME: t("Saturn"),
      VALUE: t(avastha.Saturn),
    });
    setData(newData)
  }
  }, [responseData, i18n.language])
  return (
    <div >
   
      <Grid sx={{ height: 425, width: 382,marginTop:1,display:showTable? "display": "none" }} >
      <div className="table-container">
        <table className="table">
          <thead className="thead">
            <tr>
              {columns.map((col) => (
                <th className="th" key={col.field}>{col.headerName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row) => (
              <tr className="tr" key={row.id}>
                <td className="td">{row.NAME}</td>
                <td className="td">{row.VALUE}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      </Grid>
     
    </div>
  );
}