import React, { useContext, useRef, useState } from "react";
// import response from '../resource/sample.json'
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { ResponseContext } from "../../../App";
import { useTheme } from "@emotion/react";
import { lighten, useMediaQuery } from '@mui/material';
import { useNavigate,useLocation } from "react-router-dom";
import { getPlanet } from "../../common/planetUtils";

export default function RaashiChart({showTodayPlanets}) {
    const { t, i18n } = useTranslation();
    const navigation = useNavigate();
    const location =useLocation();
    const theme = useTheme();
    const canvasRef = useRef(null);
    // const [planetFontSize, setPlanetFontSize] = React.useState("19px Arial")
    const { responseData,panchangData,chartSettings,setChartSettings } = useContext(ResponseContext)
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const [size, setSize] = useState(isSmallScreen ? 0.93 : 1);
    const [isPanchang,setIsPanchang] = useState()
   
    
    const raasiPosition = 100;
    const raasiPositionY = 100;

    function compare(a, b) {
        if (a.normDegree > b.normDegree) return 1;
        if (b.normDegree > a.normDegree) return -1;

        return 0;
    }
  

    function getPlanetDegreePosition(planetName) {
        const positions = {
            "Jupiter": -20,
            "Rahu": -20,
            "Venus": i18n.language === 'ta'? -20:-15,
            "Mercury": -17,
            "Saturn": i18n.language === 'ta' && isSmallScreen ? -20 : -20,
            "Mars": -15,
            "Ketu": -20,
            "Sun": -20,
            "Moon": -20,
            "Ascendant": -20
        };
    
        return positions[planetName] || null; 
    }
    function getPlanetPositionAddInfo(planetName) {
        const positions = {
            "Jupiter": -5,
            "Rahu": -7,
            "Venus": -7,
            "Mercury": -5,
            "Saturn": 2,
            "Mars": 0,
            "Ketu": -2,
            "Sun": -10,
            "Moon": -5,
            "Ascendant": -7
        };
    
        return positions[planetName] || null; 
    }
    
    let allowedPlanets = ["Saturn", "Sun", "Mars", "Rahu", "Ketu", "Mercury", "Moon", "Venus", "Jupiter", "Ascendant"]
    React.useEffect(() => {
        let storedSettings = JSON.parse(localStorage.getItem('chartSettings'));
        console.log("---->"+JSON.stringify(storedSettings))
        if (!storedSettings) {
            storedSettings = {
                showCombust: true,
                showRetrograde: true,
                showVarkotham: true,
                showDegree: true,
                chartType: "Automatic",
                dateComponent: "Dropdown"
            };
            setChartSettings(JSON.stringify(storedSettings));
            localStorage.setItem('chartSettings', JSON.stringify(storedSettings));
        }
        setIsPanchang(!location.pathname.includes("details") || showTodayPlanets)
        console.log('Rashichart useEffect render ---- testing')
        let planetFontSize = "bold 19px Arial"
        let degreeSize= isSmallScreen ? "10px Arial" : "11px Arial"
        if (i18n.language == 'en')
            planetFontSize = isSmallScreen ? "15px Arial" : "17px Arial"
        else 
            planetFontSize = isSmallScreen ? "17px Arial" : "19px Courier New"
        let result = responseData != "" ? responseData.data.output : JSON.parse(localStorage.getItem('responseData'))
        if(location.pathname.includes("details") && !showTodayPlanets) {
            result = responseData != "" ? responseData.data.output : JSON.parse(localStorage.getItem('responseData'))
            result = result.planetsPositions;
        } else {
            // alert(localStorage.getItem('todaysPlanetsData'))
            result =JSON.parse(localStorage.getItem('todaysPlanetsData'))

        }
        
        let starName=""
        if (result) {
            const raasi = Array.from({ length: 12 }, () => []);
        
            Object.keys(result).forEach(function (key) {
                const planet = result[key];
                if (planet && allowedPlanets.includes(planet.name)) {
                    const signIndex = planet.current_sign - 1; // Adjust for zero-based index
                    if (signIndex >= 0 && signIndex < 12) {
                        raasi[signIndex].push({
                            name: planet.name,
                            fullDegree: planet.fullDegree,
                            normDegree: planet.normDegree,
                            isRetro: planet.isRetro,
                            isCombustion: planet.isCombustion,
                            isVarkotham: planet.current_sign===planet.navamsha_sign
                        });
                    }
                    if(planet.name==='Moon')
                        starName=planet.star_name;
                }
            });
    
            const canvas = canvasRef.current;
            const ctx = canvas.getContext("2d");
            ctx.clearRect(0, 0, canvas.width, canvas.height)
            ctx.beginPath();
            // var size = 0.85;
            ctx.beginPath();
            // let moveLeft=1

            // alert(result["0"]["current_sign"])

            let moveRight = 0
            let moveBottom = 200
            let lagna_pos = result["0"]["current_sign"];
            if (lagna_pos === 1) {
                moveRight = 100;
                moveBottom = 0;
            } else if (lagna_pos === 2) {
                moveRight = 200;
                moveBottom = 0;
            } else if (lagna_pos === 3) {
                moveRight = 300;
                moveBottom = 0;
            } else if (lagna_pos === 4) {
                moveRight = 300;
                moveBottom = 100;
            } else if (lagna_pos === 5) {
                moveRight = 300;
                moveBottom = 200;
            } else if (lagna_pos === 6) {
                moveRight = 300;
                moveBottom = 300;
            } else if (lagna_pos === 7) {
                moveRight = 200;
                moveBottom = 300;
            } else if (lagna_pos === 8) {
                moveRight = 100;
                moveBottom = 300;
            } else if (lagna_pos === 9) {
                moveRight = 0;
                moveBottom = 300;
            } else if (lagna_pos === 10) {
                moveRight = 0;
                moveBottom = 200;
            } else if (lagna_pos === 11) {
                moveRight = 0;
                moveBottom = 100;
            } else if (lagna_pos === 12) {
                moveRight = 0;
                moveBottom = 0;
            }
            
            ctx.moveTo(15 + moveRight * size, 0 + moveBottom * size);
            ctx.lineTo(0 + moveRight * size, 15 + moveBottom * size);
            ctx.moveTo(12 + moveRight * size, 0 + moveBottom * size);
            ctx.lineTo(0 + moveRight * size, 12 + moveBottom * size);

            ctx.rect(size * 1, size * 1, size * 100, size * 100);
            ctx.rect(size * 1, size * 101, size * 100, size * 100);
            ctx.rect(size * 1, size * 201, size * 100, size * 100);
            ctx.rect(size * 1, size * 301, size * 100, size * 100);

            ctx.rect(size * 1, size * 1, size * 200, size * 100);
            ctx.rect(size * 1, size * 1, size * 300, size * 100);
            ctx.rect(size * 1, size * 1, size * 400, size * 100);

            ctx.rect(size * 301, size * 101, size * 100, size * 100);
            ctx.rect(size * 301, size * 201, size * 100, size * 100);
            ctx.rect(size * 301, size * 301, size * 100, size * 100);

            ctx.rect(size * 101, size * 301, size * 100, size * 100); //viruchagam
            ctx.rect(size * 201, size * 301, size * 100, size * 100); //thulam

            ctx.strokeStyle = '#4B61D1';
            ctx.fillStyle = "blue"
            let nextPosition = 0, nextPositionY = 0;
            ctx.font = planetFontSize;
            if(!location.pathname.includes("details") || showTodayPlanets) {
                ctx.font = "15px Arial";
                let tt =  t("Today's planet positions")
                ctx.fillText(tt, size * ((isSmallScreen?5:25) + nextPosition + raasiPosition * 1), size * (100 + nextPositionY + raasiPositionY * 1))
            } else {
                let tt = t('Rashi')
                ctx.fillText(tt, size * (80 + nextPosition + raasiPosition * 1), size * (100 + nextPositionY + raasiPositionY * 1))
                ctx.font = "13px Arial";
                ctx.fillStyle = "darkblue"
                let requestData = JSON.parse(localStorage.getItem('requestData'))
                if (!requestData) {
                    navigation("/")
                    return
                }
                let formatedDate = moment(new Date(parseInt(requestData.birthTimeStamp))).format('DD/MM/yyy hh:mm a')
                ctx.fillText(t('Nakshatra') +' : '+ t(starName), size * (10 + nextPosition + raasiPosition * 1), size * (30 + nextPositionY + raasiPositionY * 1))
                ctx.fillText(formatedDate, size * (10 + nextPosition + raasiPosition * 1), size * (50 + nextPositionY + raasiPositionY * 1))
                ctx.fillText(t('Place') +' : '+ requestData.birthPlace.place, size * (10 + nextPosition + raasiPosition * 1), size * (70 + nextPositionY + raasiPositionY * 1))
                ctx.fillText(t('Lat/Lon') +' : '+ requestData.lat+' / '+requestData.lon, size * (10 + nextPosition + raasiPosition * 1), size * (130 + nextPositionY + raasiPositionY * 1))
            
            }
                
            
               // ctx.fillText(t('Longitude') +' : '+ requestData.lon, size * (10 + nextPosition + raasiPosition * 1), size * (150 + nextPositionY + raasiPositionY * 1))

            const drawRaasi = (raasiArray, positionX, positionY) => {
                raasiArray.sort(compare)
                let nextPosition = 0;
                let nextPositionY = 0;
                let inc = 1;

                raasiArray.forEach((val) => {
                    const planet = getPlanet(val.name);
                    ctx.fillStyle = planet.color;
                    ctx.font = planetFontSize;
                    if(isSmallScreen && i18n.language==='ta' && val.name==='Saturn')
                    ctx.font = "16px Arial"
                    ctx.fillText(t(planet.englishShort), size * (5 + nextPosition + raasiPosition * positionX), size * (27 + nextPositionY + raasiPositionY * positionY));
                    ctx.font = degreeSize;
                    if(storedSettings.showDegree)
                    ctx.fillText(Math.floor(val['normDegree']), size * ((35 + getPlanetDegreePosition(val.name)) + nextPosition + raasiPosition * positionX), size * (13 + nextPositionY + raasiPositionY * positionY));

                    if (val['isRetro'] === true && storedSettings.showRetrograde) {
                        ctx.font = "8px Arial";
                        ctx.fillStyle="black"
                        ctx.fillText('◀', size * (38 + getPlanetPositionAddInfo(val.name) + nextPosition + raasiPosition * positionX), size * (19 + nextPositionY + raasiPositionY * positionY));
                    }
                    if (val['isCombustion'] === true && storedSettings.showCombust) {
                        ctx.font = "7px Arial";
                        ctx.fillText('🔥', size * (38 +getPlanetPositionAddInfo(val.name)+ nextPosition + raasiPosition * positionX), size * (29 + nextPositionY + raasiPositionY * positionY));
                    }
                    if (val['isVarkotham'] === true && storedSettings.showVarkotham) {
                        ctx.font = "5.5px Arial";
                        ctx.fillText('🔲', size * (38 +getPlanetPositionAddInfo(val.name)+ nextPosition + raasiPosition * positionX), size * (10 + nextPositionY + raasiPositionY * positionY));
                    }

                    if (inc % 2 === 1) {
                        nextPosition = 50;
                    } else {
                        nextPosition = 0;
                        nextPositionY += (raasiArray.length > 6 ? 20 : 30);
                    }
                    inc++;
                });
            };
            drawRaasi(raasi[0], 1, 0);
            drawRaasi(raasi[1], 2, 0);
            drawRaasi(raasi[2], 3, 0);
            drawRaasi(raasi[3], 3, 1);
            drawRaasi(raasi[4], 3, 2);
            drawRaasi(raasi[5], 3, 3);
            drawRaasi(raasi[6], 2, 3);
            drawRaasi(raasi[7], 1, 3);
            drawRaasi(raasi[8], 0, 3);
            drawRaasi(raasi[9], 0, 2);
            drawRaasi(raasi[10], 0, 1);
            drawRaasi(raasi[11], 0, 0);
            ctx.stroke();
            ctx.stroke();
        }

    }, [responseData,i18n.language,panchangData,chartSettings]);


    return (


        <div>

            <canvas
                ref={canvasRef}
                width={405 * size}
                height={405 * size}

                style={{
                    border: "1px solid white",
                    background: "white",
                    margin: isSmallScreen ? 1 : 10
                }}
            /> 
            
        </div>
    );
}