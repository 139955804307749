import axios from "axios";
import { API_URL, CONTACT_US_URL, FORGOT_PASSWORD_URL, GET_PROFILE_URL, LOGIN_URL, LOGOUT_URL, RESEND_EMAIL_VERIFICATION_LINK, RESET_PASSWORD_URL, SIGNUP_URL, UPDATE_PROFILE_URL, UPDATE_SETTINGS_URL, VERIFY_EMAIL_URL } from "../config/constants";
import { cleanLocalStorage } from "./Utitility";
axios.defaults.withCredentials = true;
export const userLogin = async (formData) => {
    try {
        const response = await axios.post(LOGIN_URL , 
          formData
        );
        const { username,user_settings,user_type,email_verified } = response.data;
        // localStorage.setItem('access_token', access_token);
        localStorage.setItem('username',username);
        localStorage.setItem('userType',user_type);
        localStorage.setItem('emailVerified',email_verified)
        if(user_settings)
        localStorage.setItem('chartSettings',user_settings);
        
        return username
      } catch (error) {
        console.error('Login error:', error);
        const errorMessage = error.response?.data?.error || 'An unexpected error occurred.';
        throw errorMessage;
      }
}
export const userLogout = async () => {
    try {
        const response = await axios.post(LOGOUT_URL);
        console.log("---logout---"+JSON.stringify(response))
        // localStorage.removeItem('access_token');
        localStorage.removeItem('username');
        localStorage.removeItem('userType');
        localStorage.removeItem('emailVerified')
      } catch (error) {
        console.error('Logout error:', error);
        throw error
      }
}
export const userSignup = async (formData) => {
    try {
        const response = await axios.post(SIGNUP_URL , 
          formData
        );
        const { access_token,username } = response.data;
        console.log("------"+JSON.stringify(response))
        // localStorage.setItem('access_token', access_token);
        localStorage.setItem('username',username)
        localStorage.setItem('emailVerified',false)
        console.log("sss")
        return username
      } catch (error) {
        console.error('Signup error:', error);
        const errorMessage = error.response?.data?.error || 'An unexpected error occurred.';
        throw errorMessage;
      }
}
export const getProfile = async () => {
  try {
      // const token = localStorage.getItem('access_token');
      const response = await axios.get(GET_PROFILE_URL, {
          headers: {
              'Access-Control-Allow-Origin': '*',
              'X-Server': localStorage.getItem('userType')
          }
      });
      return response
  } catch (error) {
      console.error('getProfile error:', error);
      cleanLocalStorage(error);
      throw error;
  }
}

export const updateProfile = async (formData) => {
  try {
      const {csrfToken,token} = await getCsrfToken();
      const response = await axios.post(UPDATE_PROFILE_URL , 
        formData, {
          headers: {
              // 'Access-Control-Allow-Origin': '*',
              'X-CSRF-Token': csrfToken,
              'Authorization': `Bearer ${token}`,
              'X-Server': localStorage.getItem('userType')
          }
      }
      );
      return response.data
    } catch (error) {
      console.error('updateProfile error:', error);
      cleanLocalStorage(error);
      throw error;
    }
}
export const updateUserSettings = async (settings) => {
  try {
      // const token = localStorage.getItem('access_token');
      const {csrfToken,token} = await getCsrfToken();
      const response = await axios.put(UPDATE_SETTINGS_URL, settings, {
          headers: {
              'Access-Control-Allow-Origin': '*',
              'X-CSRF-Token': csrfToken,
              'Authorization': `Bearer ${token}`,
              'X-Server': localStorage.getItem('userType')
          }
      });
      return response;
  } catch (error) {
      console.error('Error while updating chart settings:', error);
      cleanLocalStorage(error);
      throw error;
  }
};

export const getCsrfToken = async () => {
  try {
      const response = await axios.get(API_URL+'/ui/get_csrf_token', {
          headers: {
              'Access-Control-Allow-Origin': '*',
              'X-Server': localStorage.getItem('userType')
          }
      });
      const csrfToken = response.data.csrf_token;
      console.log('CSRF Token:', csrfToken);
      return response.data;
  } catch (error) {
      console.error('Error fetching CSRF token:', error);
      alert("You are not logged in or Session may have expired.")
      cleanLocalStorage(error);
      throw error;
  }
};
export const resendVerificationLink = async () => {
  try {
      const response = await axios.get(RESEND_EMAIL_VERIFICATION_LINK, {
          headers: {
              'Access-Control-Allow-Origin': '*',
              'X-Server': localStorage.getItem('userType')
          }
      });
      return response.data;
  } catch (error) {
      console.error('Error while resendVerificationLink:', error);
      cleanLocalStorage(error);
      const errorMessage = error.response?.data?.error || 'An unexpected error occurred.';
      throw errorMessage;
  }
};
export const verifyEmail = async (verificationId,token) => {
  try {
      const response = await axios.get(VERIFY_EMAIL_URL, {
          headers: {
              'Access-Control-Allow-Origin': '*',
              'X-Server': localStorage.getItem('userType')
          },
          params: { verification_id: verificationId, token: token }
      });
      return response;
  } catch (error) {
      console.error('Error while verifyEmail:', error);
      cleanLocalStorage(error);
      const errorMessage = error.response?.data?.error || 'An unexpected error occurred.';
      throw errorMessage;
  }
};

export const userForgotPassword = async (email) => {
  try {
    const response = await axios.post(FORGOT_PASSWORD_URL, 
      { email: email }, 
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Server': localStorage.getItem('userType')
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error while sending forgot password request:', error);
    cleanLocalStorage(error);
    const errorMessage = error.response?.data?.error || 'An unexpected error occurred.';
    throw errorMessage;
  }
};
export const userPasswordReset = async (verification_id,token,new_password) => {
  try {
    const response = await axios.post(RESET_PASSWORD_URL, 
      { verification_id: verification_id,token:token,new_password:new_password }, 
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Server': localStorage.getItem('userType')
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error while sending reset password request:', error);
    cleanLocalStorage(error);
    const errorMessage = error.response?.data?.error || 'An unexpected error occurred.';
    throw errorMessage;
  }
};

export const contactUs = async (formData) => {
  try {
      const response = await axios.post(CONTACT_US_URL , 
        formData, {
          headers: {
            'Content-Type': 'application/json',
            'X-Server': localStorage.getItem('userType')
          }
      }
      );
      return response.data
    } catch (error) {
      console.error('contactUs error:', error);
      throw error;
    }
}