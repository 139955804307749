import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import '../../css/MyTableDesign.css'
import { ResponseContext } from '../../../App';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { Grid } from '@mui/material';
import { getPlanet } from '../../common/planetUtils';
// Example response data context


export default function FixedSizeGrid() {
  const { t, i18n } = useTranslation();
  const { responseData } = useContext(ResponseContext);
  const [data, setData] = useState([]);
  const [exchangeList, setExchangeList] = useState([]);

  const columns = [
    { field: 'PLANETS', headerName: t("Planets") },
    { field: 'RASHI', headerName: t("Rashi") },
    { field: 'DEGREE', headerName: t("Degree") },
    { field: 'NAKSHATRA', headerName: t("Nakshatra") }
  ];

  const convertFractDegree = (val) => {
    let array = val.split(".");
    let val1 = array[0];
    let val2 = parseInt(array[1]) * 60 / 100;
    if (val2 < 10) {
      val2 = "0" + val2;
    }
    return parseFloat(val1 + "." + val2).toFixed(2);
  };

  const addPosition = (planet, value, values) => {
    switch (planet) {
      case 'Sun':
        values[0] = value;
        break;
      case 'Moon':
        values[1] = value;
        break;
      case 'Mars':
        values[2] = value;
        break;
      case 'Mercury':
        values[3] = value;
        break;
      case 'Jupiter':
        values[4] = value;
        break;
      case 'Venus':
        values[5] = value;
        break;
      case 'Saturn':
        values[6] = value;
        break;
      case 'Ascendant':
        values[7] = value;
        break;
      default:
        break;
    }
  };



  function findParivarthanaiPlanets(planetPositions, planetHouses) {
  let exchangeList = []
   let skipList=[]
    for (const [planet, position] of planetPositions.entries()) {
      if(!skipList.includes(planet)) {
        for (const [planet2, position2] of planetPositions.entries()) {
          if(planetHouses.get(planet) && planetHouses.get(planet2) && planet !== planet2 && 
          planetHouses.get(planet).includes(position2) && planetHouses.get(planet2).includes(position)) {
            skipList.push(planet2)
            exchangeList.push(planet+":"+planet2)
          } 
        }
      }
    }
    return exchangeList;
}

// Example usage
// let planetPositions = new Map( [ ['Sun', 4],
//   ['Moon', 5],
//   ['Mars', 2],
//   ['Mercury', 12],
//   ['Jupiter', 3],
//   ['Venus', 1],
//   ['Saturn', 7]]); 

const planetHouses = new Map([
    ['Sun', [5]],
    ['Moon', [4]],
    ['Mars', [1, 8]],
    ['Mercury', [3, 6]],
    ['Jupiter', [9, 12]],
    ['Venus', [2, 7]],
    ['Saturn', [10, 11]]
]);

  useEffect(() => {
    const planetPositions = new Map();
    let result = JSON.parse(localStorage.getItem('responseData'));
    if(result && result.planetsPositions) {
    let newData = [];
    let ashtak_input = [0, 0, 0, 0, 0, 0, 0, 0];
    for (let i = 0; i < 10; i++) {
      let row = result.planetsPositions[i];
      console.log(row)
      if (!row) break; // Break if there's no data for this index
      let planetName = t(row.name);
      let isRetro = row.isRetro;
      let isCombustion = row.isCombustion;
      let isVarkotham = row.current_sign===row.navamsha_sign
      let rashi = t(row.rashi);
      let degree = convertFractDegree(row.normDegree.toFixed(2)) + "°";
      let nakshatra = t(row.star_name) + '(' + row.leg + ')';
      addPosition(row.name, (row.current_sign - 1), ashtak_input);
      planetPositions.set(row.name,row.current_sign);
      newData.push({
        id: i + 1,
        PLANETS: planetName,
        isCombustion:isCombustion,
        isRetro:isRetro,
        isVarkotham:isVarkotham,
        RASHI: rashi,
        DEGREE: degree,
        NAKSHATRA: nakshatra
      });
    }
    console.log(planetPositions)
    setExchangeList(findParivarthanaiPlanets(planetPositions, planetHouses));
    console.log("))))"+ashtak_input)
    localStorage.setItem("ashtakInput", JSON.stringify(ashtak_input));
    setData(newData);
  }
  }, [responseData, i18n.language]);

  return (
    <div>
        {/* <div style={{ padding: '10px', maxWidth: '400px', marginBottom: '10px', border: '2px solid skyblue', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0,0,0,0.2)' }}> */}
      {/* Header */}
      {/* <div style={{ fontSize: '17px', fontWeight: 'bold', color: 'darkblue', marginBottom: '16px', textAlign: 'left' }}>
      Parivarthana :
      </div> */}

      {/* List Items */}
      {
        (exchangeList.length>0 &&
      <Grid container>
        <Grid item>  <div style={{ fontSize: '16px', fontWeight: 'bold', color: 'darkblue', marginBottom: '16px', textAlign: 'left',padding:5 }}>
        {t('Parivarthana')} : </div></Grid>
        {exchangeList.map((item, index) => {
          // Split the string by ":"
          const [firstPart, secondPart] = item.split(':');
          
          return (
            <Grid item key={index} style={{ 
              display: 'flex', 
              alignItems: 'center', 
              marginBottom: '12px', 
              padding: '4px',
              border: '1px solid rgba(100, 149, 237, 0.3)', 
              borderRadius: '4px', 
              // fontWeight:'bold',
              backgroundColor: '#f9f9f9', maxWidth:100,marginLeft:exchangeList.length<=2?20:0
              // boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
            }}>
              <span style={{ flex: 1, textAlign: 'left', color: getPlanet(firstPart).color, fontSize: '15px' }}>{t(getPlanet(firstPart).englishShort)}</span>
              <SwapHorizIcon style={{ fontSize:17, margin: '0 4px', color: 'darkblue' }} /> {/* Icon with margin for spacing */}
              <span style={{ flex: 1, textAlign: 'right', color: getPlanet(secondPart).color, fontSize: '15px' }}>{t(getPlanet(secondPart).englishShort)}</span>
            </Grid>
          );
        })}
      </Grid>)

}
    {/* </div> */}

      <div className="table-container">
        <table className="table">
          <thead className="thead">
            <tr>
              {columns.map((col) => (
                <th className="th" key={col.field}>{col.headerName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row) => (
              <tr className="tr" key={row.id}>
                <td className="td">{row.PLANETS}<span style={{fontSize:11}}>{row.isCombustion ? '🔥' : ''}</span><span style={{fontSize:11}}>{row.isRetro ? '◀' : ''}</span>
                <span style={{fontSize:6}}>{row.isVarkotham ? '🔲' : ''}</span></td>
                <td className="td">{row.RASHI}</td>
                <td className="td">{row.DEGREE}</td>
                <td className="td">{row.NAKSHATRA}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
