import React, { useState } from 'react';
import {
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Divider,
  ListItemIcon,
  Popover,
  useMediaQuery,
  useTheme,
  Box,
  lighten,
  Button,
  Tooltip
} from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import CheckIcon from '@mui/icons-material/Check';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LanguageMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [anchorElMore, setAnchorElMore] = useState(null); // Dropdown menu for more languages
  const [selectedLanguage] = useState('English');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const { t } = useTranslation();
  const navigation = useNavigate();
  const changeLanguage = (lang) => {
    let newPath=location.pathname==="/"?"/english":location.pathname;
    const replaceList = ["english", "tamil", "telugu"];
    replaceList.forEach(item => {
      newPath = newPath.replace(item, lang);
    });

  //   if (lang==='English') {
  //       newPath = location.pathname==="/"?"/english": location.pathname.replaceAll("tamil","english");
  //   } else if (lang===  "tamil") {
  //     newPath = location.pathname==="/"?"/tamil": location.pathname.replaceAll("english","tamil");
  //   } else if (lang===  "telugu") {
  //     alert(location.pathname)
  //     newPath = location.pathname==="/"?"/telugu": location.pathname.replaceAll(["english","tamil"],"telugu");
  //   }
  //  alert(location.pathname.replaceAll(["english","tamil"],"telugu"))
    navigation(newPath);
  };
  const isLangSelected =(lang) => {
    if (location.pathname==="/" && lang ==="English")
        return true;
    return location.pathname.includes(lang.toLocaleLowerCase())
  }
  // Open the menu or popover
  const handleClick = (event) => {
    if (isMobile) {
      setPopoverAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  // Open the more languages menu
  const handleMoreClick = (event) => {
    setAnchorElMore(event.currentTarget);
  };

  // Close the menu or popover
  const handleClose = () => {
    setAnchorEl(null);
    setPopoverAnchorEl(null);
    setAnchorElMore(null);
  };

  // Handle language selection
  const handleLanguageChange = (language) => {
    changeLanguage(language);
    handleClose();
  };

  // Determine if the menu is open
  const openMenu = Boolean(anchorEl);
  const id = openMenu ? 'language-menu' : undefined;

  // Determine if the popover is open
  const openPopover = Boolean(popoverAnchorEl);

  // Determine if the more languages menu is open
  const openMore = Boolean(anchorElMore);

  // Languages list
  const languages = ['english', 'tamil', 'telugu', 'ಕನ್ನಡ', 'മലയാളം', 'Gujarati', 'Marathi'];

  // Display first 5 languages directly, rest in dropdown
  const displayedLanguages = languages.slice(0, 4);
  const moreLanguages = languages.slice(4);
  const CustomTooltipContent = ({ content }) => (
    <div style={{ textAlign: 'center', fontSize: 14}}>
      <strong>{content}</strong>
    </div>
  );
  
  return (
    <div>
      {/* For mobile screens, show popover */}
      {isMobile ? (
        <>
          <IconButton
            color="inherit"
            onClick={handleClick}
            sx={{
              fontSize: 24,
              '&:hover': {
                backgroundColor: 'transparent',
                color: '#0056b3', // Enhanced hover color
              },
            }}
          >
            <LanguageIcon />
            <ArrowDropDownIcon sx={{ fontSize: 30, color: '#ffffff' }} />
          </IconButton>
          <Popover
            open={openPopover}
            anchorEl={popoverAnchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            PaperProps={{
              sx: {
                width: '45vw', // Slightly increased width for mobile
                maxWidth: 300, // Max width
                background: '#f0f8ff', // Light blue background
                color: '#333', // Dark text color
                borderRadius: 1.5, // Rounded corners
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)', // Subtle shadow
                padding: '8px 0', // Padding for content
              },
            }}
          > 
            <Typography
              variant="subtitle1"
              sx={{
                padding: '10px 16px',
                backgroundColor: '#007BFF', // Header background color
                color: '#ffffff',
                fontWeight: 'bold',
                borderTopLeftRadius: 12,
                borderTopRightRadius: 12,
              }}
            >
              Select Language
            </Typography>
            <Divider />
            {languages.map((lang) => (
              <MenuItem
                key={lang}
                selected={selectedLanguage === lang}
                onClick={() => handleLanguageChange(lang)}
                sx={{
                  backgroundColor: 'transparent',
                  color: '#333',
                  '&:hover': {
                    backgroundColor: '#e0f2f1', // Subtle hover effect
                  },
                  padding: '10px 16px',
                  borderBottom: '1px solid #ddd',
                }}
              >
                <ListItemIcon sx={{ minWidth: 'auto' }}>
                  {isLangSelected(lang) && <CheckIcon sx={{ fontSize: 20 }} />}
                </ListItemIcon>
                <Typography variant="body2">{t(lang)}</Typography>
              </MenuItem>
            ))}
          </Popover>
        </>
      ) : (
        // For larger screens, show the language options directly in the banner
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            background: '#4B61D1', // Match top bar color
            padding: '4px 12px',
            borderRadius: 2,
            // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // Shadow for the banner
            overflow: 'hidden',
          }}
        >
          {displayedLanguages.map((lang) => (
            <Tooltip
            title={<CustomTooltipContent content={lang}/>}
            placement="top"
            arrow
            enterDelay={500}
            leaveDelay={200}
          >
            <Typography
              key={lang}
              variant="body2"
              sx={{
                marginLeft: 1,
                padding: '8px 12px',
                color: '#ffffff',
                cursor: 'pointer',
                borderRadius: 2,
                transition: 'background-color 0.3s, color 0.3s',
                // border: '1px solid #003d80',
                backgroundColor: isLangSelected(lang)?'#003d80'  : lighten('#4B61D1',0.1), 
                fontWeight:'bold',
                '&:hover': {
                  backgroundColor: '#003d80', // Darker blue for hover
                  color: 'white',
                },
                // fontWeight: selectedLanguage === lang ? 'bold' : 'normal',
              }}
              onClick={() => handleLanguageChange(lang)}
            >
              {t(lang)}
            </Typography>
            </Tooltip>
          ))}
          {moreLanguages.length > 0 && (
       <Button
       onClick={handleMoreClick}
       sx={{
         marginLeft: 1,
         padding: '6px 8px',
         color: '#ffffff',
         cursor: 'pointer',
         borderRadius: 2,
         transition: 'background-color 0.3s, color 0.3s',
         backgroundColor: lighten('#4B61D1', 0.1),
         fontWeight: 'bold',
         textTransform: 'none', // Prevents text from being transformed to uppercase
         '&:hover': {
           backgroundColor: '#003d80', // Darker blue for hover
           color: 'white',
         },
       }}
     >
       <span>More</span>
       <ArrowDropDownIcon sx={{ ml: 1 }} />
     </Button>
     
          )}
          <Menu
            anchorEl={anchorElMore}
            open={openMore}
            onClose={handleClose}
            // PaperProps={{
            //   sx: {
            //     width: '200px', // Width for dropdown
            //     background: '#f0f8ff', // Light blue background
            //     color: '#333', // Dark text color
            //     borderRadius: 1.5, // Rounded corners
            //     boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)', // Subtle shadow
            //     padding: '8px 0', // Padding for content
            //   },
            // }}
          >
        
            
            {moreLanguages.map((lang) => (
              <MenuItem
                key={lang}
                selected={selectedLanguage === lang}
                onClick={() => handleLanguageChange(lang)}
                sx={{
                  backgroundColor: 'transparent',
                  color: '#333',
                  '&:hover': {
                    backgroundColor: '#e0f2f1', // Subtle hover effect
                  },
                  padding: '10px 16px',
                }}
              >
                <ListItemIcon sx={{ minWidth: 'auto' }}>
                  {selectedLanguage === lang && <CheckIcon sx={{ fontSize: 20 }} />}
                </ListItemIcon>
                <Typography variant="body2">{lang}</Typography>
              </MenuItem>
              
            ))}
            
          </Menu>
        </Box>
      )}
    </div>
  );
};

export default LanguageMenu;
