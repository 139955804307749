import React, { useContext } from 'react';
import { Box, Typography, List, ListItem, ListItemIcon, ListItemText, Paper, Button } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { ResponseContext } from '../App';
import { useTranslation } from 'react-i18next';

const SignUpBenefits = () => {
  const { t } = useTranslation();
  const {setOpenSignup} = useContext(ResponseContext);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 2,
        backgroundColor: '#ffffff',
        borderRadius: 8,
        boxShadow: 6,
        width: 380,
        margin: 'auto',
        textAlign: 'center',
        border: '1px solid #e0e0e0',
        '&:hover': {
          boxShadow: 12,
        }
      }}
    >
      <Typography
        variant="h5"
        component="h2"
        sx={{
          mb: 2,
          fontWeight: 'bold',
          color: '#4B61D1',
          textTransform: 'uppercase',
          letterSpacing: 0.5,
        }}
      >
        {t('Why Become a Member?')}
      </Typography>
      <Paper
        elevation={4}
        sx={{
          width: '100%',
          padding: 1,
          borderRadius: 4,
          backgroundColor: '#f0f4f8',
          border: '1px solid #dcdcdc',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <List sx={{ padding: 0 }}>
          <ListItem sx={{ mb: 0 }}>
            <ListItemIcon>
              <CheckCircle color="primary" sx={{ fontSize: 30 }} />
            </ListItemIcon>
            <ListItemText
              primary="Save, update, and search horoscopes effortlessly on the cloud"
              primaryTypographyProps={{ fontSize: '1.1rem', fontWeight: 'medium' }}
            />
          </ListItem>
          <ListItem sx={{ mb: 0 }}>
            <ListItemIcon>
              <CheckCircle color="primary" sx={{ fontSize: 30 }} />
            </ListItemIcon>
            <ListItemText
              primary="Access your saved horoscopes from any device"
              primaryTypographyProps={{ fontSize: '1.1rem', fontWeight: 'medium' }}
            />
          </ListItem>
          <ListItem sx={{ mb: 0 }}>
            <ListItemIcon>
              <CheckCircle color="primary" sx={{ fontSize: 30 }} />
            </ListItemIcon>
            <ListItemText
              primary="Personalize Your Settings"
              primaryTypographyProps={{ fontSize: '1.1rem', fontWeight: 'medium' }}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircle color="primary" sx={{ fontSize: 30 }} />
            </ListItemIcon>
            <ListItemText
              primary="Enjoy a smooth and user-friendly experience."
              primaryTypographyProps={{ fontSize: '1.1rem', fontWeight: 'medium' }}
            />
          </ListItem>
        </List>
      </Paper>
      <Button
        variant="contained"
        color="primary"
        sx={{
          mt: 2,
          padding: '8px 24px',
          fontSize: '1rem',
          borderRadius: 4,
          backgroundColor: '#4B61D1',
          '&:hover': {
            backgroundColor: '#3a4b9c',
          },
        }}
        onClick={()=>setOpenSignup(true)} // Adjust this href to your actual sign-up URL
      >
        Sign Up Now
      </Button>
    </Box>
  );
};

export default SignUpBenefits;
