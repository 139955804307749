import React, { useContext, useState } from "react";
import { useTranslation } from 'react-i18next';
import { ResponseContext } from "../../../App";
import { useTheme } from "@emotion/react";
import { lighten, useMediaQuery } from '@mui/material';
import TodayPlanetsDialog from "../../search/TodayPlanetsDialog";
import { getPlanet } from "../../common/planetUtils";
// import response from '../resource/sample.json'

export default function NavamshaChart() {
    const { t,i18n } = useTranslation();
    const theme = useTheme();
    const {responseData} = useContext(ResponseContext)
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const [size,setSize] = useState(isSmallScreen?0.93:1);
    
    
    const raasiPosition = 100;
    const raasiPositionY = 100;

    function compare(a, b) {
        if (a.normDegree > b.normDegree) return 1;
        if (b.normDegree > a.normDegree) return -1;

        return 0;
    }
    


    let allowedPlanets = ["Saturn", "Sun", "Mars", "Rahu", "Ketu", "Mercury", "Moon", "Venus", "Jupiter", "Ascendant"]
    React.useEffect(() => {
        // alert(i18n.language)
        console.log('navamasha useEffect render ---- testing')
        let planetFontSize="19px Arial"
        if(i18n.language=='en')
            planetFontSize=isSmallScreen?"14px Arial": "17px Arial"
        else if(i18n.language=='ta')
            planetFontSize=isSmallScreen?"16px Arial": "19px Arial"
        let result = responseData !=""?responseData.data.output:JSON.parse(localStorage.getItem('responseData'))
        result = result.planetsPositions;
        if (result) {
            let raasi = Array.from({ length: 12 }, () => []); // Creates an array of 12 empty arrays
        
            Object.keys(result).forEach(function (key) {
                const planet = result[key];
                if (planet && allowedPlanets.includes(planet.name)) {
                    const signIndex = parseInt(planet.navamsha_sign) - 1; // Convert to 0-based index
                    if (signIndex >= 0 && signIndex < 12) {
                        raasi[signIndex].push({
                            name: planet.name,
                            normDegree: planet.normDegree
                        });
                    }
                }
            });
            const canvas = document.getElementById("myCanvas2");
            const ctx = canvas.getContext("2d");
            ctx.clearRect(0, 0, canvas.width, canvas.height)
            ctx.beginPath();
            
            ctx.rect(size * 1, size * 1, size * 100, size * 100);
            ctx.rect(size * 1, size * 101, size * 100, size * 100);
            ctx.rect(size * 1, size * 201, size * 100, size * 100);
            ctx.rect(size * 1, size * 301, size * 100, size * 100);

            ctx.rect(size * 1, size * 1, size * 200, size * 100);
            ctx.rect(size * 1, size * 1, size * 300, size * 100);
            ctx.rect(size * 1, size * 1, size * 400, size * 100);

            ctx.rect(size * 301, size * 101, size * 100, size * 100);
            ctx.rect(size * 301, size * 201, size * 100, size * 100);
            ctx.rect(size * 301, size * 301, size * 100, size * 100);

            ctx.rect(size * 101, size * 301, size * 100, size * 100); //viruchagam
            ctx.rect(size * 201, size * 301, size * 100, size * 100); //thulam

            ctx.strokeStyle = '#4B61D1';
            ctx.fillStyle = "blue"
            let nextPosition = 0, nextPositionY = 0;
            let inc = 1;
            ctx.font = planetFontSize;
            ctx.fillText(t("Navamsa"), size * (70 + nextPosition + raasiPosition * 1), size * (100 + nextPositionY + raasiPositionY * 1));



            const drawRaasi = (raasiArray, positionX, positionY) => {
                raasiArray.sort(compare)
                let nextPosition = 0;
                let nextPositionY = 0;
                let inc = 1;

                raasiArray.forEach((val) => {
                    let planet = getPlanet(val.name);
                ctx.fillStyle = getPlanet(val.name).color;
                ctx.font = planetFontSize;
                ctx.fillText(t(planet.englishShort), size * (5 + nextPosition + raasiPosition * positionX), size * (27 + nextPositionY + raasiPositionY * positionY));

                if (inc % 2 == 1) {
                    nextPosition = 50;
                } else {
                    nextPosition = 0;
                    nextPositionY = nextPositionY + (raasiArray.length > 6 ? 20 : 30);
                }
                inc++;
                });
            };
            drawRaasi(raasi[0], 1, 0);
            drawRaasi(raasi[1], 2, 0);
            drawRaasi(raasi[2], 3, 0);
            drawRaasi(raasi[3], 3, 1);
            drawRaasi(raasi[4], 3, 2);
            drawRaasi(raasi[5], 3, 3);
            drawRaasi(raasi[6], 2, 3);
            drawRaasi(raasi[7], 1, 3);
            drawRaasi(raasi[8], 0, 3);
            drawRaasi(raasi[9], 0, 2);
            drawRaasi(raasi[10], 0, 1);
            drawRaasi(raasi[11], 0, 0);



            ctx.stroke();
            ctx.stroke();
        }

    }, [responseData,i18n.language]);
    // ⏹️ வர்கோத்தமம்
    return (
        <div >
           
            <canvas
                id="myCanvas2"
                width={405*size}
                height={405*size}
                style={{  border: "1px solid white", background: "white",margin:isSmallScreen?1:10  }}
            />
        </div>
    );
}