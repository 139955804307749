import React, { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchParamsContext } from './CreateChart';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
// Date and Time Entry Component
const DateTimeEntry = () => {
  const {dateEntry, setDateEntry, timeEntry, setTimeEntry, amPmEntry, setAmPmEntry} = useContext(SearchParamsContext)
  const { t } = useTranslation();
  const timeInputRef = useRef(null);
  const amPmInputRef = useRef(null);
  // Helper function to format date as DD-MM-YYYY
  const formatDate = (value) => {
    const cleaned = value.replace(/\D/g, '');
    if (cleaned.length <= 1) return cleaned;
    if (cleaned.length <= 3) return `${cleaned.slice(0, 2)}-${cleaned.slice(2)}`;
    return `${cleaned.slice(0, 2)}-${cleaned.slice(2, 4)}-${cleaned.slice(4, 8)}`;
  };

  // Helper function to format time as HH:mm
  const formatTime = (value) => {
    const cleaned = value.replace(/\D/g, '');
    if (cleaned.length <= 1) return cleaned;
    if (cleaned.length <= 3) return `${cleaned.slice(0, 2)}:${cleaned.slice(2)}`;
    return `${cleaned.slice(0, 2)}:${cleaned.slice(2)}`;
  };

  // Helper function to format time with AM/PM
  const getFormattedTimeWithAmpm = () => {
    const hours = parseInt(timeEntry.slice(0, 2), 10) % 12 || 12;
    const minutes = timeEntry.slice(3, 5);
    return `${hours}:${minutes} ${amPmEntry}`;
  };

 


  // Handle input changes
  const handleDateChange = (e) => {
    const value = e.target.value;
    if (e.nativeEvent.inputType === 'deleteContentBackward' || e.nativeEvent.inputType === 'deleteContentForward') {
        setDateEntry(value);
        return;
      }
    
    const formattedDate = formatDate(value);
    setDateEntry(formattedDate);

    // Move focus to time input field when date entry is complete
    if (formattedDate.length === 10) {
      timeInputRef.current.focus();
    }
  };

  const handleTimeChange = (e) => {
    const value = e.target.value;
    if (e.nativeEvent.inputType === 'deleteContentBackward' || e.nativeEvent.inputType === 'deleteContentForward') {
        setTimeEntry(value);
        return;
      }
    
    const formattedTime = formatTime(value)
    setTimeEntry(formatTime(value));
    if (formattedTime.length === 5) {
        amPmInputRef.current.focus();
      }
  };

  const handleAmpmChange = (e) => {
    const value = e.target.value.trim().toUpperCase();
    if (e.nativeEvent.inputType === 'deleteContentBackward' || e.nativeEvent.inputType === 'deleteContentForward') {
        setAmPmEntry('');
        return;
      }
    if (value === 'A' || value === 'AM') {
      setAmPmEntry('AM');
    } else if (value === 'P' || value === 'PM') {
        setAmPmEntry('PM');
    } 
  };

  const linkStyle = {
    textDecoration: 'none',
    color: '#007bff', marginLeft:10,fontSize:14,
    // fontWeight: 'bold',
    // borderBottom: '2px solid #007bff',
    transition: 'color 0.3s, border-bottom 0.3s',
    cursor: 'pointer'
};
const handleClearDate = () => {
  setDateEntry('');
  setTimeEntry('');
  setAmPmEntry('');
}

  return (
    <form  style={styles.form}>
      <div style={styles.formGroup}>
        <label style={styles.label}>{t("Birth Date and Time")}: {(( dateEntry || timeEntry || amPmEntry) &&   <a
                onClick={handleClearDate}
                style={linkStyle}
            >Clear Date</a> )}</label> 
      </div>
      <div style={styles.inputRow}>
        <input
          id="date"
          name="date"
          type="text"
          value={dateEntry}
          onChange={handleDateChange}
          placeholder="DD-MM-YYYY" autoComplete="off"
          style={styles.input}
        />
        <input ref={timeInputRef}
          id="time"
          name="time"
          type="text"
          value={timeEntry}
          onChange={handleTimeChange}
          placeholder="HH:mm" autoComplete="off"
          style={styles.timeInput}
        />
        <input ref={amPmInputRef}
          id="ampm"
          name="ampm"
          type="text"
          value={amPmEntry}
          onChange={handleAmpmChange}
          placeholder="AM/PM"
          style={styles.ampmInput}
        />
      </div>
      
     
    </form>
  );
};

// Styles for the form
const styles = {
  form: {
    maxWidth: '600px',
    margin: 'auto',
    // padding: '1rem',
    background: 'white',
    borderRadius: '8px',
    // boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',paddingTop:15,paddingBottom:15
  },
  formGroup: {
    marginBottom: '1rem',
  },
  label: {
    fontWeight: 'normal',
    color: 'darkblue',
    fontSize: '1rem',
    // marginBottom: '0.2rem',
    display: 'block', textAlign:'left',paddingBottom:5
  },
  inputRow: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
  input: {
    padding: '0.5rem',
    border: '1px solid lightblue',
    borderRadius: '4px',
    fontSize: '1rem',
    width: '125px',
    height: '50px',
    color: 'darkblue',
  },
  timeInput: {
    padding: '0.5rem',
    border: '1px solid lightblue',
    borderRadius: '4px',
    fontSize: '1rem',
    width: '80px',
    height: '50px',
    color: 'darkblue',
  },
  ampmInput: {
    padding: '0.5rem',
    border: '1px solid lightblue',
    borderRadius: '4px',
    fontSize: '1rem',
    width: '75px',
    height: '50px',
    color: 'darkblue',
  },
  error: {
    color: '#dc3545',
    fontSize: '0.875rem',
    marginTop: '0.5rem',
  },
 
};

export default DateTimeEntry;
