import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Collapse,
  Divider,
  lighten,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import clsx from "clsx";
import { ResponseContext } from "../../App";
import { useTranslation } from "react-i18next";
import { userLogout } from "../../service/UserService";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "95%",
    margin: "4px auto",
    borderRadius: "8px",
    transition: "all .5s",
    overflow: "hidden",
  },
  listItem: {
    transition: "all .5s",
    display: "flex",
    flexDirection: "column",
  },
  listLink: {
    padding: "0 0",
    textDecoration: "none",
    color: "inherit",
    transition: "all .5s",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  listLinkCollapsed: {
    [theme.breakpoints.up("md")]: {
      width: theme.spacing(7),
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  listIcon: {
    color: "inherit",
    justifyContent: "center",
  },
  listItemText: {
    [theme.breakpoints.up("md")]: {
      fontSize: 9,
    },
  },
  expanded: {
    backgroundColor: lighten("#191970", 0.1),
  },
  selected: {
    backgroundColor: lighten("#191970", 0.3),
  },
}));

const NavigationItem = ({ item, collapsed }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const classes = useStyles();
  const navigation = useNavigate();

  const {
    requestData,
    setRequestData,
    loggedInUser,
    setLoggedInUser,
    openChart,
    setOpenChart,
    openLogin,
    setOpenLogin,
    openSignup,
    setOpenSignup,
    openEdit,
    setOpenEdit,
    setOpenCreate,
    setOpenEditProfile,
    setOpenChartSettings,
    setMobileOpen, setGotResponse
  } = useContext(ResponseContext);

  // If navigation is nested
  const [open, setOpen] = React.useState(false);
  const nested = typeof item.navigationData === "object";

  const handleClick = () => {
    if(item.url !== "expand")
    setMobileOpen(false);
    if (item.url === "openSignIn") setOpenLogin(true);
    else if (item.url === "openSignUp") setOpenSignup(true);
    else if (item.url === "signOut") logout();
    else if (item.url === "settings") setOpenChartSettings(true);
    else if (item.url === "openHoroscopePopup") setOpenChart(true);
    else if (item.url === "openEditProfilePopup") setOpenEditProfile(true);
    else if (item.url === "openCreatePopup") setOpenCreate(true);
    else if (item.url === "openEditPopup") {
      if (pathname.includes("/details")) setOpenEdit(true);
      else setOpenChart(true);
    } 
    else if (item.url.includes("changeTo")) 
      changeLanguage();

    setOpen(!open);
  };

  const changeLanguage = () => {
    let lang = "english"
    if(item.url ==="changeToTa")
    lang ="tamil"
    else if(item.url ==="changeToTe")
    lang ="telugu"
    let newPath=pathname==="/"?"/english":pathname;
    const replaceList = ["english", "tamil", "telugu"];
    replaceList.forEach(item => {
      newPath = newPath.replace(item, lang);
    });
   
    navigation(newPath);
  };

  const logout = () => {
    setGotResponse(false)
    userLogout().then(() => {
      setLoggedInUser("");
      setRequestData("");
      navigation("/");
      setGotResponse(true)
    });
  };

  // Persisting the nested navigation open state on page refresh
  React.useEffect(() => {
    console.log("NavigationItem useEffect render test ")
    if (pathname.search(new RegExp(item.url, "g")) !== -1) {
      setOpen(true);
    }
  }, [pathname, item.url]);

  const determineToValue = (itemUrl) => {
    if (!itemUrl.includes("/")) {
      return pathname;
    } 
    let newPath = itemUrl;
    const replaceList = ["/english", "/tamil", "/telugu"];
    replaceList.forEach(item => {
      newPath = newPath.replace(item, "");
    });
    if(pathname.includes("tamil"))
      newPath= "/tamil"+newPath;
    else if(pathname.includes("telugu"))
      newPath= "/telugu"+newPath;
    else
      newPath= "/english"+newPath;
    return newPath;
  };
  // const shouldDisplay = (item) => {
    
  //   return (!item.url.includes('details') && 
  //          (item.name === 'Calculations' || 
  //            item.name === 'Prastharashtakvarga' || 
  //            item.name === 'Shodashvarga Charts' || 
  //            item.name === 'Ashtak Varga' ||
  //            item.name === 'Print PDF'));
  // };
  return (
    <div
      className={clsx(
        classes.root,
        nested && open && classes.expanded
      )}
    >
      <ListItem 
        button
        className={clsx(classes.listItem)}
        onClick={handleClick}
        disableGutters 
        // style={{ display: shouldDisplay(item) ? 'none' : 'display' }}
       >
        <Box
          component={!nested ? Link : "div"}
          to={determineToValue(item.url)} 
          className={clsx(classes.listLink, collapsed && classes.listLinkCollapsed)}
        >
          <ListItemIcon className={classes.listIcon}>
            {(item.icon && <item.icon />) || ""}
          </ListItemIcon>
          <ListItemText
            classes={{ primary: collapsed ? classes.listItemText : "" }}
          >
            {t(item.name)}
          </ListItemText>
          {nested &&
            (open ? (
              <ExpandLess fontSize={collapsed ? "inherit" : "default"} />
            ) : (
              <ExpandMore fontSize={collapsed ? "inherit" : "default"} />
            ))}
        </Box>
      </ListItem>
      <Divider />
      {nested && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List disablePadding>
            {item.navigationData.map((nestedItem, i) => (
              <NavigationItem key={i} item={nestedItem}  collapsed={collapsed} />
            ))}
          </List>
        </Collapse>
      )}
    </div>
  );
};

export default NavigationItem;

NavigationItem.propTypes = {
  item: PropTypes.object.isRequired,
  collapsed: PropTypes.bool,
};
