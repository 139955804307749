import React, { useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Alert, Avatar, Backdrop, Box, Button, CircularProgress, InputAdornment, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { userForgotPassword } from '../service/UserService';
import { ResponseContext } from '../App';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
// import { userForgotPassword } from '../../service/UserService';userForgotPassword

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        width: "420px",
        [theme.breakpoints.down('sm')]: {
            width: '380px',
        }
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1)
    },
    height: 'auto',
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
}));

const StyledButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    '&:hover': {
        backgroundColor: theme.palette.primary.dark,
    }
}));

const ForgotPasswordDialog = () => {
    const {openForgotPassword, setOpenForgotPassword} = useContext(ResponseContext);
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();

    const handleForgotPassword = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setSuccess('');

        try {
            await userForgotPassword(email);
            setSuccess('Password reset link has been sent to your email.');
            setEmail('');
        } catch (e) {
            setError(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(()=>{
        setError('')
        setSuccess('')
    },[openForgotPassword]);

    return (
        <BootstrapDialog
            onClose={()=>{setOpenForgotPassword(false)}}
            aria-labelledby="forgot-password-dialog-title"
            open={openForgotPassword}
        >
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <IconButton
                aria-label="close"
                onClick={()=>{setOpenForgotPassword(false)}}
                sx={{ position: 'absolute', right: 7, top: 5, color: "darkblue" }}
            >
                <CancelIcon />
            </IconButton>

            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 3 }}>
                    <StyledAvatar>
                        <LockOutlinedIcon />
                    </StyledAvatar>
                    <Typography component="h1" variant="h5" color='darkblue' sx={{ mb: 2 }}>
                        Forgot Password
                    </Typography>
                    {success && <Alert severity="success" sx={{ width: '100%' }}>{success}</Alert>}
                    {error && <Alert severity="error" sx={{ width: '100%' }}>{error}</Alert>}
                    {!success && <Box component="form" onSubmit={handleForgotPassword} noValidate sx={{ width: '100%' }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            autoComplete="email"
                            error={!!error}
                            helperText={error ? error : ''}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="email icon"
                                            edge="end"
                                        >
                                            <AlternateEmailIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <StyledButton
                            fullWidth
                            type="submit"
                            variant="contained"
                        >
                            Send Reset Link
                        </StyledButton>
                    </Box>}
                </Box>
            </DialogContent>
        </BootstrapDialog>
    );
};

export default ForgotPasswordDialog;
